import axiosApi from "../../providers/api";

export default {
  state: {
    applicableDepositBonuses: {},
    activeBonuses: [],
    bonusRules: [],
    playerDetails: {},
  },
  getters: {
    applicableDepositBonuses: (state) => state.applicableDepositBonuses,
    activeBonuses: (state) => state.activeBonuses,
    bonusRules: (state) => state.bonusRules,
    playerDetails: (state) => state.playerDetails,
  },
  mutations: {
    setApplicableDepositBonuses: (state, data) => {
      state.applicableDepositBonuses = data;
    },
    setActiveBonuses: (state, data) => {
      state.activeBonuses = data;
    },
    setBonusRules: (state, data) => {
      state.bonusRules = data;
    },
    setPlayerDetails: (state, data) => {
      state.playerDetails = data;
    },
  },
  actions: {
    async savePlayerAttributes(
      { commit, getters },
      { attributeName, attributeValue }
    ) {
      const data = {
        sessionKey: getters.user.crm_session_key,
        attributes: {
          PlayerAttribute: {
            AttrName: attributeName,
            AttrValue: attributeValue,
            AttrType: "String",
            CanDelete: true,
            CanEdit: true,
            CanView: true,
          },
        },
      };
      await axiosApi.post("/crm/savePlayerAttributes", data);
      commit("setPlayerAttributes", {
        attribute_name: attributeName,
        attribute_type: "String",
        attribute_value: attributeValue,
        can_delete: true,
        can_edit: true,
        can_view: true,
      });
    },
    async getApplicableDepositBonuses({ commit, getters }) {
      try {
        const { data } = await axiosApi.get(
          `/crm/getApplicableDepositBonuses/${getters.user.crm_session_key}`
        );
        commit("setApplicableDepositBonuses", data);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchActiveBonuses({ commit, getters }) {
      try {
        const { data } = await axiosApi.post("/crm/bonusesGetActive", {
          sessionKey: getters.user.crm_session_key,
        });
        commit(
          "setActiveBonuses",
          data.PlayerGetActiveBonusesResult.Bonuses?.BonusAwarded || []
        );
      } catch (err) {
        console.error(err);
      }
    },
    async fetchBonusRules({ commit }) {
      try {
        const { data } = await axiosApi.get("/crm/getBonusRules");
        commit(
          "setBonusRules",
          data.BonusGetBonusRulesResult?.Bonuses?.BonusRuleExtendedBase || []
        );
      } catch (err) {
        console.error(err);
      }
    },
    async fetchPlayerDetails({ commit }) {
      try {
        const { data } = await axiosApi.get("/crm/getPlayerDetails");
        commit("setPlayerDetails", data);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
