<script setup>
import { onMounted, inject, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";

let isLoading = ref(false);
let error = ref({});
const store = useStore();
const route = useRoute();
const router = useRouter();
const api = inject("api");
const toast = useToast();
const $t = inject("translations");

let signInWithEmail = async (user) => {
  let response = await store.dispatch("loginWithEmailAndPassword", user);
  if (response.jwt) {
    toast.success($t("toaster.success.message_login_success"), {
      timeout: 2000,
    });
    router.push({
      name: "home",
    });
  } else {
    toast.error($t("toaster.error.message_wrong_username_or_password"), {
      timeout: 2000,
    });
  }
};

onMounted(async () => {
  isLoading.value = true;
  let token = route.query.access_token;
  let errorMsg = route.query.error;
  const provider = route.params.provider;
  let response;
  if (errorMsg) {
    error.value = errorMsg;
    isLoading.value = false;
    toast.error(errorMsg, {
      timeout: 2000,
    });
    router.push({
      name: "home",
    });
  }
  if (token) {
    isLoading.value = true;
    response = await api
      .get(`/auth/${provider}/callback?access_token=` + token)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    //If is sign up
    if (!response.user.first_name && !response.user.phone) {
      if (provider === "google") {
        const googleResponse = await fetch(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${token}`
        );
        const googleData = await googleResponse.json();
        response.user.first_name = googleData.given_name;
        response.user.last_name = googleData.family_name;
      }

      await store.dispatch("setProviderData", response);

      let event = new CustomEvent("toggleAuthModal", {
        detail: {
          show: true,
          useCase: "registration",
        },
      });
      window.dispatchEvent(event);
      router.push({
        name: "home",
        query: {
          provider,
        },
      });
      isLoading.value = false;
    }

    //if is SIGN IN
    if (response.user.crm_session_key) {
      const user = {
        email: response.user.email,
        password: response.user.crm_password,
        ip_address: "0.0.0.0",
      };
      await signInWithEmail(user);
    }
  }
});
</script>
<template>
  <div class="fb-callback d-flex justify-content-center align-items-center">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$color: var(--app-yellow);
.fb-callback {
  min-height: 60vh;
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: $color;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
