<script setup>
import cashier from "../../helpers/cashier";
import { computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";
import AppLink from "../AppLink.vue";
import HeaderUserGift from "../header/HeaderUserGift.vue";

const store = useStore();

let menuOpened = ref(false);
const menuToggler = ref();

let refreshInterval = computed(() => {
  return store.state.global.data.balance_refresh_interval || 15000;
});

let balance = computed(() => {
  let balance = {
    amount: 0,
    currency: "USD",
  };
  if (store.state.user.balance && store.state.user.balance.CurrentBalance) {
    balance.amount = store.state.user.balance.CurrentBalance;
    balance.bonusBalance = store.state.user.balance.CurrentBonusBalance;
    balance.realBalance = store.state.user.balance.CurrentRealBalance;
    balance.currency = store.state.user.balance.CurrencyISO;
  }
  //check if balance is nubmer
  if (balance.amount && !isNaN(balance.amount)) {
    //balance.amount = (parseFloat(balance.amount) / 100).toFixed(2);
    balance.amount = parseFloat(balance.amount).toFixed(2);
  }
  if (balance.bonusBalance && !isNaN(balance.bonusBalance)) {
    //balance.bonusBalance = (parseFloat(balance.bonusBalance) / 100).toFixed(2);
    balance.bonusBalance = parseFloat(balance.bonusBalance).toFixed(2);
  }
  if (balance.realBalance && !isNaN(balance.realBalance)) {
    balance.realBalance = parseFloat(balance.realBalance).toFixed(2);
  }

  return balance;
});

const accountMenu = computed(
  () => store.getters.navigationMenus?.account_menu?.links || []
);

const bankingMenu = computed(
  () => store.getters.navigationMenus?.banking_menu?.links || []
);

const openCashier = (type) => {
  cashier.open(type);
};

let user = computed(() => {
  return store.state.user.user;
});

const hasFreeSpins = computed(
  () =>
    store.state.user?.balance?.CwFreeRoundsNum &&
    store.state.user?.balance?.CwFreeRoundsNum !== "0"
);

let refreshBalance = () => {
  store.dispatch("refreshBalance", user.value.crm_session_key);
};

watch(store.state.auth.jwt, async (newData) => {
  if (newData) {
    refreshBalance();
  }
});

let logOut = async () => {
  await store.dispatch("logOut", user.value.crm_session_key);
};

onMounted(() => {
  refreshBalance();
  setInterval(() => {
    refreshBalance();
  }, refreshInterval.value);
});

// Directives
const VClickInside = {
  created(el) {
    document.addEventListener("click", ({ target }) => {
      const elChildren = [...el.querySelectorAll("*")];
      const menuTogglerChildren =
        [...menuToggler.value.querySelectorAll("*")] || [];
      if (
        target !== menuToggler.value &&
        !elChildren.includes(target) &&
        !menuTogglerChildren.includes(target)
      ) {
        menuOpened.value = false;
      }
    });
  },
};
</script>
<template>
  <div class="user d-flex align-items-center">
    <a
      role="button"
      class="me-2 deposit d-md-inline-flex btn btn-primary"
      @click="openCashier('DEPOSIT')"
    >
      <span class="d-none d-md-inline">
        {{ $t("header.button_deposit") }}
      </span>
      <span class="d-inline d-md-none">+</span>
    </a>
    <HeaderUserGift v-if="hasFreeSpins" />

    <div class="user__info">
      <div class="user__menu">
        <div
          class="user__menu-toggle d-flex align-items-center"
          @click="menuOpened = !menuOpened"
          :class="{ opened: menuOpened }"
          ref="menuToggler"
        >
          <div class="user__avatar d-none d-sm-flex me-sm-3">
            {{ user.username[0] || "?" }}
          </div>
          <div>
            <div class="name d-none d-sm-block">
              {{ user.username || user.email }}
            </div>
            <div class="balance ps-1 ps-sm-0">
              {{ balance.amount || "--.--" }}
              {{ balance.currency || "USD" }}
            </div>
          </div>
          <icon
            class="ms-2 ms-md-3 me-1 d-inline-block"
            :variant="'dropdown'"
            color="#fff"
          />
        </div>
        <div
          class="user__menu-dropdown"
          :class="{ opened: menuOpened }"
          v-click-inside
        >
          <div class="bg">
            <div
              class="user__menu-user d-flex d-sm-none align-itens-center py-3"
            >
              <div class="user__avatar me-3">
                {{ user.username[0] || "?" }}
              </div>
              <div class="name d-flex align-items-center">
                {{ user.username || user.email }}
                <!-- <div class="name h-100">{{ user.username || user.email }}</div> -->
                <!-- <div class="balance mt-1">
                  {{ balance.amount || "--.--" }}
                  {{ balance.currency || "USD" }}
                </div> -->
              </div>
            </div>

            <div class="mobile-menu-user-content">
              <h5 class="text-white">
                {{ $t("header.user_dropdown.text_my_balance") }}:
                <span class="balance-currency text-warning ms-1">{{
                  balance.amount + " " + balance.currency
                }}</span>
              </h5>

              <p class="text-secondary mb-0">
                {{ $t("header.user_dropdown.text_withdrawable") }}:
                <span class="balance-currency text-warning opacity-50 ms-1">{{
                  balance.realBalance + " " + balance.currency
                }}</span>
              </p>
              <p class="text-secondary">
                {{ $t("header.user_dropdown.text_bonus") }}:
                <span class="balance-currency text-warning opacity-50 ms-1">{{
                  balance.bonusBalance + " " + balance.currency
                }}</span>
              </p>
              <div class="text-white">
                <h5 class="mb-2 mt-4">
                  {{ $t("header.user_dropdown.title_banking_menu") }}
                </h5>
                <ul class="list-unstyled menu-list">
                  <li v-for="item in bankingMenu" :key="item.id">
                    <AppLink :link="item" @navigated="menuOpened = false">
                      <template #icon>
                        <div class="icon">
                          <icon
                            :variant="item.icon_name"
                            style="width: 14px; height: 14px"
                            class="me-2"
                            color="#fff"
                          />
                        </div>
                      </template>
                    </AppLink>
                  </li>
                </ul>
              </div>
              <div class="text-white mt-2">
                <h5 class="mb-2 mt-4">
                  {{ $t("header.user_dropdown.title_account_menu") }}
                </h5>
                <ul class="list-unstyled menu-list">
                  <li v-for="item in accountMenu" :key="item.id">
                    <AppLink :link="item" @navigated="menuOpened = false">
                      <template #icon>
                        <div class="icon">
                          <icon
                            :variant="item.icon_name"
                            style="width: 14px; height: 14px"
                            class="me-2"
                            color="#fff"
                          />
                        </div>
                      </template>
                    </AppLink>
                  </li>
                </ul>
              </div>
              <a
                href="#"
                @click="logOut"
                class="d-flex px-2 align-items-center text-secondary mt-4 text-decoration-none"
              >
                <icon
                  :variant="'log-out'"
                  style="width: 14px; height: 14px"
                  class="me-4"
                  color="#6c757d"
                />
                {{ $t("header.user_dropdown.logout") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.user {
  color: #fff;
  .deposit {
    @media (max-width: 767px) {
      height: 28px;
      font-size: 28px;
      padding: 0px 0px;
      width: 28px;
    }
  }
  &__menu {
    position: relative;
    &-toggle {
      padding: 8px;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.3s;
      @media (max-width: 500px) {
        padding: 6px;
      }
      svg {
        transition: 0.2s;
        @media (max-width: 500px) {
          width: 12px;
          height: 12px;
        }
      }
      &:hover {
        background-color: rgba(#fff, 0.2);
        transition: 0.2s;
      }
      &.opened {
        background-color: rgba(#fff, 0.2);
        transition: 0.2s;
        svg {
          transition: 0.2s;
          transform: rotate(180deg);
        }
      }
    }
    &-dropdown {
      position: absolute;
      top: 100%;
      min-width: 250px;
      right: 0;
      z-index: 1;
      width: 100%;
      padding-top: 28px;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      @media screen and (max-height: 600px) {
        padding-top: 5px;
      }
      &.opened {
        visibility: visible;
        opacity: 1;
        transition: 0.3s;
      }
      .bg {
        background: lighten(#0c1d29, 10%);
        list-style: none;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.4);
        @include tablet {
          max-height: 520px;
          overflow: auto;
        }
        @media screen and (max-height: 600px) {
          overflow: auto;
          max-height: 250px;
        }
      }
      ul {
        list-style: none;
        padding: 0px;
        margin: 0;
        li {
          a {
            padding: 12px 8px;
            color: #fff;
            display: flex;
            width: 100%;
            text-decoration: none;
            font-size: 18px;
            &:hover {
              background: rgba(#fff, 0.1);
            }
          }
        }
      }
      &-list {
        list-style: none;
        padding: 0;
        margin: 0;
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.5rem 1rem;
          &-link {
            color: #000;
            font-size: 1.2rem;
            font-weight: 500;
            &:hover {
              color: #000;
            }
          }
        }
      }
    }
  }
  &__info {
    line-height: 1.1;
    .name {
      font-size: 1rem;
      font-weight: bold;
    }
    .balance {
      font-size: 0.8rem;
      font-weight: 400;
      @media (max-width: 500px) {
        font-size: 13px;
      }
    }
  }
  &__avatar {
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--app-yellow);
    color: #000;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: inset 0px 0px 0px 2px #000;
    border: 2px solid var(--app-yellow);
  }
}
.menu-list {
  li {
    &:hover {
      a {
        color: var(--app-yellow) !important;
        svg {
          fill: var(--app-yellow);
        }
      }
    }
  }
}
.mobile-menu-user-content {
  /* overflow-y: auto;
  height: 100%; */
  h5,
  p,
  ul li a,
  span {
    font-size: 14px;
  }
}
.balance-currency {
  font-size: 12px !important;
}
</style>
