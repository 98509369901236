<script setup>
import gamesFilterItem from "./games-filter-item.vue";
import gamesFilterSelections from "./games-filter-selections.vue";
import { computed, ref, inject } from "vue";
import { useStore } from "vuex";

// Declarations
const store = useStore();
const $t = inject("translations");

// Data
const isLoading = ref(false);

// Computed
const getShowGamesAdvancedFilters = computed(
  () => store.getters.showGamesAdvancedFilters
);

const getSelectedFilters = computed(() => store.getters.selectedFilters);

const getFilters = computed(() => [
  {
    id: 1,
    title: $t("pages.games.advanced_filter.title_game_providers"),
    group: "provider",
    data: store.getters.gameProviders,
  },
  {
    id: 2,
    title: $t("pages.games.advanced_filter.title_game_themes"),
    group: "theme",
    data: store.getters.gameThemes,
  },
]);

// Methods
const hideFilters = () => {
  store.commit("setShowGamesAdvancedFilters", false);
};

const clearAllFilters = () => {
  store.commit("setSelectedFilters", []);
};

const fetchFilters = async () => {
  isLoading.value = true;
  await store.dispatch("getGameProviders");
  await store.dispatch("getGameThemes");
  isLoading.value = false;
};
fetchFilters();
</script>
<template>
  <div
    class="filter position-fixed"
    :class="{ 'filter--show': getShowGamesAdvancedFilters }"
    @click.self="hideFilters"
  >
    <div class="filter__content h-100 w-100">
      <form
        class="filter__form h-100 position-relative"
        @submit.prevent="hideFilters"
      >
        <div class="filter__wrapper">
          <div
            class="filter__header d-flex justify-content-between align-items-center"
          >
            <div class="d-flex align-items-center">
              <icon
                variant="games-advanced-filter"
                class="filter__filter-icon"
                fill="#fff"
              />
              <h2 class="filter__title mb-0 ms-1">
                {{ $t("pages.games.advanced_filter.title") }}
              </h2>
            </div>
            <span @click="hideFilters" role="button">
              <icon variant="close" fill="#fff" class="filter__close-icon" />
            </span>
          </div>
          <games-filter-selections v-if="getSelectedFilters.length" />
          <div class="filter__body">
            <games-filter-item
              v-for="filter in getFilters"
              :key="filter.id"
              :filter="filter"
            />
          </div>
        </div>
        <div
          class="filter__buttons position-absolute bottom-0 left-0 w-100 d-flex"
        >
          <button
            type="button"
            class="btn btn--clear w-100"
            @click="clearAllFilters"
          >
            {{ $t("pages.games.advanced_filter.button_clear_all") }}
          </button>
          <button type="submit" class="btn btn--submit w-100">
            {{ $t("pages.games.advanced_filter.button_submit") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped lang="scss">
.filter {
  z-index: 99;
  padding-top: 100px;
  pointer-events: none;
  color: #fff;
  padding-bottom: env(safe-area-inset-bottom);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @media (max-width: 1024px) {
    padding-top: 60px;
  }
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(#000000, 0.5);
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  &__form {
    background: #102b3c;
    max-width: 400px;
    width: 90%;
    transform: translateX(-100%);
    transition: transform 0.2s ease;
    z-index: 2;
    overflow: auto;
  }
  &__title {
    font-weight: 700;
  }
  &__filter-icon {
    width: 24px;
    height: 24px;
  }
  &__wrapper {
    height: calc(100% - 57px);
    overflow: auto;
    @media (max-width: 769px) {
      height: calc(100% - 113px);
    }
    @media (min-width: 1024px) {
      height: calc(100% - 80px);
    }
  }
  &__buttons {
    border-top: 1px solid #546773;
    box-shadow: 0 -8px 16px rgb(0 0 0 / 16%);
    padding: 8px;
    @media (max-width: 769px) {
      padding-bottom: 20px;
      flex-direction: column;
    }
  }
  .btn {
    font-weight: unset;
    &--submit {
      color: #fff;
      background: #a80000;
      margin-left: 4px;
      @media (max-width: 769px) {
        margin-left: 0;
      }
    }
    &--clear {
      margin-right: 4px;
      color: #fae00c;
      text-decoration: underline;
      @media (max-width: 769px) {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
    @media (min-width: 1024px) {
      min-height: 50px;
    }
  }
  &--show {
    pointer-events: all;
    .filter__form {
      transform: translateX(0);
    }
    &::after {
      opacity: 1;
    }
  }
  &__header {
    padding: 12px 8px;
    @media (min-width: 1024px) {
      padding: 12px 22px;
    }
  }
  &__title {
    font-size: 14px;
  }
  &__close-icon.svg-icon {
    height: 18px;
    width: 18px;
  }
}
</style>
