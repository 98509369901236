<script setup>
import gamesFilterSubItem from "./games-filter-sub-item.vue";
import { defineProps, computed, ref } from "vue";
import { useStore } from "vuex";
import _ from "lodash";

// Declarations
const store = useStore();

// Data
const isOpened = ref(false);
const searchKeywords = ref("");

// Props
const props = defineProps({
  filter: {
    required: true,
    type: Object,
  },
});

// Computed
const getFilteredSubFilters = computed(() => {
  const subFilters = props.filter.data.filter((subFilter) => {
    const name = subFilter.name || subFilter.display_name;
    return (
      name?.toLowerCase().includes(searchKeywords.value.toLowerCase().trim()) ||
      ""
    );
  });
  return _.orderBy(
    subFilters,
    (subFilter) => subFilter.name || subFilter.display_name,
    "ASC"
  );
});

const getSelectedProviders = computed(() => store.getters.selectedProviders);

const getSelectedThemes = computed(() => store.getters.selectedThemes);

const getSelectedSubTitles = computed(() =>
  props.filter.group === "provider"
    ? getSelectedProviders.value
    : getSelectedThemes.value
);

const getFilterSubTitles = computed(() =>
  getSelectedSubTitles.value.length
    ? getSelectedSubTitles.value
    : props.filter.data
);

const getAllFilterNames = computed(() =>
  getFilterSubTitles.value
    .map((filter) => filter.name || filter.display_name)
    .join(", ")
);

// Methods
const init = () => {
  if (props.filter.group === "provider") {
    isOpened.value = true;
  }
};

// Created
init();
</script>
<template>
  <div class="filter-item" :class="{ 'filter-item--opened': isOpened }">
    <div
      class="filter-item__header d-flex align-items-center justify-content-between"
      :class="{ 'filter-item__header--active': getSelectedSubTitles.length }"
      @click="isOpened = !isOpened"
    >
      <div class="filter-item__details">
        <h2 class="filter-item__title">
          {{ filter.title }}
        </h2>
        <p class="filter-item__selected m-0">
          {{ getAllFilterNames }}
        </p>
      </div>
      <div class="filter-item__chevron">
        <icon variant="games-advanced-filter-chevron" fill="#fff" />
      </div>
    </div>
    <div class="filter-item__body" v-show="isOpened">
      <div class="filter-item__input-wrapper position-relative">
        <input
          type="text"
          :placeholder="
            $t('pages.games.advanced_filter.input_placeholder_search')
          "
          class="filter-item__input w-100"
          v-model="searchKeywords"
        />
        <icon
          variant="games-advanced-filter-search"
          class="filter-item__input-icon position-absolute"
          fill="#fff"
        />
      </div>
      <div class="filter-item__content">
        <games-filter-sub-item
          v-for="subFilter in getFilteredSubFilters"
          :key="subFilter.id"
          :group="filter.group"
          :sub-filter="subFilter"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.filter-item {
  padding: 16px;
  border-top: 1px solid #546773;
  @media (min-width: 1024px) {
    padding: 16px 22px;
  }
  &__header {
    cursor: pointer;
    &:hover,
    &--active {
      color: #f2c94c;
    }
    &--active .svg-icon {
      fill: #f2c94c;
    }
  }
  &__title {
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 700;
  }
  &__details {
    width: calc(100% - 20px);
  }
  &__chevron {
    .svg-icon {
      width: 12px;
      height: 12px;
    }
  }
  &__selected {
    font-size: 11px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__body {
    margin-top: 15px;
  }
  &__input {
    margin-bottom: 7px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 0.6);
    padding: 10px 30px 10px 20px;
    border-radius: 10px;
    font-size: 14px;
  }
  &__input-icon {
    opacity: 0.5;
    right: 10px;
    top: 15px;
  }
  &__content {
    max-height: 240px;
    overflow: auto;
  }
  &--opened {
    .filter-item {
      &__chevron {
        transform: rotate(-180deg);
      }
    }
  }
}
</style>
