<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

// Declarations
const store = useStore();

// Computed
const getUMImage = computed(
  () => store.getters.underMaintenanceImage?.data?.attributes?.url
);
</script>
<template>
  <div
    class="app-um d-flex align-items-center justify-content-center flex-column text-center"
  >
    <img
      class="app-um__image"
      v-if="getUMImage"
      :src="getUMImage"
      alt="under maintenance image"
    />
    <h1 class="app-um__title">{{ $t("under_maintenance.title") }}</h1>
    <p class="app-um__text">{{ $t("under_maintenance.post_title") }}</p>
  </div>
</template>
<style scoped lang="scss">
.app-um {
  height: 100vh;
  padding: 20px;
  &__image {
    max-width: 350px;
    margin-bottom: 30px;
    @include mobile {
      max-width: 250px;
      margin-bottom: 20px;
    }
  }
  &__title {
    font-size: 36px;
    margin-bottom: 15px;
    color: #fff;
    @include mobile {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
  &__text {
    font-size: 18px;
    color: rgb(172, 169, 169);
    margin: 0;
    @include mobile {
      font-size: 14px;
    }
  }
}
</style>
