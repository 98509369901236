<script setup>
import { ref, inject } from "vue";
import { useToast } from "vue-toastification";

const toast = useToast();
let sent = ref(false);
let email = ref("");
let username = ref("");

const api = inject("api");
async function resetPassword() {
  await api
    .post("/crm/requestPasswordReset", {
      email: email.value,
      username: username.value,
    })
    .then((response) => {
      if (response.data.success) {
        toast.success("Password reset link sent", {
          timeout: 2000,
        });
        sent.value = true;
      } else {
        toast.error("Something went wrong", {
          timeout: 2000,
        });
      }
    })
    .catch((error) => {
      toast.error("Something went wrong", {
        timeout: 2000,
      });
      return error;
    });
}
</script>
<template>
  <div class="forgot-password">
    <div class="form" v-if="!sent">
      <h3 class="mb-4 pt-2">{{ $t("login_forgot_password") }}</h3>
      <div class="form-input">
        <div class="icon">
          <icon :variant="'email'" />
        </div>
        <input type="email" placeholder="Email" v-model="email" />
      </div>
      <div class="form-input">
        <div class="icon">
          <icon :variant="'user'" />
        </div>
        <input type="text" placeholder="Username" v-model="username" />
      </div>
      <div class="cta">
        <button class="btn btn-primary w-100" @click="resetPassword">
          {{ $t("login_reset_password") }}
        </button>
      </div>
    </div>
    <div class="sent" v-if="sent">
      <h3 class="mb-4 pt-2">{{ $t("login_forgot_password") }}</h3>
      <p class="mb-4">
        {{ $t("login_forgot_password_sent") }}
      </p>
    </div>
  </div>
</template>



<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@include fancy-form-input;
.cta {
  .btn {
    box-shadow: 0px 10px 25px -8px rgba(0, 0, 0, 0.26);
  }
}
</style>