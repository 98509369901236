<script setup>
import { inject, onMounted, ref } from "vue";
import loginForm from "@/components/auth/login-form.vue";
import forgotPassword from "@/components/auth/forgot-password.vue";
import registrationForm from "@/components/auth/registration-form.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const $t = inject("translations");

let show = ref(false);
let useCase = ref("login");

let registrationStep = ref(1);
let continueRegistration = () => {
  if (store.state.user.user.id && !store.state.user.user.first_name) {
    registrationStep.value = 2;
    useCase.value = "registration";
    show.value = true;
  }
};

const getAuthTitle = (useCase) => {
  if (useCase === "registration") {
    return $t("registration_form.title");
  }
  if (useCase === "login") {
    return $t("login_form.title");
  }
};

const finishRegistration = () => {
  show.value = false;
  router.push({ name: "bonus", params: { slug: "welcome" } });
};

onMounted(() => {
  window.addEventListener("toggleAuthModal", (e) => {
    //Define login or create account
    if (e.detail.useCase) {
      useCase.value = e.detail.useCase;
    }
    //Show modal
    if (e.detail.show) {
      show.value = true;
    } else {
      show.value = false;
    }
  });
  window.addEventListener("continueRegistration", () => {
    continueRegistration();
  });

  setTimeout(() => {
    continueRegistration();
  }, 300);
});
</script>
<template>
  <div class="modal fade" :class="{ show: show, 'd-block': show }">
    <div class="modal-scroller">
      <div class="modal-body">
        <div class="auth-modal">
          <div class="row">
            <div class="col-12 d-none d-lg-block col-lg-6 p-0">
              <div
                class="auth-modal__image"
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/img/login_bg.png') + ')',
                }"
              />
            </div>
            <div class="col-12 col-lg-6 p-0">
              <div class="auth-modal__content">
                <div
                  v-if="registrationStep == 1"
                  class="auth-modal__close"
                  @click="show = false"
                >
                  <icon :variant="'close'" />
                </div>
                <div class="logo text-center mt-0 mt-md-2">
                  <img src="@/assets/img/logo.png" width="180" alt="" />
                </div>
                <div
                  class="case text-center mb-2 mb-md-5"
                  v-if="registrationStep == 1"
                >
                  {{ getAuthTitle(useCase) }}
                </div>
                <!-- /header -->
                <template v-if="useCase == 'login'">
                  <login-form />
                  <div class="switch-auth-case text-center mt-4">
                    <div>{{ $t("login_form.text_no_account") }}</div>
                    <a href="#" @click.prevent="useCase = 'registration'">
                      {{ $t("login_form.link_sign_up") }}</a
                    >
                    <div class="mt-4 text-center">
                      <a href="#" @click.prevent="useCase = 'forgot-password'">
                        {{ $t("login_form.link_forgot_password") }}
                      </a>
                    </div>
                  </div>
                </template>
                <template v-if="useCase == 'forgot-password'">
                  <forgotPassword @update="useCase = 'login'" />
                </template>
                <template v-if="useCase == 'registration'">
                  <registration-form
                    @nextStep="registrationStep = 2"
                    @finish="finishRegistration"
                    :step="registrationStep"
                  />
                  <div
                    class="switch-auth-case text-center mt-4"
                    v-if="registrationStep == 1"
                  >
                    <div>{{ $t("login_or") }}</div>
                    {{ $t("login_have_account") }}
                    <a href="#" @click.prevent="useCase = 'forgot-password'">
                      {{ $t("login_forgot_password") }}
                    </a>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.auth-modal {
  width: 90%;
  max-width: 1020px;
  border-radius: 12px;
  margin: 30px auto 30px;
  @media (max-width: 768px) {
    margin: 0 auto 100px;
  }
  .logo {
    @media (max-width: 767px) {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    @media (max-width: 545px) {
      img {
        height: 96px;
        width: auto;
      }
    }
    @media (min-width: 991px) {
      position: absolute;
      top: -30px;
      left: 0;
      transform: translateX(-50%);
    }
  }
  .case {
    text-transform: capitalize;
  }
  .switch-auth-case {
    a {
      color: #e16a14;
    }
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    opacity: 0.7;
    padding: 10px;
    &:hover {
      opacity: 1;
      transition: 0.3s;
    }
    transition: 0.3s;
  }
  &__image {
    background: #f00;
    height: 100%;
    width: 100%;
    min-height: 500px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background-position: center top;
    background-size: cover;
  }
  &__content {
    position: relative;
    background: rgba(255, 255, 255, 0.78);
    backdrop-filter: blur(28px);
    height: 100%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    @media (max-width: 767px) {
      padding: 12px;
    }
    h3 {
      font-weight: 300;
      strong {
        font-weight: 800;
      }
    }
    @media (max-width: 768px) {
      border-radius: 12px;
    }

    padding: 36px;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#112b3c, 0.6);
  z-index: 1000;
  backdrop-filter: blur(8px);
  transition: 0.3s;
}
</style>
