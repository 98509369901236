export const toggleAuthModal = ({ useCase, show }) => {
  window.dispatchEvent(
    new CustomEvent("toggleAuthModal", {
      detail: {
        show,
        useCase,
      },
    })
  );
};
