<script setup>
import vSelect from "vue-select";
import { VueTelInput } from "vue-tel-input/dist/vue-tel-input.es.js";
import "vue-tel-input/dist/vue-tel-input.css";
import { computed, ref, defineEmits, onMounted, inject } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import qs from "qs";
import { marked } from "marked";
import { useToast } from "vue-toastification";
import { toggleAuthModal } from "../../../helpers/modals";

// import axios from "axios";
const translations = inject("translations");
const toast = useToast();

//inputs

const route = useRoute();
const store = useStore();
const emit = defineEmits(["nextStep"]);

//service
let isLoading = ref(false);
let validate = ref(false);
let dataIsLoading = ref(false);
let showPrivacyPolicy = ref(false);
let privacyPolicyText = ref({});
let currencies = ref([]);

let showPassword = ref(false);

let isProviderAuth = computed(() => {
  let state = false;
  if (
    route.query.provider &&
    store.state.auth.providerData.jwt &&
    !store.state.auth.jwt
  ) {
    state = true;
  }

  return state;
});

let markdownPolicyText = computed(() => {
  let text = "";
  if (privacyPolicyText.value.text) {
    text = marked(privacyPolicyText.value.text);
  }
  return text;
});

let user = ref({
  email: "",
  phone: "",
  username: "",
  currency: "EUR",
  password: "",
  country: computed(() => store.getters.geodata).value.country,
  ip_address: computed(() => store.getters.geodata).value.user_ip,
});
let phoneValid = ref(false);
let telInput = ref("");

//validating fields
let valid = computed(() => {
  let valid = {
    email: true,
    phone: true,
    username: true,
    currency: true,
    password: true,
  };

  //test email regex
  let emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (
    !emailRegex.test(user.value.email) ||
    !user.value.email ||
    !uniqueCheck.value.email.unique
  ) {
    valid.email = false;
  }

  if (
    !user.value.username ||
    user.value.username.length < 3 ||
    !uniqueCheck.value.username.unique
  ) {
    valid.username = false;
  }
  if (!phoneValid.value) {
    valid.phone = false;
  }
  if (!user.value.currency) {
    valid.currency = false;
  }
  if (user.value.password.length < 6) {
    valid.password = false;
  }

  return valid;
});

let providerData = computed(() => {
  return store.state.auth.providerData;
});

const createUser = async () => {
  validate.value = true;

  let invalidFields = [];
  for (let key in valid.value) {
    if (!valid.value[key]) {
      invalidFields.push(key);
    }
  }

  if (invalidFields.length > 0) {
    invalidFields.forEach((field) => {
      let fieldName = field.charAt(0).toUpperCase() + field.slice(1);
      fieldName = fieldName.replace("_", " ");
      let text = translations("error_field_invalid");
      toast.error(`${fieldName} ${text}`);
    });
    return;
  }

  isLoading.value = true;
  let response = await store.dispatch(
    "createUserWithEmailAndPassword",
    user.value
  );

  // let response = await axios.post(
  //   "http://localhost:1337/api/crm/createUser",
  //   user.value
  // );
  isLoading.value = false;

  //move to the next step if success
  if (response.jwt) {
    emit("nextStep");
  } else {
    toast.error("Something went wrong");
  }
};

let validateTel = (data) => {
  phoneValid.value = data.valid;
  if (data.valid) {
    user.value.phone = data.number;
  }
};

const api = inject("api");
let fetchPrivacyPolicy = async () => {
  let query = qs.stringify(
    {
      filters: {
        slug: {
          $eq: "privacy-policy",
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  let response = await api.get(`/pages?${query}`);
  privacyPolicyText.value = response.data?.data?.[0]?.attributes || "";
};

const oauth = (provider) => {
  const env = process.env.VUE_APP_ENV.toLowerCase();
  const baseURL =
    env === "dev"
      ? process.env.VUE_APP_LOCAL_STRAPI_URL
      : process.env.VUE_APP_STRAPI_OAUTH2_CALLBACK_URL;

  let url = `${baseURL}/connect/${provider}`;
  window.location.href = url;
};

const mapProviderData = () => {
  if (
    providerData.value.user.provider === "facebook" ||
    providerData.value.user.provider === "google"
  ) {
    user.value.email = providerData.value.user.email;
    user.value.username = providerData.value.user.username;
    user.value.id = providerData.value.user.id;
    user.value.provider = providerData.value.user.provider;
    if (providerData.value.user.phone) {
      user.value.phone = providerData.value.user.phone;
    }
    if (providerData.value.user.username.includes(" ")) {
      let regexReplaceSpacesWithUnderscore = /\s+/g;
      user.value.username = providerData.value.user.username
        .toLowerCase()
        .replace(regexReplaceSpacesWithUnderscore, "_");
    }
    user.value.currency = "EUR";
    checkIfEmailExists();
    checkIfUsernameExists();
  }
};

const continueWithProvider = async () => {
  validate.value = true;

  let invalidFields = [];
  for (let key in valid.value) {
    if (!valid.value[key]) {
      invalidFields.push(key);
    }
  }

  if (invalidFields.length > 0) {
    invalidFields.forEach((field) => {
      let fieldName = field.charAt(0).toUpperCase() + field.slice(1);
      fieldName = fieldName.replace("_", " ");
      let text = translations("error_field_invalid");
      toast.error(`${fieldName} ${text}`);
    });
    return;
  }
  // await store.dispatch('');
  // await store.dispatch("setRegStepOneData", user.value);
  await createUser();
  // emit("nextStep");
};

let uniqueCheck = ref({
  email: {
    checked: false,
    unique: false,
    isLoading: false,
  },
  username: {
    checked: false,
    unique: false,
    isLoading: false,
  },
});

let emailTimeout;
let checkIfEmailExists = async () => {
  async function check() {
    uniqueCheck.value.email.isLoading = true;
    let data = {
      username: user.value.username,
      action: "checkEmailAvailability",
    };
    let response = await api.get(
      `/crm/checkEmailAvailability?email=${user.value.email}`,
      {
        data,
      }
    );
    uniqueCheck.value.email.checked = true;
    uniqueCheck.value.email.isLoading = false;
    if (response.data.exists !== "true") {
      uniqueCheck.value.email.unique = true;
    } else {
      uniqueCheck.value.email.unique = false;
    }
  }
  clearTimeout(emailTimeout);
  emailTimeout = setTimeout(() => {
    //regex email
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(user.value.email)) {
      check();
    }
  }, 800);
};

let usernameTimeout;
let checkIfUsernameExists = async () => {
  async function check() {
    uniqueCheck.value.username.isLoading = true;
    let data = {
      username: user.value.username,
      action: "checkUsernameAvailability",
    };
    await api
      .get(`/crm/checkUsernameAvailability?username=${user.value.username}`, {
        data,
      })
      .then((response) => {
        if (response.data.exists !== "true") {
          uniqueCheck.value.username.unique = true;
        } else {
          uniqueCheck.value.username.unique = false;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        uniqueCheck.value.username.checked = true;
        uniqueCheck.value.username.isLoading = false;
      });
  }
  clearTimeout(usernameTimeout);
  usernameTimeout = setTimeout(() => {
    check();
  }, 800);
};

const getCurrencies = async () => {
  dataIsLoading.value = true;
  await api
    .get("/currencies")
    .then((response) => {
      currencies.value = response.data.data.map((currency) => {
        return {
          value: currency.attributes.code,
          label: currency.attributes.code + " - " + currency.attributes.name,
        };
      });
    })
    .catch(() => {
      return [];
    })
    .finally(() => {
      dataIsLoading.value = false;
    });

  getCurrencyByCountry();
};
const getCurrencyByCountry = async () => {
  let query = qs.stringify(
    {
      filters: {
        code: {
          $eq: "EUR",
        },
      },
      populate: ["currency"],
    },
    {
      encodeValuesOnly: true,
    }
  );
  let response = await api.get(`/countries?${query}`);
  if (response.data?.data?.[0]?.attributes?.currency) {
    user.value.currency =
      response.data?.data[0]?.attributes?.currency?.data?.attributes?.code ||
      "EUR";
  }
};
const openLoginForm = () => {
  toggleAuthModal({ useCase: "registration", show: false });
  toggleAuthModal({ useCase: "login", show: true });
};

onMounted(() => {
  getCurrencies();
  fetchPrivacyPolicy();
  setTimeout(() => {
    if (route.query.provider && providerData.value.jwt) {
      mapProviderData();
    }
  }, 400);
});
</script>
<template>
  <form class="step-one" @submit.prevent="createUser">
    <div class="privacy-policy" v-show="showPrivacyPolicy">
      <div class="privacy-policy__text">
        <h3>
          {{ privacyPolicyText.title }}
        </h3>
        <div v-html="markdownPolicyText"></div>
      </div>
      <div class="privacy-pokicy__close pt-4 text-center">
        <a
          href="#"
          @click.prevent="showPrivacyPolicy = false"
          class="btn w-50 btn-secondary"
        >
          {{ $t("registration_form.privacy_policy.button_close") }}
        </a>
      </div>
    </div>
    <div class="position-relative">
      <div
        class="form-input position-relative"
        :class="{ error: !valid.email && validate }"
      >
        <div class="icon">
          <icon :size="32" :variant="'email'" />
        </div>
        <input
          type="email"
          id="email"
          :disabled="isProviderAuth"
          v-model="user.email"
          :placeholder="$t('registration_form.input_placeholder_email')"
          @keyup="checkIfEmailExists"
        />

        <div
          class="position-absolute d-inline-flex right-center"
          style="z-index: 3"
        >
          <spinner v-if="uniqueCheck.email.isLoading"></spinner>
        </div>
      </div>
      <div
        class="error-msg mb-3"
        v-if="
          !uniqueCheck.email.isLoading &&
          uniqueCheck.email.checked &&
          !uniqueCheck.email.unique
        "
      >
        {{ $t("registration_form.message_invalid_email") }}
      </div>
    </div>

    <div class="form-input" :class="{ error: !valid.phone && validate }">
      <div class="icon">
        <icon :variant="'phone'" />
      </div>
      <div class="input-mock">
        <vue-tel-input
          @validate="validateTel"
          :inputOptions="{
            placeholder: $t('registration_form.input_placeholder_phone'),
          }"
          v-model="telInput"
        />
      </div>
    </div>
    <div class="position-relative">
      <div class="form-input" :class="{ error: !valid.username && validate }">
        <div class="icon">
          <icon :variant="'user'" />
        </div>
        <input
          type="text"
          id="username"
          v-model="user.username"
          :placeholder="$t('registration_form.input_placeholder_username')"
          @keyup="checkIfUsernameExists"
        />

        <div
          class="position-absolute d-inline-flex right-center"
          style="z-index: 3"
        >
          <spinner v-if="uniqueCheck.username.isLoading"></spinner>
        </div>
      </div>
      <div
        class="error-msg mb-3"
        v-if="
          !uniqueCheck.username.isLoading &&
          uniqueCheck.username.checked &&
          !uniqueCheck.username.unique
        "
      >
        {{ $t("registration_form.message_invalid_username") }}
      </div>
    </div>
    <div class="form-input" :class="{ error: !valid.currency && validate }">
      <div class="icon">
        <icon :variant="'dollar'" />
      </div>
      <div class="input-mock">
        <v-select
          :clearable="false"
          :options="currencies"
          :placeholder="$t('registration_form.input_placeholder_currency')"
          :reduce="(currency) => currency.value"
          v-model="user.currency"
          :value="user.currency"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <icon class="dropdown" :variant="'dropdown'" />
            </span>
          </template>
        </v-select>
      </div>
    </div>
    <div class="form-input" :class="{ error: !valid.password && validate }">
      <div class="icon">
        <icon :variant="'password'" />
      </div>
      <input
        :type="showPassword ? 'text' : 'password'"
        id="password"
        v-model="user.password"
        :placeholder="$t('registration_form.input_placeholder_password')"
      />
      <icon
        :variant="showPassword ? 'eye-opened' : 'eye-closed'"
        class="eye"
        @click="showPassword = !showPassword"
      />
    </div>
    <div class="terms-and-conditions custom-checkbox pt-4 ps-0 mb-3 mb-md-3">
      {{ $t("registration_form.text_terms_and_conditions") }}
      <a href="#" @click.prevent="showPrivacyPolicy = true" target="_blank">
        {{ $t("registration_form.link_privacy_policy") }}
      </a>
    </div>
    <div class="cta text-center">
      <template v-if="!isProviderAuth">
        <button type="submit" class="btn btn-success w-100">
          <span class="btn-text" v-if="!isLoading">
            {{ $t("registration_form.button_sign_up") }}
          </span>
          <div class="spinner-border text-dark" role="status" v-if="isLoading">
            <span class="visually-hidden"></span>
          </div>
        </button>
        <div class="w-100 pt-3">
          <button
            type="button"
            @click="oauth('facebook')"
            class="btn facebook-auth btn-secondary w-100"
          >
            <icon variant="facebook" />
            <span>{{
              $t("registration_form.button_sign_up_with_facebook")
            }}</span>
          </button>
        </div>
        <div class="w-100 pt-3">
          <button
            type="button"
            @click="oauth('google')"
            class="btn google-auth btn-secondary mb-2 w-100"
          >
            <icon variant="google" />
            <span>{{
              $t("registration_form.button_sign_up_with_google")
            }}</span>
          </button>
        </div>
      </template>

      <button
        type="button"
        class="btn btn-primary mb-3"
        v-if="isProviderAuth"
        @click="continueWithProvider"
      >
        <span class="btn-text" v-if="!isLoading">
          {{ $t("registration_form.button_continue") }}
        </span>
        <div class="spinner-border text-dark" role="status" v-if="isLoading">
          <span class="visually-hidden"></span>
        </div>
      </button>
      <p class="have-account">
        {{ $t("registration_form.text_already_have_account") }}
        <a href="javascript:void(0)" role="button" @click="openLoginForm">{{
          $t("registration_form.text_login_here")
        }}</a>
      </p>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.privacy-policy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d9d9d9;
  z-index: 20;
  padding: 20px;
  &__text {
    background: #fff;
    overflow: scroll;
    max-height: calc(100% - 80px);
    padding: 16px 14px 20px;
    font-size: 15px;
    box-shadow: 0px 0px 8px 0px rgba(#000, 0.2);
    border-radius: 12px;
  }
}
.have-account {
  a {
    color: #f26001;
  }
}
</style>
