<script setup>
import HeaderUserGiftDropdown from "./HeaderUserGiftDropdown.vue";
import { useStore } from "vuex";
import { ref, computed } from "vue";

// Declarations
const store = useStore();

// Data
const showDropdown = ref(false);
const dropdownToggler = ref(null);

// Computed
const hasFreeSpins = computed(
  () =>
    store.state.user?.balance?.ActiveBonuses?.BonusAwarded?.CwFreeRound
      ?.FreeRoundsRemaining &&
    store.state.user?.balance?.ActiveBonuses?.BonusAwarded?.CwFreeRound
      ?.FreeRoundsRemaining !== "0"
);

// Directives
const VClickInside = {
  created(el) {
    const handleClick = ({ target }) => {
      const elChildren = [...el.querySelectorAll("*")];
      const menuTogglerChildren =
        [...dropdownToggler.value.querySelectorAll("*")] || [];
      if (
        target !== dropdownToggler.value &&
        !elChildren.includes(target) &&
        !menuTogglerChildren.includes(target)
      ) {
        showDropdown.value = false;
        document.removeEventListener("click", handleClick);
      }
    };

    document.addEventListener("click", handleClick);
  },
};
</script>
<template>
  <div class="header-user-gift">
    <div class="position-relative">
      <a
        ref="dropdownToggler"
        role="button"
        class="header-user-gift__toggler position-relative"
        :class="{ 'header-user-gift__toggler--active': hasFreeSpins }"
        @click="showDropdown = !showDropdown"
      >
        <icon variant="gift" />
      </a>
      <HeaderUserGiftDropdown v-click-inside v-if="showDropdown" />
    </div>
  </div>
</template>
<style scoped lang="scss">
.header-user-gift {
  @media only screen and (max-width: 745px) {
    position: absolute;
    left: 45px;
  }
  &__toggler {
    &--active {
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 6px;
        width: 6px;
        top: -6px;
        right: -6px;
        border-radius: 50%;
        background: #e3141f;
      }
    }
  }
  margin: 0 10px;
  .svg-icon {
    width: 24px;
    height: 24px;
    fill: #fff;
  }
}
</style>
