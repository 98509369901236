<script setup>
import { computed, defineProps } from "vue";
import { useStore } from "vuex";

// Declarations
const store = useStore();

// Props
const props = defineProps({
  subFilter: {
    required: true,
    type: Object,
  },
  group: {
    required: true,
    type: String,
  },
});

// Computed
const getSubFilterName = computed(
  () => props.subFilter.name || props.subFilter.display_name
);

const isSelected = computed(() =>
  store.getters.selectedFilters.some(
    (filter) => filter.id === props.subFilter.id && filter.key === props.group
  )
);

// Methods
const select = () => {
  store.commit("setSelectedFilters", {
    ...props.subFilter,
    key: props.group,
  });
};
</script>
<template>
  <div
    class="filter-sub-item"
    :class="{ 'filter-sub-item--selected': isSelected }"
  >
    <div
      class="d-flex align-items-center justify-content-between"
      @click="select"
    >
      <label class="filter-sub-item__title d-flex align-items-center">
        {{ getSubFilterName }}
      </label>
      <p class="filter-sub-item__count m-0">{{ subFilter.games.count }}</p>
    </div>
  </div>
</template>
<style scoped lang="scss">
.filter-sub-item {
  padding: 0 20px 0 10px;
  height: 32px;
  &__title {
    font-size: 14px;
    cursor: pointer;
    border-radius: 30px;
    padding: 3px 8px;
  }
  &__count {
    font-size: 12px;
    color: #fae00c;
  }
  &--selected {
    .filter-sub-item__title {
      color: #1d2325;
      background: #fae00c;
    }
  }
}
</style>
