import cashier from "./cashier";
import { toggleAuthModal } from "./modals";
import { isLogged } from "../composables/auth";
import router from "../router/index";
import store from "../store";

export const handleButtonClick = (button) => {
  const action = button.action?.toLowerCase();
  const supportedButtonActions = Object.freeze({
    redirect: "redirect",
    popup: "popup",
    deposit: "deposit",
    withdraw: "withdraw",
    registration: "registration",
  });
  const protectedActions = [
    supportedButtonActions.deposit,
    supportedButtonActions.withdraw,
  ];
  // Check if banner button has any assigned path. Also check if that banner button path exists in our routes
  if (action === supportedButtonActions.redirect) {
    if (button.external_link) {
      window.open(button.button_path, "_blank");
      return;
    }
    if (button.button_path && router.resolve(button.button_path).name) {
      router.push(button.button_path);
      return;
    }
  }
  // Check if the action is valid and if user is logged in
  // If not, open registration modal
  if (protectedActions.includes(action)) {
    if (isLogged.value) {
      cashier.open(action.toUpperCase());
    } else {
      if (store.getters.hasBeenLoggedBefore) {
        toggleAuthModal({ show: true, useCase: "login" });
        return;
      }
      toggleAuthModal({ show: true, useCase: "registration" });
    }
    return;
  }
  // If action is "registration", open the registration modal
  if (action === supportedButtonActions.registration) {
    toggleAuthModal({ useCase: "registration", show: true });
  }
};
