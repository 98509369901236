<script setup>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Pagination, Autoplay, Controller } from "swiper";
import { ref, computed } from "vue";
import "swiper/swiper.scss";
import "swiper/swiper-bundle.min.css";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import qs from "qs";

// Declarations
const store = useStore();
const router = useRouter();
let modules = [Pagination, Autoplay, Controller];
let breakpoints = ref({
  300: {
    slidesPerView: 3,
    spaceBetween: 12,
    speed: 600,
  },
  // when window width is >= 480px
  480: {
    slidesPerView: 3,
    spaceBetween: 25,
  },
  // when window width is >= 640px
  640: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 5,
  },
  1024: {
    slidesPerView: 6,
  },
  1366: {
    slidesPerView: 7,
  },
  1440: {
    slidesPerView: 8,
  },
});

// Data
let controlledSwiper = ref(null);

// Computed
const providersData = computed(
  () => store.getters.homepageData?.gameProviders || []
);

// Methods
const slideMove = (direction) => {
  if (direction == "next") {
    controlledSwiper.value.slideNext();
  } else {
    controlledSwiper.value.slidePrev();
  }
};
const setControlledSwiper = (swiper) => {
  controlledSwiper.value = swiper;
};
const goToGames = (provider) => {
  store.commit("setSelectedFilters", { ...provider, key: "provider" });
  const filters = qs.stringify(
    { provider: [provider.display_name] },
    { encode: false }
  );
  router.push({ name: "games", query: { filters } });
};
</script>
<template>
  <div class="providers">
    <section class="provider">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="provider__title">
              <span>
                <span>
                  {{ $t("pages.homepage.title_game_providers") }}
                </span>
              </span>
            </div>
          </div>
          <div class="providers__slider">
            <div class="prev">
              <div class="circle" @click="slideMove('prev')">
                <icon variant="arrow-right" />
              </div>
            </div>
            <Swiper
              :modules="modules"
              :pagination="{ clickable: true }"
              :speed="400"
              :loop="true"
              :autoplay="{ delay: 5000 }"
              effect="slide"
              :space-between="20"
              :breakpoints="breakpoints"
              :controller="{ control: controlledSwiper }"
              @swiper="setControlledSwiper"
            >
              <SwiperSlide v-for="pr in providersData" :key="pr.id">
                <div class="provider__provider" @click="goToGames(pr)">
                  <img :src="pr.Logo?.url" alt="" />
                </div>
              </SwiperSlide>
            </Swiper>
            <div class="next">
              <div class="circle" @click="slideMove('next')">
                <icon variant="arrow-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.provider {
  padding: 60px 0px;
  &__provider {
    cursor: pointer;
  }
  &__title {
    text-align: center;
    position: relative;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin: 0 0 40px 0;
    /* letter-spacing: 0.13em; */

    /* color: #E2C777; */
    span {
      font-size: 30px;
      background-color: #102b3c;
      padding-left: 10px;
      padding-right: 10px;
      z-index: 100;
      span {
        background: -webkit-linear-gradient(
          rgba(250, 241, 168, 1),
          rgba(144, 108, 57, 1)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      z-index: -1;
      background: -webkit-linear-gradient(
        rgba(250, 241, 168, 1),
        rgba(144, 108, 57, 1)
      );
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__img {
    border: 3px solid #e2c777;
    box-shadow: 0px 15px 41px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background-color: #fff;
    width: 100%;
    max-height: 70px;
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 85px;
      width: 100%;
      /* max-width: 100%; */
      max-height: 130px;
    }
  }
}
.next {
  left: auto !important;
  right: 0;
}
.prev {
  svg {
    transform: scaleX(-1);
  }
}
.providers__slider {
  position: relative;
}
.swiper-slide > div {
  background-color: #fff;
  border: 3px solid #e2c777;
  height: 80px;
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev,
.next {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  right: 0;
  z-index: 20;
  .circle {
    transition: 0.5s ease-in-out;
    opacity: 0.7;
    &:hover {
      transform: scale(1.1);
      transition: 0.5s ease-in-out;
      opacity: 1;
    }
    cursor: pointer;
    width: 40px;
    height: 40px;
    min-width: 40px;
    background: rgb(250, 241, 168);
    background: radial-gradient(
      circle,
      rgba(250, 241, 168, 1) 0%,
      rgba(144, 108, 57, 1) 100%
    );
    border-radius: 50%;
    box-shadow: 0px 0px 30px 0px rgba(#000, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #2a0303;
      stroke: #2a0303;
    }
  }
}
.swiper-slide img {
  max-width: 120px;
}
@media (max-width: 500px) {
  .swiper-slide img {
    max-width: 80px;
  }
}
</style>

<style lang="scss">
.swiper .swiper-pagination {
  display: none;
}
@media (max-width: 640px) {
  .next,
  .prev {
    display: none !important;
  }
  .swiper {
    min-height: 130px;
  }
  .swiper .swiper-pagination {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    transform: translateY(-100%);
    height: 36px;
    align-items: center;

    .swiper-pagination-bullet {
      border: 1px solid var(--app-yellow) !important;
      &.swiper-pagination-bullet-active {
        background: var(--app-yellow) !important;
      }
      min-width: 16px;
      height: 16px;
      display: block;
      margin: 8px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
</style>
