import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import baseViev from "../views/lang-base-view/index.vue";
import Home from "../views/home/Home.vue";
import Games from "../views/games/Games.vue";
import GamSignle from "../views/game-single/index.vue";
import Promotions from "../views/promotions/Promotions.vue";
import PromotionsSingle from "../views/promotions/Promotion-single.vue";
import Page from "../views/page/index.vue";
import providerCallback from "../views/auth/provider-callback.vue";
import resetPassword from "../views/auth/reset-password.vue";
import My from "../views/my/";
import Bonus from "../views/bonus/index.vue";

let langs = [];
store.state.global.langs.forEach((lang) => {
  langs.push(lang.code);
});
let basePath = "/:lang(" + langs.join("|") + ")?";

const routes = [
  {
    path: basePath,
    component: baseViev,
    redirect: { name: "home" },
    children: [
      {
        path: "",
        name: "home",
        alias: ["register", "login"],
        component: Home,
        meta: {
          role: "public",
        },
      },
      {
        path: "games/:slug?",
        name: "games",
        component: Games,
        meta: {
          role: "public",
        },
      },
      {
        path: "game-single/:id",
        name: "game-single",
        component: GamSignle,
        meta: {
          role: "auth",
        },
      },
      {
        path: "promotions",
        name: "promotions",
        component: Promotions,
        meta: {
          role: "public",
        },
      },
      {
        path: "promotions/:id",
        name: "promotion-single",
        component: PromotionsSingle,
        meta: {
          role: "public",
        },
      },
      {
        path: "my/:tab",
        name: "my",
        component: My,
        meta: {
          role: "auth",
        },
      },
      {
        path: "page/:slug",
        name: "page",
        component: Page,
        meta: {
          role: "public",
        },
      },
      {
        path: "auth/reset-password",
        name: "reset-password",
        component: resetPassword,
        meta: {
          role: "public",
        },
      },
      {
        path: "auth/:provider/callback",
        name: "auth-provider-callback",
        component: providerCallback,
        meta: {
          role: "public",
        },
      },
      {
        path: "bonuses/:slug",
        name: "bonus",
        component: Bonus,
        meta: {
          role: "public",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!localStorage.getItem("luck-royal-jwt") && to.meta.role == "auth") {
    next({ name: "home" });
  }
  if (to.params.lang) {
    if (to.params.lang !== store.state.global.currentLang) {
      store.dispatch("setLanguage", to.params.lang);
      next();
    }
    next();
  } else {
    if (!to.path.includes("/auth/")) {
      next({
        path: "/" + store.state.global.currentLang + to.path,
        query: to.query,
      });
    } else {
      next();
    }
  }

  if (to.name !== "games") {
    window.scrollTo(0, 0);
  }
});

export default router;
