import { isLogged } from "../composables/auth";
import cashier from "./cashier";
import { toggleAuthModal } from "./modals";
import store from "../store";

export const getBonusPositionAbbr = (
  bonusDepositCount,
  translatedPositions
) => {
  if (!bonusDepositCount) {
    return;
  }

  const bonusDepositCountLastChar =
    bonusDepositCount[bonusDepositCount.length - 1];

  const bonusDepositCountSecondLastChar =
    bonusDepositCount.length > 1
      ? bonusDepositCount[bonusDepositCount.length - 2]
      : "";

  if (
    bonusDepositCountLastChar === "1" &&
    bonusDepositCountSecondLastChar + bonusDepositCountLastChar !== "11"
  ) {
    return translatedPositions?.first || "st";
  }
  if (
    bonusDepositCountLastChar === "2" &&
    bonusDepositCountSecondLastChar + bonusDepositCountLastChar !== "12"
  ) {
    return translatedPositions?.second || "nd";
  }
  if (
    bonusDepositCountLastChar === "3" &&
    bonusDepositCountSecondLastChar + bonusDepositCountLastChar !== "13"
  ) {
    return translatedPositions?.third || "rd";
  }
  return translatedPositions?.default || "th";
};

export const handleButtonAction = async (bonus) => {
  if (isLogged.value) {
    try {
      await store.dispatch("savePlayerAttributes", {
        attributeName: `bonus_rule_id_${bonus.bonus_id}`,
        attributeValue: `attempted`,
      });
      store.commit("setCashierBonus", bonus);
      cashier.open("DEPOSIT", bonus.bonus_id);
      return;
    } catch (err) {
      console.error(err);
    }
  }
  toggleAuthModal({ useCase: "registration", show: true });
};
