import api from "./api";

export default {
  userMeUpdate: async (data) => {
    let result = await api
      .post("/crm/updateUser", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return result;
  },
  createUserWithFacebook: (user) => {
    let payload = user;

    //check btag
    let allCookies = document.cookie;
    let btagCookie = allCookies.match(/btag=([^;]+)/);
    try {
      if (btagCookie && btagCookie.length) {
        payload["affiliateRegistrationCode"] = btagCookie[1];
      }
    } catch (e) {
      console.log(e);
    }

    return api
      .post("/crm/createUser", {
        ...payload,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  },
  createUserWithEmailAndPassword: (user) => {
    let payload = user;

    //check btag
    let allCookies = document.cookie;
    let btagCookie = allCookies.match(/btag=([^;]+)/);
    try {
      if (btagCookie && btagCookie.length) {
        payload["affiliateRegistrationCode"] = btagCookie[1];
      }
    } catch (e) {
      console.log(e);
    }

    return api
      .post("/crm/createUser", {
        ...payload,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  },
  loginWithEmailAndPassword: (email, password, ip_address) => {
    //login strapi
    return api
      .post("/crm/login", {
        identifier: email,
        password: password,
        ip_address: ip_address,
      })
      .then((response) => {
        localStorage.setItem("has-logged-before", true);
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  },
  crmLogout: (sessionKey) => {
    return api
      .post("/crm/logOut", {
        sessionKey,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  },
  fetchUser: () => {
    return api
      .get("/users/me")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  },
};
