import api from "@/providers/api";

export default {
  state: {
    gameProviders: [],
    gameThemes: [],
    selectedFilters: [],
    games: {
      data: [],
      meta: {},
    },
    gamePage: {},
  },
  getters: {
    gameProviders: (state) => state.gameProviders,
    gameThemes: (state) => state.gameThemes,
    selectedFilters: (state) => state.selectedFilters,
    selectedProviders: (state) =>
      state.selectedFilters.filter((filter) => filter.key === "provider"),
    selectedThemes: (state) =>
      state.selectedFilters.filter((filter) => filter.key === "theme"),
    games: (state) => state.games,
    gamePage: (state) => state.gamePage,
  },
  mutations: {
    setGameProviders: (state, data) => {
      state.gameProviders = data;
    },
    setGameThemes: (state, data) => {
      state.gameThemes = data;
    },
    setSelectedFilters: (state, data) => {
      if (typeof data === "object" && !Object.keys(data).length) {
        state.selectedFilters = [];
        return;
      }
      const filterIndex = state.selectedFilters.findIndex(
        (filter) => filter.id === data.id && filter.key === data.key
      );
      if (filterIndex !== -1) {
        state.selectedFilters.splice(filterIndex, 1);
      } else {
        state.selectedFilters.push(data);
      }
    },
    setGames: (state, data) => {
      if (data.data.length && Array.isArray(data.data)) {
        state.games.data.push(...data.data);
      } else if (!data.data.length) {
        state.games.data = [];
      }
      if (
        data.meta &&
        typeof data.meta === "object" &&
        Object.keys(data.meta).length
      ) {
        state.games.meta = data.meta;
      } else if (!data.meta || !Object.keys(data.meta).length) {
        state.games.meta = {};
      }
    },
    setGamePage: (state, data) => {
      state.gamePage = data;
    },
  },
  actions: {
    getGamePage: async ({ commit }, slug) => {
      let { data } = await api.get(
        `/game-pages?filters[slug][$eqi]=${slug}&populate[banners][populate][Banner][populate]=*&populate[game_categories][populate]=*`
      );
      data = { data: data.data[0], meta: data.meta };
      commit("setGamePage", data);
      return data;
    },
    getGames: async ({ commit, getters }, { queryData }) => {
      try {
        const { data } = await api.get(
          `/games/getGamesWithCategoryOrder?${queryData}`
        );
        if (!getters.games.data?.length) {
          commit("setGames", data);
        }
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    getGameProviders: async ({ commit }) => {
      try {
        const { data } = await api.get("/game-providers");
        commit("setGameProviders", data);
      } catch (err) {
        console.error(err);
      }
    },
    getGameThemes: async ({ commit }) => {
      try {
        const { data } = await api.get("/game-themes");
        commit("setGameThemes", data);
      } catch (err) {
        console.error(err);
      }
    },
    getFavouriteGames: async ({ commit, getters }) => {
      try {
        const {
          data: { games },
        } = await api.post("/game/getFavourites");
        if (!getters.games.data?.length) {
          commit("setGames", { data: games });
        }
        return games;
      } catch (err) {
        console.error(err);
      }
    },
  },
};
