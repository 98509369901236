<script setup>
import { computed, defineProps } from "vue";
import { useStore } from "vuex";

// Declarations
const store = useStore();

// Props
const props = defineProps({
  filter: {
    required: true,
    type: Object,
    default: () => ({}),
  },
});

// Computed
const getFilterName = computed(
  () => props.filter.name || props.filter.display_name
);

// Methods
const deleteFilter = () => {
  store.commit("setSelectedFilters", {
    ...props.filter,
    key: props.filter.key,
  });
};
</script>

<template>
  <li class="game-selected-filter d-flex align-items-center">
    <p class="mb-0">{{ getFilterName }}</p>
    <icon
      class="game-selected-filter__icon ms-2"
      variant="close"
      @click="deleteFilter"
    />
  </li>
</template>
<style scoped lang="scss">
.game-selected-filter {
  background: #a80000;
  color: #fff;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 3px 6px;
  border-radius: 6px;
  align-items: center;
  display: inline-flex;
  &__icon {
    fill: #fff;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
}
</style>
