<script setup>
import { ref, computed, inject, onMounted, defineExpose } from "vue";
import { useStore } from "vuex";
import loadingSpinenrBig from "@/components/loading-spinner-big.vue";
import modalBase from "@/components/modals/modal-base.vue";
import activeBonus from "./active-bonus.vue";
import AppFreeSpinBonus from "../../../components/AppFreeSpinBonus.vue";

const bonuses = ref([]);
const isLoading = ref(false);
let modalLoading = ref(false);
let dataLoaded = ref(false);
let modalForfeitRef = ref(null);

let selectedBonus = ref(null);
const store = useStore();
let user = computed(() => store.state.user.user);

const api = inject("api");
const fetchActiveBonuses = async () => {
  isLoading.value = true;
  bonuses.value = [];
  await api
    .post("/crm/bonusesGetActive", {
      sessionKey: user.value.crm_session_key,
    })
    .then((response) => {
      if (
        response.data.PlayerGetActiveBonusesResult &&
        response.data.PlayerGetActiveBonusesResult.Bonuses
      ) {
        bonuses.value =
          response.data.PlayerGetActiveBonusesResult.Bonuses.BonusAwarded;

        if (!Array.isArray(bonuses.value)) {
          bonuses.value = [bonuses.value];
        }
      }
      dataLoaded.value = true;
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const confirmForfeit = async () => {
  modalLoading.value = true;
  await api.post("/crm/forfeitBonus", {
    sessionKey: user.value.crm_session_key,
    bonusAwardedID: selectedBonus.value.BonusAwardedID,
  });

  modalLoading.value = false;
  modalForfeitRef.value.showModal = false;
  fetchActiveBonuses();
};
const forfeitBonus = (bonus) => {
  selectedBonus.value = bonus;
  modalForfeitRef.value.showModal = true;
};

defineExpose({
  fetchActiveBonuses,
});
onMounted(() => {
  fetchActiveBonuses();
});
</script>
<template>
  <div class="active-bonuses">
    <h4>{{ $t("bonuses_active_tab") }}</h4>
    <div
      class="py-3 position-relative"
      :class="{ 'd-flex justify-content-center': isLoading }"
    >
      <loadingSpinenrBig class="my-5 position-absolute" v-if="isLoading" />
      <div class="row">
        <div
          class="col-12 col-md-6 mb-2"
          v-for="b in bonuses"
          :key="b.GivenDate"
        >
          <active-bonus
            :bonus="b"
            @forfeit-bonus="forfeitBonus"
            v-if="b.BonusRule.BaseRule.IsFreeRounds === 'false'"
          />
          <div v-else class="active-bonuses__freespin">
            <AppFreeSpinBonus :bonus="b" />
          </div>
        </div>
      </div>

      <div
        class="no-data text-center py-5"
        v-if="dataLoaded && !isLoading && bonuses.length === 0"
      >
        Nothing found
      </div>
      <modal-base ref="modalForfeitRef">
        <h4>Are You sure to forfeit bonus?</h4>
        <p>
          Lorem ipsum is placeholder text commonly used in the graphic, print,
          and publishing industries for previewing layouts and visual mockups.
        </p>
        <div class="d-flex flex-wrap forfeit-controls">
          <div class="btn btn-primary w-100" @click.prevent="confirmForfeit">
            Forfeit
          </div>
          <div
            class="btn btn-outline-secondary w-100 mt-3"
            @click.prevent="confirmForfeit"
          >
            Cancel
          </div>
        </div>
      </modal-base>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.active-bonuses {
  color: #fff;
  .forfeit-controls {
    .btn-primary {
      &:hover {
        background-color: darken(#e2c778, 10%) !important;
      }
    }
  }
  &__freespin {
    border: 1px solid rgba(#fff, 0.1);
    background: rgba(#fff, 0.1);
    margin-bottom: 12px;
    border-radius: 12px;
    padding: 20px !important;
    padding-bottom: 16px;
  }
  .no-data {
    font-size: 24px;
    color: rgba(#fff, 0.3);
    font-weight: 400;
    margin-top: 20px;
  }
}
</style>
