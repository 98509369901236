<script setup>
import GameCategoryTabs from "../../components/games/GameCategoryTabs.vue";
import GameSearchInput from "../../components/games/GameSearchInput.vue";
import GamesGrid from "./GamesGrid.vue";
import GameSelectedFilters from "../../components/games/GameSelectedFilters.vue";
import { Waypoint } from "vue-waypoint";
import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  inject,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import qs from "qs";
import { useRoute, useRouter } from "vue-router";
import { isLogged } from "../../composables/auth";

// Declarations
const store = useStore();
const route = useRoute();
const router = useRouter();
const $t = inject("translations");
let lastScrollTop = 0;

// Data
const selectedCategoryId = ref(0);
const page = ref(1);
const searchKeywords = ref("");
const gamesListEl = ref(null);
const isLoading = ref(false);
const hideSearchBar = ref(false);
const scrollPlaceholder = ref(null);

// Computed
const getStoreGameCategories = computed(
  () => store.getters.gamePage?.data?.attributes?.game_categories.data || []
);
const getGameCategories = computed(() => {
  if (isLogged.value) {
    return [
      ...getStoreGameCategories.value,
      {
        attributes: { name: $t("pages.games.label_favourite_games") },
        id: "favourites",
      },
    ];
  }
  return getStoreGameCategories.value;
});
const getGames = computed(() => store.getters.games);
const canRequestNextPage = computed(
  () =>
    page.value < getGames.value.meta?.pagination?.pageCount &&
    !isLoading.value &&
    !!getGames.value.data.length
);
const getSelectedProviders = computed(() => store.getters.selectedProviders);
const getSelectedThemes = computed(() => store.getters.selectedThemes);
const getProviderFilters = computed(() =>
  getSelectedProviders.value.map(({ id }) => ({
    game_provider: id,
  }))
);
const getThemeFilters = computed(() =>
  getSelectedThemes.value.map(({ id }) => ({
    game_themes: id,
  }))
);
const getFilters = computed(() => store.getters.selectedFilters);
const isMobile = computed(() => window.innerWidth < 768);

// Methods
const scrollToGames = () => {
  if (scrollPlaceholder.value) {
    const headerHeight = window.innerWidth <= 1024 ? 60 : 100;
    const top = scrollPlaceholder.value.offsetTop - headerHeight;
    setTimeout(() => {
      scroll({ top, behavior: "smooth" });
    }, 100);
  }
};
const openAdvancedGamesFilter = () => {
  store.commit("setShowGamesAdvancedFilters", true);
};
const resetGames = () => {
  if (getGames.value.data?.length && page.value === 1) {
    store.commit("setGames", { data: [], meta: {} });
  }
};
const fetchGames = async () => {
  isLoading.value = true;

  const filters = {
    is_active: {
      $eq: true,
    },
    [isMobile.value ? "is_mobile" : "is_desktop"]: true,
    game_provider: {
      is_active: {
        $eq: true,
      },
    },
  };

  if (
    getFilters.value.length &&
    (getProviderFilters.value.length || getThemeFilters.value.length)
  ) {
    filters.$or = [];
    filters.$or.push(...getProviderFilters.value, ...getThemeFilters.value);
    resetGames();
  } else if (searchKeywords.value) {
    filters.name = {
      $containsi: searchKeywords.value,
    };
    resetGames();
  } else if (selectedCategoryId.value) {
    filters.game_categories = {
      id: {
        $eq: selectedCategoryId.value,
      },
    };
    resetGames();
  }

  const queryData = qs.stringify({
    populate: "*",
    filters,
    pagination: {
      page: page.value,
      pageSize: 20,
    },
    sort: ["order", "createdAt:desc"],
  });

  let games;

  if (selectedCategoryId.value === "favourites") {
    games = await store.dispatch("getFavouriteGames");
  } else {
    games = await store.dispatch("getGames", { queryData });
  }

  isLoading.value = false;

  return games;
};
const handleWaypointScroll = async ({ going, direction }) => {
  // Check if it's going in and scrolling down, then load new page
  if (going === "IN" && direction === "UP") {
    page.value++;

    const games = await fetchGames();

    if (games) {
      store.commit("setGames", { data: games.data, meta: games.meta });
    }
  }
};
const resetPage = () => {
  if (page.value !== 1) {
    page.value = 1;
  }
};
const setSelectedCategoryId = async (categoryId) => {
  if (typeof categoryId !== "string") {
    categoryId = +categoryId;
  }
  if (selectedCategoryId.value !== categoryId) {
    scrollToGames();
    selectedCategoryId.value = categoryId;
    resetPage();
    searchKeywords.value = "";
    store.commit("setSelectedFilters", {});
    await router.replace({ query: { category: categoryId } });
    await fetchGames();
  }
};
const setInitialFilters = async (filters) => {
  await router.replace({ query: { filters } });
};
const setInitialCategory = async (categoryId) => {
  // Check if the category exists in our list
  // If not, just select the first category that is inside the list
  categoryId = isNaN(+categoryId) ? categoryId : +categoryId;
  const categoryIndex = getGameCategories.value.findIndex(
    ({ id }) => id === categoryId
  );
  if (categoryIndex !== -1) {
    await router.replace({ query: { category: categoryId } });
    selectedCategoryId.value = categoryId;
    return;
  }

  const firstCategoryId = getGameCategories.value[0].id;
  router.replace({
    query: { category: firstCategoryId },
  });
  selectedCategoryId.value = firstCategoryId;
};
const setInitialSearchKeywords = async (keywords) => {
  await router.replace({ query: { search: keywords } });
  searchKeywords.value = keywords;
};
const init = async () => {
  const { filters, search, category } = route.query;

  if (!filters) {
    store.commit("setSelectedFilters", []);
  }

  if (filters) {
    await setInitialFilters(filters);
  } else if (search) {
    await setInitialSearchKeywords(search);
  } else if (category) {
    await setInitialCategory(category);
  }
  // If no query params are available, default to the first category in the array
  else if (!filters && !search && !category) {
    await setInitialCategory(getGameCategories.value[0].id);
  }

  await fetchGames();

  // Watchers
  watch(searchKeywords, async (search) => {
    await router.replace({ query: { search } });
    resetPage();
    store.commit("setSelectedFilters", {});
    if (search) {
      selectedCategoryId.value = 0;
      fetchGames();
    }
  });
  watch(
    getFilters,
    async (selectedFilters) => {
      if (searchKeywords.value) {
        return;
      }
      if (!selectedFilters.length) {
        setSelectedCategoryId(
          selectedCategoryId.value || getGameCategories.value[0].id
        );
        return;
      }

      selectedCategoryId.value = 0;
      resetPage();

      const filters = { provider: [], theme: [] };
      if (getSelectedProviders.value.length) {
        filters.provider.push(
          ...getSelectedProviders.value.map(({ Name }) => Name)
        );
      }
      if (getSelectedThemes.value.length) {
        filters.theme.push(...getSelectedThemes.value.map(({ name }) => name));
      }
      await router.replace({
        query: {
          filters: qs.stringify(filters, {
            encode: false,
          }),
        },
      });
      fetchGames();
    },
    { deep: true }
  );
};
const checkScroll = () => {
  // 60 -> max-width: 768px (Mobile)
  const mediumOffsetValue = 59;
  // 100 -> min-width: 768px (Desktop)
  const largeOffsetValue = 99;
  // Assign offset value depending on the screen size
  const offset = !isMobile.value ? largeOffsetValue : mediumOffsetValue;
  if (window.scrollY >= gamesListEl.value.offsetTop - offset) {
    if (window.scrollY > lastScrollTop) {
      hideSearchBar.value = true;
    } else {
      hideSearchBar.value = false;
    }
    lastScrollTop = window.scrollY;
  }

  if (window.scrollY <= gamesListEl.value.offsetTop - 140) {
    store.commit("setIsGameScrolling", false);
  } else {
    store.commit("setIsGameScrolling", true);
  }
};
const loadMore = async () => {
  page.value++;
  const games = await fetchGames();

  if (games) {
    store.commit("setGames", {
      data: games.data,
      meta: games.meta,
    });
  }
};

// Mounted
onMounted(async () => {
  // Add event listener and check where the scroll value is at
  checkScroll();
  document.addEventListener("scroll", checkScroll);
  scrollToGames();
});

// Unmounted
onBeforeUnmount(() => {
  // Remove scroll event listener after component is unmounted
  document.removeEventListener("scroll", checkScroll);
  store.commit("setIsGameScrolling", false);
});
// Unmounted
onUnmounted(() => {
  store.commit("setSelectedFilters", []);
});

// Init
init();
</script>
<template>
  <div ref="scrollPlaceholder"></div>
  <div class="games-list" ref="gamesListEl">
    <div class="container">
      <div
        class="games-list__header row sticky"
        :class="{ 'games-list__header--hide': hideSearchBar }"
      >
        <div class="col-12 col-lg-9">
          <GameCategoryTabs
            :categories="getGameCategories"
            :selected-category-id="selectedCategoryId"
            @set-category="setSelectedCategoryId"
          />
        </div>
        <div
          class="col-12 col-lg-3 d-flex align-items-center justify-content-end"
        >
          <div class="py-3 w-100">
            <GameSearchInput v-model:model-value="searchKeywords" />
          </div>
          <div
            class="games-list__filter-icon"
            :class="{ 'games-list__filter-icon--active': getFilters.length }"
          >
            <icon
              variant="filter"
              fill="#fff"
              @click="openAdvancedGamesFilter"
            />
          </div>
        </div>
      </div>
      <GameSelectedFilters />
      <GamesGrid :isLoading="isLoading" :games="getGames.data" />
      <Waypoint @change="handleWaypointScroll" :active="canRequestNextPage" />
      <div class="d-flex justify-content-center">
        <a
          v-if="canRequestNextPage"
          class="btn btn-primary mt-5"
          role="button"
          @click="loadMore"
          >{{ $t("pages.games.button_load_more") }}</a
        >
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.games-list {
  &__filter-icon {
    margin-left: 16px;
    .svg-icon {
      min-width: 32px;
      min-height: 32px;
      cursor: pointer;
    }
    &--active {
      position: relative;
      &::after {
        top: -9px;
        right: -9px;
        position: absolute;
        display: block;
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #a80000;
      }
    }
  }
  &__header {
    transition: opacity 0.3s ease, transform 0.2s ease;
    &--hide {
      opacity: 0;
      pointer-events: none;
      transform: translateY(-100%);
    }
    &.sticky {
      position: sticky;
      z-index: 20;
      background-color: #102b3c;

      @media (max-width: 768px) {
        top: 58px;
      }
      @media (min-width: 769px) {
        top: 60px;
      }
      @media (min-width: 1025px) {
        top: 98px;
      }
    }
  }
}
</style>
