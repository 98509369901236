<script setup>
import { ref, inject, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { marked } from "marked";
import { handleButtonAction } from "../../helpers/bonus";
import { isLogged } from "../../composables/auth";
import { useStore } from "vuex";
import { handleButtonClick } from "../../helpers/button";

const route = useRoute();
const store = useStore();
let isLoading = ref(false);
let promotion = ref({});
const isActiveBonusesLoading = ref(false);

let markdownText = computed(() => {
  let text = "";
  if (p.value.text) {
    text = marked(p.value.text);
  }
  return text;
});

let p = computed(() => promotion.value?.attributes || {});

let cover = computed(() => {
  let cover = "";
  if (p.value.cover && p.value.cover.data) {
    cover = p.value.cover.data.attributes.url;
  }
  return cover;
});

let buttons = computed(() => {
  let button = [];
  if (p.value.button && p.value.button.length) {
    button = p.value.button;
  }
  return button;
});

const getBonus = computed(() =>
  p.value.bonuses?.data?.length === 1 ? p.value.bonuses?.data[0].attributes : {}
);

const hasBonus = computed(() => !!Object.keys(getBonus.value).length);

const getActiveBonuses = computed(() => store.getters.activeBonuses);

const isBonusUsed = computed(() => {
  if (Array.isArray(getActiveBonuses.value)) {
    return getActiveBonuses.value.some(
      (b) => +b.BonusRuleID === getBonus.value.bonus_id
    );
  } else {
    return +getActiveBonuses.value.BonusRuleID === getBonus.value.bonus_id;
  }
});

const api = inject("api");
let fetchPromotion = async () => {
  isLoading.value = true;
  let id = route.params.id;
  let result = await api
    .get(`/promotions/${id}?populate=*`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  promotion.value = result.data;
  isLoading.value = false;
};

onMounted(async () => {
  await fetchPromotion();
  if (isLogged.value) {
    isActiveBonusesLoading.value = true;
    await store.dispatch("fetchActiveBonuses");
    isActiveBonusesLoading.value = false;
  }
});
</script>
<template>
  <div class="promotion">
    <div class="bg" :style="{ backgroundImage: 'url(' + cover + ')' }"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <div
            class="promotion__cover"
            :style="{ backgroundImage: 'url(' + cover + ')' }"
          ></div>
          <div class="promotion__body">
            <div
              class="promotion__bonus d-flex justify-content-between align-items-center"
              v-if="hasBonus && !isActiveBonusesLoading"
            >
              <div class="promotion__bonus-code">
                <span>{{ $t("promotion_single.label_bonus_code") }} </span>
                {{ getBonus.bonus_code }}
              </div>
              <div class="cta" v-if="!isBonusUsed">
                <a
                  role="button"
                  class="btn btn-primary"
                  @click="!isBonusUsed ? handleButtonAction(getBonus) : null"
                  >{{ $t("promotion_single.button_deposit_now") }}</a
                >
              </div>
              <p class="m-0" v-if="isBonusUsed">
                {{ $t("promotion_single.message_bonus_already_used") }}
              </p>
            </div>
            <div class="content">
              <h1>
                {{ p.title }}
              </h1>
              <h4>
                {{ p.excerpt }}
              </h4>
              <div v-html="markdownText"></div>
              <div class="cta mt-5">
                <a
                  v-for="b in buttons"
                  :key="b.id"
                  role="button"
                  @click="handleButtonClick(b)"
                  class="btn btn-primary btn-lg me-3"
                  :target="b.external_link ? '_blank' : '_self'"
                >
                  {{ b.button_text }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.promotion {
  position: relative;
  &__bonus {
    padding: 12px;
  }
  &__body {
    background: #fff;
  }
  &__bonus,
  .content {
    .cta {
      a {
        font-size: 20px;
        height: 48px;
        &:hover {
          background: darken(#f1ca4b, 10%) !important;
        }
      }
    }
  }
  .content {
    padding-bottom: 80px;
    margin-bottom: 60px;
    padding: 40px 100px 300px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    @media (max-width: 991px) {
      padding: 40px 60px 300px;
    }
    @media (max-width: 475px) {
      padding: 40px 20px 160px;
    }
    h1 {
      font-weight: bold;
      font-size: 48px;
      margin-bottom: 30px;
      line-height: 1.2;
      @media (max-width: 475px) {
        font-size: 42px;
      }
    }
    h4 {
      font-weight: bold;
      font-size: 28px;
      @media (max-width: 475px) {
        font-size: 22px;
      }
    }
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(17, 43, 60, 0.85);
      backdrop-filter: blur(20px);
    }
  }
  &__cover {
    margin: 60px 0px 0px;
    aspect-ratio: 4;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-size: cover;
    background-position: center top;
    background-color: rgba(17, 43, 60, 1);
    @media (max-width: 991px) {
      aspect-ratio: 2;
    }
  }
}
</style>
