<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
const openGame = (gameId) => {
  let event = new CustomEvent("openGame", {
    detail: {
      gameID: gameId,
    },
  });
  window.dispatchEvent(event);
};
onMounted(() => {
  let gameId = route.params.id;
  if (gameId) {
    openGame(gameId);
  }
});
</script>
<template>
  <div></div>
</template>
<style lang="scss" scoped></style>
