import api from "../../providers/api";
import qs from "qs";

export default {
  state: {
    homepageData: {},
  },
  getters: {
    homepageData: (state) => state.homepageData,
  },
  mutations: {
    setHomepageData: (state, data) => {
      state.homepageData = data;
    },
  },
  actions: {
    async fetchHomepageData({ commit }) {
      try {
        const { data } = await api.get("/homepage/getHomepageInitialData");
        commit("setHomepageData", data);
        return data.homepageSettings;
      } catch (err) {
        console.error(err);
      }
    },

    fetchHomepageBanners() {
      let bannersQuery = qs.stringify(
        {
          filters: {
            location_name: {
              $contains: "home",
            },
          },
          populate: {
            Banner: {
              populate: "*",
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );
      return api.get(`/banners?${bannersQuery}`);
    },
  },
};
