<script setup>
import { ref, onMounted, computed } from "vue";
import promotionCard from "./promotion-card.vue";
import { useStore } from "vuex";

const store = useStore();

let isLoading = ref(false);

let dummies = computed(() => {
  return isLoading.value ? 6 : 0;
});
const getPromotions = computed(() => store.getters.promotions);

let fetchPromotions = async () => {
  isLoading.value = true;
  store.dispatch("fetchPromotions");
  isLoading.value = false;
};

onMounted(() => {
  fetchPromotions();
});
</script>
<template>
  <div class="container">
    <div class="promotions pt-5">
      <h1 class="promotions__title">
        {{ $t("pages.promotions.title") }}
      </h1>
      <div class="promotions__list">
        <promotion-card v-for="p in getPromotions" :promotion="p" :key="p.id" />
        <div class="promotions__card dummy" v-for="n in dummies" :key="n"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.promotions {
  &__card {
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 15px 24px -5px rgb(0 0 0 / 37%);
    background-color: rgb(17, 43, 60);
    color: #fff;
    text-decoration: none;
    display: flex;
    padding-bottom: 40px;
    background-size: cover;
    background-position: center top;
    position: relative;
    overflow: hidden;
    min-height: 200px;
    @media (max-width: 475px) {
      padding: 12px 20px;
      min-height: 160px;
    }
  }
}
.promotions {
  &__list {
    margin: 40px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    grid-auto-rows: 1fr;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 1440px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  &__title {
    font-weight: 800;
    font-size: 32px;
    line-height: 1.3;
    color: #ffffff;
    margin-bottom: 26px;
  }
}
</style>
