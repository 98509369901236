<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";

// Declarations
const store = useStore();

// Props
const props = defineProps({
  filter: {
    required: true,
    type: Object,
  },
});

// Computed
const getSelectedFilterName = computed(
  () => props.filter.name || props.filter.display_name
);

// Methods
const removeSelection = () => {
  store.commit("setSelectedFilters", {
    id: props.filter.id,
    key: props.filter.key,
  });
};
</script>
<template>
  <div
    class="filter-selected-item d-flex align-items-center justify-content-between"
    @click="removeSelection"
  >
    <p class="filter-selected-item__title m-0">{{ getSelectedFilterName }}</p>
    <span class="filter-selected-item__remove d-flex align-items-center ms-2">
      <icon variant="close" />
    </span>
  </div>
</template>
<style scoped lang="scss">
.filter-selected-item {
  padding: 3px 6px;
  color: #fff;
  background: #a80000;
  border-radius: 6px;
  margin: 3px;
  cursor: pointer;
  &__title {
    font-size: 13px;
    font-weight: 700;
  }
  &__remove {
    .svg-icon {
      width: 12px;
      height: 12px;
      fill: #fff;
    }
  }
}
</style>
