import api from "@/providers/api";

export default {
  state: {
    cashierUrl: "",
    showCashierModal: false,
    cashierModalTitle: "",
    isCashierLoading: false,
    cashierBonus: {},
  },
  getters: {
    cashierUrl: (state) => state.cashierUrl,
    showCashierModal: (state) => state.showCashierModal,
    cashierModalTitle: (state) => state.cashierModalTitle,
    isCashierLoading: (state) => state.isCashierLoading,
    cashierBonus: (state) => state.cashierBonus,
  },
  mutations: {
    setCashierUrl: (state, data) => {
      state.cashierUrl = data;
    },
    setShowCashierModal: (state, data) => {
      state.showCashierModal = data;
    },
    setCashierModalTitle: (state, data) => {
      state.cashierModalTitle = data;
    },
    setIsCashierLoading: (state, data) => {
      state.isCashierLoading = data;
    },
    setCashierBonus: (state, data) => {
      state.cashierBonus = data;
    },
  },
  actions: {
    openCashier: async ({ commit, getters }, { action, bonusRuleId }) => {
      try {
        const requestData = {
          action,
          sessionKey: getters.user.crm_session_key,
          locale:
            getters.data.praxis_locale_mapping[getters.currentLang] || "en-GB",
        };
        if (bonusRuleId) {
          requestData.bonusRuleId = bonusRuleId;
        }
        commit("setIsCashierLoading", true);
        const { data } = await api.post("/crm/openCashier", requestData);
        commit("setCashierUrl", data?.CashierUrl);
        commit("setIsCashierLoading", false);
      } catch (err) {
        console.error(err);
        commit("setIsCashierLoading", false);
      }
    },
  },
};
