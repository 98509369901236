<script setup>
import { ref, computed, watch, onMounted, inject } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import myAccount from "./my-account.vue";
import GameHistory from "./game-history.vue";
import Bonuses from "./bonuses.vue";
import transactionsHisotry from "./transactions-history.vue";
import kyc from "./kyc.vue";

const $t = inject("translations");

const store = useStore();
const router = useRouter();
const route = useRoute();

let activeTab = ref("account");
const sidebarMenu = ref([
  {
    key: "account",
    title: $t("pages.user.my_account.title"),
    icon: "joystick",
    string_key: "my_account",
  },
  {
    key: "game-history",
    title: $t("pages.user.game_history.title"),
    icon: "joystick",
    string_key: "game_history",
  },
  {
    key: "transactions",
    title: $t("pages.user.transactions.title"),
    icon: "joystick",
    string_key: "transactions",
  },
  {
    key: "bonuses",
    title: $t("pages.user.bonuses.title"),
    icon: "joystick",
    string_key: "bonuses",
  },
  {
    key: "kyc",
    title: $t("pages.user.kyc.title"),
    icon: "joystick",
    string_key: "kyc",
  },
  {
    key: "responsible-gaming",
    title: $t("pages.user.responsible_gaming.title"),
    icon: "joystick",
    string_key: "responsible_gaming",
  },
  {
    key: "logout",
    title: $t("pages.user.title_logout"),
    icon: "joystick",
    string_key: "title_logout",
  },
]);

let activeTabTitle = computed(() => {
  let title = "";
  let activeTabItem = sidebarMenu.value.find(
    (item) => item.key === activeTab.value
  );

  let key = activeTabItem ? activeTabItem.string_key : null;
  if (key) {
    title = $t(`pages.user.${key}.title`);
  } else {
    title = activeTabItem.title;
  }

  /// Code snippet to render titles if they are not present in local state or data coming from backend
  if (!title || title === undefined) {
    title = activeTabItem.title;
  }

  return title;
});

let user = computed(() => store.getters.user);

watch(activeTab, (newData) => {
  if (newData === "logout") {
    logOut();
  }
});

watch(
  () => route.params,
  (params) => {
    activeTab.value = params.tab;
  }
);

let logOut = async () => {
  await store.dispatch("logOut", user.value.crm_session_key);
};
const goToTab = async (m) => {
  await router.replace({ name: "my", params: { tab: m.key } });
  activeTab.value = m.key;
};

onMounted(() => {
  if (router.currentRoute.value.params.tab) {
    activeTab.value = router.currentRoute.value.params.tab;
  }
});
</script>
<template>
  <div class="my">
    <div class="container my_account-container">
      <div class="row">
        <div class="col-12 d-none d-md-block col-md-3">
          <div class="my__sidebar mb-5">
            <ul class="menu me-4 pr-0 mt-0">
              <li
                v-for="m in sidebarMenu"
                :key="m.key"
                :class="{ active: m.key == activeTab }"
              >
                <a role="button" @click="goToTab(m)">
                  <icon :variant="m.icon" />
                  {{ m.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-9 position-relative">
          <h1 class="mb-3">
            {{ activeTabTitle }}
          </h1>
          <my-account v-if="activeTab == 'account'" key="account" />
          <game-history v-if="activeTab == 'game-history'" key="gameHistory" />
          <transactionsHisotry
            v-if="activeTab == 'transactions'"
            key="transactions"
          />
          <kyc v-if="activeTab == 'kyc'" key="kyc" />
          <Bonuses v-if="activeTab == 'bonuses'" key="bonuses" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.my {
  padding-top: 35px;

  .container.my_account-container {
    margin-bottom: 80px;
  }

  h1 {
    color: #fff;
    font-size: 1.5rem;
  }
  &__sidebar {
    .menu {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        padding: 0;
        margin: 0;
        padding-bottom: 6px;
        a {
          display: block;
          padding: 4px 16px;
          border-radius: 8px;
          transition: 0.3s;
          color: #fff;
          text-decoration: none;
          font-weight: 400;
          font-size: 1 rem;
          position: relative;
          padding-left: 45px;
          svg {
            fill: #fff;
            position: absolute;
            left: 14px;
            top: 50%;
            transform: translateY(-50%);
            width: 22px;
            height: 22px;
          }
          &:hover {
            transition: 0.3s;
            background-color: rgba(#fff, 0.1);
          }
        }
        &.active {
          a {
            color: var(--app-yellow);
            background-color: rgba(#fff, 0.1);
          }
        }
      }
    }
  }
}
</style>
