<script setup>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import bonusesHistory from "./bonuses/bonuses-history.vue";
import activeBonuses from "./bonuses/active-bonuses.vue";
import redeemCode from "./bonuses/redeem-code.vue";

const route = useRoute();
const router = useRouter();

let activeBonusesRef = ref(null);
let current_tab = ref();
let tabs = ref([
  {
    name: "title_tab_active_bonuses",
    id: "active",
  },
  {
    name: "title_tab_history_bonuses",
    id: "history",
  },
]);

// Methods
const setTab = (tab) => {
  current_tab.value = tab;
  router.replace({ query: { tab: tab.id } });
};
const refetchActiveBonuses = () => {
  activeBonusesRef.value.fetchActiveBonuses();
};

// Watchers
watch(
  () => route.query.tab,
  (routeTab) => {
    if (routeTab) {
      const found = tabs.value.find((tab) => tab.id === routeTab);
      if (found) {
        current_tab.value = found;
      } else {
        setTab(tabs.value[0]);
      }
    } else {
      current_tab.value = setTab(tabs.value[0]);
    }
  },
  { immediate: true }
);
</script>
<template>
  <div class="w-100 bonuses">
    <div class="bonuses__tabs-controls-item" v-for="t in tabs" :key="t.id">
      <a
        href="#"
        :class="{ active: current_tab?.id === t.id }"
        @click.prevent="setTab(t)"
      >
        <span>{{ $t(`pages.user.bonuses.${t.name}`) }}</span>
      </a>
    </div>
    <div class="bonuses__tabs-content mt-4">
      <redeem-code
        @redeem="refetchActiveBonuses"
        v-if="current_tab?.id == 'active'"
      />
      <active-bonuses
        ref="activeBonusesRef"
        v-if="current_tab?.id == 'active'"
      />
      <bonuses-history v-if="current_tab?.id == 'history'" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bonuses {
  &__tabs-controls {
    max-width: 100%;
    overflow: hidden;

    @media (max-width: 768px) {
      overflow-x: scroll;
      & > div:first-child {
        margin-left: var(--bs-gutter-x);
      }
      & > div:last-child {
        margin-right: var(--bs-gutter-x);
      }
      &:before {
        content: "";
        display: block;
        width: calc(var(--bs-gutter-x) * 1.5);
        height: 100%;
        background: linear-gradient(
          to left,
          rgba(255, 0, 0, 0),
          rgba(16, 43, 60, 1)
        );
        position: absolute;
        top: 0;
        left: 0;
      }
      &:after {
        content: "";
        display: block;
        width: calc(var(--bs-gutter-x) * 1.5);
        height: 100%;
        background: linear-gradient(
          to right,
          rgba(255, 0, 0, 0),
          rgba(16, 43, 60, 1)
        );
        position: absolute;
        top: 0;
        right: 0;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }
  }
  &__tabs-controls-item {
    display: inline-block;
    a {
      display: block;
      padding: 21px 18px;
      color: #fff;
      text-decoration: none;
      border-bottom: 2px solid #546773;
      transition: 0.1s;
      white-space: nowrap;
      svg {
        fill: #fff;
        width: 14px;
        height: 14px;
      }
      &.active {
        color: var(--app-yellow);
        border-color: var(--app-yellow);
        transition: 0.1s;
        svg {
          fill: var(--app-yellow);
        }
        &:hover {
          color: var(--app-yellow);
        }
      }
      &:hover {
        color: var(--app-yellow-dark);
        transition: 0.3s;
        svg {
          fill: var(--app-yellow);
        }
      }
    }
  }
}
</style>
