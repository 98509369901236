<script setup>
import { computed, defineProps, defineEmits } from "vue";
import stepOne from "./registration/step-one.vue";
import stepTwo from "./registration/step-two.vue";

const emit = defineEmits(["nextStep", "finish"]);

const props = defineProps({
  step: {
    type: Number,
    default: 1,
  },
});

let emitNextStep = () => {
  emit("nextStep");
};
let finishRegistration = () => {
  emit("finish");
};
let step = computed(() => {
  return props.step;
});
</script>
<template>
  <div class="registration">
    <template v-if="step == 1">
      <step-one @nextStep="emitNextStep" />
    </template>
    <template v-if="step == 2">
      <step-two @finish="finishRegistration" />
    </template>
  </div>
</template>
<style lang="scss">
.registration {
  .vti__dropdown-item.highlighted {
    background: lighten(#0c1d29, 20%);
  }
  .cta {
    .btn {
      box-shadow: 0px 10px 25px -8px rgba(0, 0, 0, 0.26);
    }
  }
  .form-input {
    position: relative;
    margin-bottom: 12px;
    box-shadow: 0px 19px 25px -8px rgba(0, 0, 0, 0.17);
    .eye {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      width: 20px;
      height: 20px;
      fill: #666;
      transition: 0.3s;
      &:hover {
        fill: #f26001;
        transition: 0.3s;
      }
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 0%;
      width: 58px;
      text-align: center;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 545px) {
        width: 42px;
      }
      svg {
        fill: #818385;
        width: 24px;
        height: 24px;
        @media (max-width: 545px) {
          width: 18px;
          height: 18px;
        }
      }
    }
    &.error {
      & > input,
      .input-mock {
        box-shadow: 0px 19px 25px -8px rgba(0, 0, 0, 0.17),
          inset 0px 0px 0px 1px #ff0000 !important;
      }
    }
    & > input,
    .input-mock {
      background: #d9d9d9;
      border-radius: 15px;
      height: 48px;
      border: none;
      outline: none;
      padding-left: 58px;
      min-width: 100%;
      @media (max-width: 545px) {
        height: 42px;
        padding-left: 42px;
      }
      .dp__main {
        height: 100%;
        input {
          border: 0;
          padding: 0;
        }
        div[role="textbox"],
        .dp__input_wrap,
        .dp__input {
          height: 100%;
        }
      }
      .vue-tel-input,
      .dp__main {
        input {
          background: transparent;
        }
      }
      .vue-tel-input {
        position: absolute;
        left: 1px;
        right: 1px;
        top: 1px;
        bottom: 1px;
        padding-left: 58px;
        padding-right: 8px;
        border: none;
        @media (max-width: 545px) {
          padding-left: 42px;
        }
        .vti__dropdown {
          &:hover {
            background: rgba(#000, 0.1);
          }
        }
        .vti__dropdown-list {
          border-radius: 12px;
          border: none;
          background: lighten(#0c1d29, 10%);
          top: calc(100% - 1px);
          z-index: 100;
          * {
            color: rgba(#fff, 0.8);
          }
        }
        &:focus-within {
          border: none;
          box-shadow: none;
        }
      }
      .v-select {
        position: absolute;
        left: 0%;
        right: 0;
        top: 0;
        bottom: 0;
        padding-left: 58px;
        padding-right: 8px;
        @media (max-width: 545px) {
          padding-left: 32px;
        }
        .vs__selected {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 90%;
          min-width: 60px;
        }
        .vs__selected-options {
          @media (max-width: 575px) {
            max-width: 90%;
            flex-wrap: nowrap;
          }
        }
        &.vs--open {
          .vs__selected {
            top: 50%;
            transform: translateY(-50%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 90%;
          }
        }
      }
      .vs__dropdown-toggle {
        height: 100% !important;
        border: none;
      }
    }
  }
}
</style>
