import axios from "axios";
import store from "../store/index";
import { useToast } from "vue-toastification";

const toast = useToast();

// const getLangCode = (url) => {
//   let langCode = url.match(/\/[a-z]{2}\//);
//   if (langCode) {
//     let code = langCode[0].replace("/", "");
//     code = code.replace("/", "");
//     return code;
//   }
//   if (window.localStorage.getItem("luckroyal-lang")) {
//     return window.localStorage.getItem("luckroyal-lang");
//   }
//   return "en";
// };

// Set the baseURL depending on the environment
const env = process.env.VUE_APP_ENV.toLowerCase();
const baseURL =
  env === "dev"
    ? process.env.VUE_APP_LOCAL_STRAPI_URL
    : process.env.VUE_APP_PRODUCTION_STRAPI_URL;

let axiosApi = axios.create({
  baseURL,
});
axiosApi.interceptors.request.use(
  function (config) {
    let headers = {
      "Content-Type": "application/json",
    };
    if (localStorage.getItem("luck-royal-jwt")) {
      headers["Authorization"] =
        "Bearer " + localStorage.getItem("luck-royal-jwt");
    }
    if (store.getters.geodata.country) {
      headers["User-Country"] = store.getters.geodata.country;
    }
    config.headers = headers;
    config.params = {
      locale: store.getters.currentLang,
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  (config) => config,
  async (error) => {
    if (error?.response?.status === 401) {
      store.commit("setUser", {});
      store.commit("setJwt", null);
      localStorage.removeItem("luck-royal-jwt");
      toast.error("Session expired, please log in again", {
        timeout: false,
      });
      window.location.replace("/");
    }
    return Promise.reject(error);
  }
);

export default axiosApi;
