<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

let info = computed(() => {
  let info = null;
  if (store.state?.global.data?.prefooter_info_block) {
    info = store.state?.global.data?.prefooter_info_block[0];
  }
  return info;
});

let background = computed(() => {
  let path = "";
  if (
    info.value.background_image &&
    info.value.background_image.data &&
    info.value.background_image.data.attributes.url
  ) {
    path = info.value.background_image.data.attributes.url;
  }
  return path;
});

let flags = computed(() => {
  let flags = [];
  if (info.value.flags && info.value.flags.data) {
    flags = info.value.flags.data.map((el) => {
      return {
        url: el.attributes.url,
      };
    });
  }
  return flags;
});
</script>
<template>
  <div
    v-if="info"
    class="home__prefooter"
    :style="{
      backgroundImage: 'url(' + background + ')',
    }"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8">
          <div class="flags d-flex justify-content-center">
            <div
              class="flag"
              v-for="(f, i) in flags"
              :key="i"
              :style="{
                backgroundImage: 'url(' + f.url + ')',
              }"
            ></div>
          </div>
          <div class="text text-center" v-html="info?.text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home__prefooter {
  background-size: cover;
  background-position: center;
  padding: 86px 0px;
  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
  }
  .flags {
    margin-bottom: 62px;
    flex-wrap: wrap;
    .flag {
      width: 100px;
      height: 100px;
      min-width: 100px;
      margin: 10px 40px;
      background-color: #fff;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      @media (max-width: 768px) {
        margin: 10px 12px;
      }
    }
  }
}
</style>
