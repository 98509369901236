<script setup>
import { ref, computed, defineEmits, onMounted, inject } from "vue";
import { useStore } from "vuex";
import vSelect from "vue-select";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import qs from "qs";
import _ from "lodash";

const toast = useToast();

const emit = defineEmits(["finish"]);

const store = useStore();
const route = useRoute();
const $t = inject("translations");
const days = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];
const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
const startYear = new Date().getFullYear() - 100;
const endYear = new Date().getFullYear() - 18;
const years = Array.from(
  { length: endYear - startYear + 1 },
  (_, i) => endYear - i
);

let validate = ref(false);
let isLoading = ref(false);
let countries = ref([]);
let userData = ref({
  first_name: "",
  last_name: "",
  day: "",
  month: "",
  year: "",
  country: "",
  gender: null,
  address_1: "",
  accept_promotions: true,
});
let genders = ref([
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
]);

let valid = computed(() => {
  let valid = {
    first_name: true,
    last_name: true,
    day: true,
    month: true,
    year: true,
    country: true,
    gender: true,
    address_1: true,
  };
  if (userData.value.first_name.length < 2) {
    valid.first_name = false;
  }
  if (userData.value.last_name.length < 2) {
    valid.last_name = false;
  }
  if (!userData.value.day) {
    valid.day = false;
  }
  if (!userData.value.month) {
    valid.month = false;
  }
  if (!userData.value.year) {
    valid.year = false;
  }
  if (!userData.value.country) {
    valid.country = false;
  }
  if (!userData.value.gender) {
    valid.gender = false;
  }
  if (!userData.value.address_1) {
    valid.address_1 = false;
  }
  return valid;
});

let buttonDisabled = computed(() => {
  let disabled = false;
  for (var key in valid.value) {
    if (!valid.value[key]) {
      disabled = true;
    }
  }
  return disabled;
});

const getOrderedCountries = computed(() =>
  _.orderBy(countries.value, ["label"], ["asc"])
);

const api = inject("api");
const getCountriesList = async () => {
  let query = qs.stringify({
    pagination: {
      limit: -1,
    },
    filters: {
      active: {
        $eq: true,
      },
    },
  });
  await api.get("/countries?" + query).then((response) => {
    if (response.data.data) {
      countries.value = response.data.data.map((country) => {
        return {
          label: country.attributes.name,
          value: country.attributes.code,
        };
      });
      userData.value.country =
        countries.value.find(
          (country) => country.value === store.getters.geodata.country
        )?.value || store.getters.geodata.country;
    } else {
      countries.value = [];
    }
  });
};

let providerData = computed(() => {
  return store.state.auth.providerData;
});

let firstStepData = computed(() => {
  return store.state.auth.registrationData.step_one;
});

const mapProviderData = () => {
  if (route.query.provider === "google") {
    userData.value.first_name = providerData.value.user.first_name;
    userData.value.last_name = providerData.value.user.last_name;
  }
  if (providerData.value.user.username.split(" ").length > 1) {
    userData.value.first_name = providerData.value.user.username.split(" ")[0];
    userData.value.last_name = providerData.value.user.username.split(" ")[1];
  }
};

const updateUserdata = async () => {
  let data = JSON.parse(JSON.stringify(userData.value));
  data["id"] = store.state.user.user.id;
  data["session_key"] = store.state.user.user.crm_session_key;
  data[
    "date_of_birth"
  ] = `${userData.value.year}-${userData.value.month}-${userData.value.day}`;
  delete data["day"];
  delete data["month"];
  delete data["year"];
  isLoading.value = true;
  let result = await store.dispatch("userMeUpdate", data);
  if (result.id) {
    toast.success('"Profile updated successfully"', {
      timeout: 2000,
    });
    isLoading.value = false;
    emit("finish");
  } else {
    toast.error("Something went wrong", {
      timeout: 2000,
    });
    isLoading.value = false;
  }
  isLoading.value = false;
};

let isProviderAuth = computed(() => {
  let state = false;
  if (route.query.provider && store.state.auth.providerData.jwt) {
    state = true;
  }

  return state;
});
const finishProviderRegistration = async () => {
  store.dispatch("setJwt", providerData.value.jwt);
  let user = JSON.parse(JSON.stringify(firstStepData.value));
  user["first_name"] = userData.value.first_name;
  user["last_name"] = userData.value.last_name;
  user[
    "date_of_birth"
  ] = `${userData.value.year}-${userData.value.month}-${userData.value.day}`;
  user["country"] = userData.value.country;
  user["address_1"] = userData.value.address_1;
  user["id"] = providerData.value.user.id;
  delete user["email"];
  delete user["day"];
  delete user["month"];
  delete user["year"];
  let result = await store.dispatch("userMeUpdate", user);
  if (result.id) {
    toast.success($t("toaster.success.message_profile_updated"), {
      timeout: 2000,
    });
    await store.dispatch("setProviderData", {});
    route.query.provider = "";
    isLoading.value = false;
    emit("finish");
  } else {
    toast.error($t("toaster.error.message_general_error"), {
      timeout: 2000,
    });
    isLoading.value = false;
  }
};

onMounted(() => {
  getCountriesList();

  setTimeout(() => {
    if (isProviderAuth.value) {
      mapProviderData();
    }
  }, 400);
});
</script>
<template>
  <form
    class="step-two"
    @submit.prevent="
      isProviderAuth ? finishProviderRegistration() : updateUserdata()
    "
  >
    <h4 class="text-center mt-5 mb-3">
      {{ $t("registration_form.title_finish_registration") }}
    </h4>
    <div class="form-input" :class="{ error: !valid.first_name && validate }">
      <div class="icon">
        <icon :size="32" :variant="'user'" />
      </div>
      <input
        type="text"
        id="first_name"
        v-model="userData.first_name"
        :placeholder="$t('registration_form.input_placeholder_first_name')"
      />
    </div>
    <div class="form-input" :class="{ error: !valid.first_name && validate }">
      <div class="icon">
        <icon :size="32" :variant="'user'" />
      </div>
      <input
        type="text"
        id="last_name"
        v-model="userData.last_name"
        :placeholder="$t('registration_form.input_placeholder_last_name')"
      />
    </div>
    <div class="row row-date">
      <p class="col-12 birthdate-label">
        {{ $t("registration_form.label_birthdate") }}
      </p>
      <div class="col-4 col-date">
        <div
          class="form-input form-input--select position-relative"
          :class="{ error: !valid.day && validate }"
        >
          <div class="input-mock input-mock--date">
            <v-select
              :clearable="false"
              :options="days"
              :placeholder="$t('registration_form.input_placeholder_day')"
              v-model="userData.day"
              :value="userData.day"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <icon class="dropdown" :variant="'dropdown'" />
                </span>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-4 col-date">
        <div
          class="form-input form-input--select position-relative"
          :class="{ error: !valid.month && validate }"
        >
          <div class="input-mock input-mock--date">
            <v-select
              :clearable="false"
              :options="months"
              :placeholder="$t('registration_form.input_placeholder_months')"
              v-model="userData.month"
              :value="userData.month"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <icon class="dropdown" :variant="'dropdown'" />
                </span>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-4 col-date">
        <div
          class="form-input form-input--select position-relative"
          :class="{ error: !valid.year && validate }"
        >
          <div class="input-mock input-mock--date">
            <v-select
              :clearable="false"
              :options="years"
              :placeholder="$t('registration_form.input_placeholder_years')"
              v-model="userData.year"
              :value="userData.year"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <icon class="dropdown" :variant="'dropdown'" />
                </span>
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </div>

    <div class="form-input" :class="{ error: !valid.country && validate }">
      <div class="icon">
        <icon :variant="'globe'" />
      </div>
      <div class="input-mock input-mock--countries">
        <v-select
          :clearable="false"
          :options="getOrderedCountries"
          :placeholder="$t('registration_form.input_placeholder_country')"
          :reduce="(country) => country.value"
          v-model="userData.country"
          :value="userData.country"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <icon class="dropdown" :variant="'dropdown'" />
            </span>
          </template>
        </v-select>
      </div>
    </div>
    <div class="form-input" :class="{ error: !valid.gender && validate }">
      <div class="icon">
        <icon :variant="'gender'" />
      </div>
      <div class="input-mock">
        <v-select
          :clearable="false"
          :options="genders"
          :placeholder="$t('registration_form.input_placeholder_gender')"
          :reduce="(gender) => gender.value"
          v-model="userData.gender"
          :value="userData.gender"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <icon class="dropdown" :variant="'dropdown'" />
            </span>
          </template>
        </v-select>
      </div>
    </div>

    <div class="form-input" :class="{ error: !valid.first_name && validate }">
      <div class="icon">
        <icon :size="32" :variant="'location'" />
      </div>
      <input
        type="text"
        id="address_1"
        v-model="userData.address_1"
        :placeholder="$t('registration_form.input_placeholder_address_1')"
      />
    </div>
    <div class="terms-and-conditions custom-checkbox pt-4 mb-3">
      <label for="accept-promotions">
        <input
          type="checkbox"
          v-model="userData.accept_promotions"
          id="accept-promotions"
        />
        <span class="pseudo__checkbox">
          <icon :variant="'check'" />
        </span>
        {{ $t("registration_form.text_receive_pomotional_letters") }}
      </label>
    </div>
    <div class="cta pt-2">
      <button
        type="submit"
        class="btn btn-primary w-100"
        :disabled="buttonDisabled"
      >
        {{ $t("registration_form.button_finish_registration") }}
      </button>
      <button
        v-if="isProviderAuth"
        type="submit"
        class="btn btn-primary w-100 d-none"
        :disabled="buttonDisabled"
      >
        {{ $t("registration_form.button_finish_registration") }}
      </button>
    </div>
  </form>
</template>
<style lang="scss">
.input-mock--date .v-select {
  padding-left: 15px !important;
}
.birthdate-label {
  margin-bottom: 3px;
}
.row-date {
  margin-left: -3px !important;
  margin-right: -3px !important;
}
.col-date {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.input-mock--countries {
  @media (max-width: 769px) {
    .vs__dropdown-menu {
      max-height: 170px;
    }
  }
}
</style>
