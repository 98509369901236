<script setup>
import { defineProps, computed, inject } from "vue";
import { useStore } from "vuex";
import { isLogged } from "../composables/auth";
import { toggleAuthModal } from "../helpers/modals";

// Declarations
const store = useStore();
const $t = inject("translations");

// Props
const props = defineProps({
  bonus: {
    required: true,
    type: Object,
    default: () => ({}),
  },
});

// Computed
const getFreeSpinsTitle = computed(
  () =>
    `${$t("header.gift.title")} ${
      props.bonus?.CwFreeRound?.FreeRoundsAwarded
    } ${props.bonus.CwFreeRound?.FreeRoundStatus}`
);
const getAmount = computed(
  () =>
    `${$t("header.gift.text_amount")} ${
      props.bonus.CwFreeRound?.FreeRoundsAwarded
    }`
);
// const getAmountLeft = computed(
//   () =>
//     `${$t("header.gift.text_amount_left")} ${
//       props.bonus.CwFreeRound?.FreeRoundsRemaining
//     }`
// );
const getGivenDate = computed(
  () =>
    `${$t("header.gift.text_given_date")} ${props.bonus.GivenDate.split(
      " "
    )[0].replaceAll("-", "/")}`
);
const getExpiryDate = computed(
  () =>
    `${$t("header.gift.text_expiry_date")} ${props.bonus.ExpiryDate.split(
      " "
    )[0].replaceAll("-", "/")}`
);
const isGamesObject = computed(
  () => typeof props.bonus.CwFreeRound?.FreeRoundsGames === "object"
);
const getGames = computed(() =>
  props.bonus.CwFreeRound?.FreeRoundsGames &&
  Object.keys(props.bonus.CwFreeRound?.FreeRoundsGames).length
    ? isGamesObject.value
      ? [
          {
            ...props.bonus.CwFreeRound?.FreeRoundsGames
              .GameManufacturerGameData,
          },
        ]
      : props.bonus.CwFreeRound?.FreeRoundsGames
    : []
);
const openGame = (gameId) => {
  if (isLogged.value) {
    if (window.innerWidth < 768) {
      window.scrollTo(0, window.scrollY + 1);
    }
    let event = new CustomEvent("openGame", {
      detail: {
        gameID: gameId,
      },
    });
    window.dispatchEvent(event);
  } else {
    if (store.getters.hasBeenLoggedBefore) {
      toggleAuthModal({ show: true, useCase: "login" });
      return;
    }
    toggleAuthModal({ show: true, useCase: "registration" });
  }
};
</script>
<template>
  <div class="freespin-bonus">
    <h3 class="freespin-bonus__title text-uppercase">
      {{ getFreeSpinsTitle }}
    </h3>
    <div class="d-flex align-items-center justify-content-between">
      <p class="freespin-bonus__amount m-0">{{ getAmount }}</p>
      <!-- <p class="freespin-bonus__amount m-0">{{ getAmountLeft }}</p> -->
    </div>
    <ul class="freespin-bonus__games d-flex p-0">
      <li
        class="freespin-bonus__game"
        v-for="(game, index) in getGames"
        :key="index"
        @click="openGame(game.GameID)"
      >
        <a role="button" class="d-block h-100 w-100">
          <img :src="game.LogoUrl" :alt="`${game.Name} logo`" class="w-100" />
          <p class="text-center m-0">{{ game.Name }}</p></a
        >
      </li>
    </ul>
    <div class="freespin-bonus__date d-flex justify-content-between">
      <p class="m-0">{{ getGivenDate }}</p>
      <p class="m-0">{{ getExpiryDate }}</p>
    </div>
  </div>
</template>
<style scoped lang="scss">
.freespin-bonus {
  &__header {
    margin-bottom: 3px;
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    @media only screen and (max-width: 745px) {
      font-size: 13px;
    }
  }
  &__amount {
    font-size: 14px;
    @media only screen and (max-width: 745px) {
      font-size: 12px;
    }
  }
  &__date {
    color: #909092;
    font-size: 12px;
    @media only screen and (max-width: 745px) {
      font-size: 12px;
    }
  }
  &__games {
    margin: 30px 0;
    overflow: auto;
    @media only screen and (max-width: 745px) {
      margin: 20px 0;
    }
  }
  &__game {
    min-width: 110px;
    width: 110px;
    list-style: none;
    font-size: 14px;
    margin-right: 20px;
    @media only screen and (max-width: 745px) {
      min-width: 75px;
      width: 75px;
      font-size: 10px;
    }
    &:last-child {
      margin-right: 0;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    img {
      margin-bottom: 3px;
    }
  }
}
</style>
