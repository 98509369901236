<script setup>
import gamesBannerSlider from "./games-banner-slider.vue";
import gamesPrefooter from "./games-prefooter.vue";
import GamesFilter from "./games-filter/games-filter.vue";
import GamesList from "./GamesList.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { computed, watch } from "vue";

// Declarations
const store = useStore();
const route = useRoute();
const router = useRouter();

// Computed
const getGamePage = computed(() => store.getters.gamePage);
const hasGameBanners = computed(
  () => store.getters.gamePage?.data?.attributes?.banners?.data?.length
);
const hasGameCategories = computed(
  () => store.getters.gamePage?.data?.attributes?.game_categories?.data?.length
);

// Methods
const fetchGamePage = async () => {
  store.commit("setIsAppLoading", true);
  try {
    await store.dispatch("getGamePage", route.params.slug);
    // If there is no data(page), redirect to home page
    if (
      !getGamePage.value.data ||
      !Object.keys(getGamePage.value.data).length
    ) {
      router.push({ name: "home" });
    }
  } catch (err) {
    console.error(err);
  }
  store.commit("setIsAppLoading", false);
};

// Watchers
watch(
  () => route.params.slug,
  () => {
    fetchGamePage();
  }
);

// Created
fetchGamePage();
</script>

<template>
  <div v-if="!store.getters.isAppLoading">
    <games-filter />
    <games-banner-slider v-if="hasGameBanners" />
    <games-list v-if="hasGameCategories" />
    <games-prefooter />
  </div>
</template>

<style lang="scss"></style>
