import axiosApi from "../../providers/api";
import qs from "qs";

export default {
  state: {
    kyc: {},
    kycDocuments: {},
    kycIsLoading: false,
  },
  getters: {
    kyc: (state) => state.kyc,
    kycDocuments: (state) => state.kycDocuments,
    kycIsLoading: (state) => state.kyIsLoading,
  },
  mutations: {
    setKyc(state, data) {
      state.kyc = data;
    },
    setKycIsLoading(state, data) {
      state.kyIsLoading = data;
    },
    setKycDocuments(state, data) {
      state.kycDocuments = data;
    },
  },
  actions: {
    async submitFiles(_, { files, key, data, id }) {
      try {
        const formData = new FormData();
        files.forEach((file) => {
          formData.append(`files.${key}`, file, file.name);
        });
        formData.append("data", JSON.stringify(data));
        if (id) {
          await axiosApi.put(`/kyc-verifications/${id}`, formData);
          return;
        }
        await axiosApi.post("/kyc-verifications", formData);
      } catch (err) {
        console.error(err);
      }
    },
    async findKyc({ commit, getters }) {
      try {
        const queryData = qs.stringify({
          populate: "*",
          filters: {
            users_permissions_user: {
              id: {
                $eq: getters.user.id,
              },
            },
          },
        });
        const { data } = await axiosApi.get(`/kyc-verifications?${queryData}`);
        commit("setKyc", data.data[0]);
      } catch (err) {
        console.error(err);
      }
    },
    async getUserKycDocuments({ commit }) {
      try {
        const { data } = await axiosApi.get("/crm/getUserKycDocuments");
        commit("setKycDocuments", data);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
