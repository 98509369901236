<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Pagination, Autoplay } from "swiper";
import "swiper/swiper.scss";
import { handleButtonClick } from "../../helpers/button";

let modules = [Pagination, Autoplay];

let bannersGameData = ref({});

const store = useStore();

let banners = computed(() => {
  let banners = [];
  if (bannersGameData.value.Banner) {
    banners = bannersGameData.value.Banner;
  }
  if (user.value.id) {
    banners = banners.filter((banner) => {
      return banner.show_for == "all" || banner.show_for == "authenticated";
    });
  } else {
    banners = banners.filter((banner) => {
      return banner.show_for == "all" || banner.show_for == "not_authenticated";
    });
  }

  return banners;
});

const user = computed(() => store.getters.user);

const getImageUrl = (banner) =>
  banner.banner_image
    ? banner.banner_image?.data?.attributes?.url || banner.banner_image.url
    : "";

const getImageUrlMobile = (banner) =>
  banner.banner_mobile_image
    ? banner.banner_mobile_image?.data?.attributes?.url ||
      banner.banner_mobile_image.url
    : "";

const getAlignment = (banner) => banner.text_alignment || "";

const fetchBanners = async () => {
  try {
    const { data } = await store.dispatch("fetchHomepageBanners");
    if (data.data.length) {
      bannersGameData.value = {
        id: data.data[0].id,
        ...data.data[0].attributes,
      };
    }
  } catch (err) {
    console.error(err);
  }
};

onMounted(() => {
  fetchBanners();
});
</script>

<template>
  <div class="home__banners" v-if="banners.length">
    <Swiper
      :modules="modules"
      :pagination="{ clickable: true }"
      :speed="800"
      :loop="true"
      :autoplay="{ delay: 5000 }"
      :slides-per-view="1"
    >
      <SwiperSlide v-for="(b, i) in banners" :key="i">
        <div class="home__banner" :style="{ textAlign: getAlignment(b) }">
          <div
            class="bg d-none d-sm-block"
            :style="{
              backgroundImage: 'url(' + getImageUrl(b) + ')',
            }"
          ></div>
          <div
            class="bg d-sm-none"
            :style="{
              backgroundImage: 'url(' + getImageUrlMobile(b) + ')',
            }"
          ></div>
          <div class="container">
            <div
              class="row align-items-center"
              :class="{
                'justify-content-center': getAlignment(b) == 'center',
                'justify-content-end': getAlignment(b) == 'right',
              }"
            >
              <div class="col-12 col-lg-6">
                <h1>
                  {{ b.heading_1 }}
                  <strong class="d-block"> {{ b.heading_2 }} </strong>
                </h1>
                <p>
                  {{ b?.banner_text }}
                </p>
                <div class="cta" v-if="b.banner_button">
                  <a
                    role="button"
                    class="btn btn-primary"
                    @click="
                      handleButtonClick(b.banner_button)
                    "
                  >
                    <span>{{ b.banner_button.button_text }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
<style lang="scss">
.home {
  &__banners {
    position: relative;
    .swiper-pagination {
      display: flex !important;
      justify-content: center;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 10;
      transform: translateY(-100%);
      height: 36px;
      align-items: center;

      .swiper-pagination-bullet {
        border: 1px solid var(--app-yellow);
        &.swiper-pagination-bullet-active {
          background: var(--app-yellow);
        }
        width: 16px;
        height: 16px;
        display: block;
        margin: 8px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  &__banner {
    color: #fff;
    position: relative;
    padding-bottom: 36px;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: cover;
      background-position: center top;
    }
    * {
      z-index: 2;
    }
    h1 {
      font-style: normal;
      font-weight: 300;
      font-size: 48px;
      margin-bottom: 35px;
      @media (max-width: 768px) {
        font-size: 28px;
        margin-bottom: 12px;
      }
      strong {
        font-weight: 800;
        color: var(--app-yellow);
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 50px;
    }
    .row {
      min-height: 400px;
      max-height: 40vh;
      @media (max-width: 768px) {
        min-height: 300px;
      }
    }
  }
}
</style>
