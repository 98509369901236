import authProvider from "@/providers/auth";

export default {
  state: {
    jwt: null,
    registrationData: {
      step_one: {},
    },
    providerData: {},
  },
  mutations: {
    setJwt(state, jwt) {
      state.jwt = jwt;
      localStorage.setItem("luck-royal-jwt", jwt);
    },
    setRegStepOneData(state, data) {
      state.registrationData.step_one = data;
    },
    setProviderData(state, data) {
      state.providerData = data;
    },
  },
  actions: {
    async loginWithEmailAndPassword(context, payload) {
      let result = await authProvider.loginWithEmailAndPassword(
        payload.email,
        payload.password,
        payload.ip_address
      );
      if (result.balance) {
        context.commit("setBalance", result);
      }
      if (result.jwt) {
        context.commit("setJwt", result.jwt);
      }

      if (result.user) {
        context.commit("setUser", result.user);
      }

      return result;
    },
    async createUserWithFacebook(context, payload) {
      let user = payload;
      let result = await authProvider.createUserWithFacebook(user);
      if (result.jwt) {
        console.log("jwt to store");
        context.commit("setJwt", result.jwt);
      }
      if (result.user) {
        context.commit("setUser", result.user);
      }
      return result;
    },
    async createUserWithEmailAndPassword(context, payload) {
      let user = payload;
      let result = await authProvider.createUserWithEmailAndPassword(user);
      if (result.jwt) {
        context.commit("setJwt", result.jwt);
        localStorage.setItem("luck-royal-jwt", result.jwt);
      }
      if (result.user) {
        console.log("user to store");
        context.commit("setUser", result.user);
      }
      return result;
    },
    async logOut(context, sessionKey) {
      let result = await authProvider.crmLogout(sessionKey);
      console.log(result);
      context.commit("setUser", {});
      context.commit("setJwt", null);
      localStorage.removeItem("luck-royal-jwt");
      window.location.replace("/");
    },
    setJwt(context, payload) {
      context.commit("setJwt", payload);
    },
    setRegStepOneData(context, payload) {
      context.commit("setRegStepOneData", payload);
    },
    setProviderData(context, payload) {
      context.commit("setProviderData", payload);
    },
  },
};
