<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { marked } from "marked";
import { isLogged } from "../../composables/auth";
import { toggleAuthModal } from "../../helpers/modals";
import { useStore } from "vuex";

// Declarations
const store = useStore();

// Emits
const emits = defineEmits(["shuffle", "openGame", "close"]);

// Props
const props = defineProps({
  chosenGame: {
    required: true,
    type: Object,
    default: () => ({}),
  },
});

// Computed
const getBackgroundStyle = computed(() =>
  props.chosenGame?.thumbnail?.url
    ? { backgroundImage: `url(${props.chosenGame.thumbnail.url})` }
    : null
);
const getDescription = computed(() =>
  marked(props.chosenGame?.description || "")
);

// Methods
const openGame = () => {
  if (isLogged.value) {
    if (window.innerWidth < 768) {
      window.scrollTo(0, window.scrollY + 1);
    }
    let event = new CustomEvent("openGame", {
      detail: {
        gameID: props.chosenGame.game_id,
      },
    });
    window.dispatchEvent(event);
    emits("openGame");
  } else {
    if (store.getters.hasBeenLoggedBefore) {
      toggleAuthModal({ show: true, useCase: "login" });
      return;
    }
    toggleAuthModal({ show: true, useCase: "registration" });
  }
};
</script>

<template>
  <div
    class="random-chosen-wrapper position-absolute"
    :class="{
      'random-chosen-wrapper--show':
        chosenGame && !!Object.keys(chosenGame).length,
    }"
  >
    <div class="random-chosen w-100 h-100" :style="getBackgroundStyle">
      <div
        class="random-chosen__content position-relative d-flex flex-column justify-content-end align-items-start w-100 h-100"
      >
        <a
          role="button"
          class="random-chosen__close position-absolute"
          @click="$emit('close')"
        >
          <icon variant="close" />
        </a>
        <h3 class="random-chosen__title m-0">{{ chosenGame.Name }}</h3>
        <div class="random-chosen__description" v-html="getDescription"></div>
        <div
          class="random-chosen__buttons d-flex w-100 justify-content-between align-items-center"
        >
          <a
            class="random-chosen__button btn btn-primary"
            role="button"
            @click="openGame"
            >{{ $t("pages.homepage.random_game.button_try_this_one") }}</a
          >
          <p class="m-0">{{ $t("pages.homepage.random_game.label_or") }}</p>
          <a class="text-underline" role="button" @click="$emit('shuffle')">{{
            $t("pages.homepage.random_game.button_shuffle_again")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.random-chosen-wrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: opacity 0.3s ease-out, transform 0.3s ease-in;
  width: 450px;
  height: 450px;
  border-radius: 20px;
  pointer-events: none;
  &--show {
    opacity: 1;
    z-index: 3;
    transform: translate(-50%, -50%) scale(1);
    pointer-events: all;
  }
  @include desktop {
    width: 400px;
    height: 400px;
  }
  @include tablet {
    width: 230px;
    height: 230px;
    border-radius: 10px;
  }
  @include mobile {
    position: fixed !important;
    width: 75%;
    &::after {
      content: "";
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 50%;
      top: 50%;
      background: rgba($color: #000000, $alpha: 0.5);
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
  .random-chosen {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: inherit;
    &__content {
      border-radius: inherit;
      z-index: 1;
      padding: 0 50px 30px 30px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      @include tablet {
        padding: 0 15px 10px;
      }
    }
    &__close {
      height: 20px;
      width: 20px;
      right: 20px;
      top: 20px;
      @include tablet {
        right: 10px;
        top: 10px;
      }
      .svg-icon {
        fill: #fff;
      }
    }
    &__title {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
      @include tablet {
        font-size: 14px;
      }
    }
    &__description {
      font-size: 14px;
      color: #fff;
      margin: 20px 0 30px;
      p {
        margin: 0;
      }
      @include desktop {
        margin: 15px 0 25px;
      }
      @include tablet {
        font-size: 10px;
        margin: 15px 0 5px;
      }
    }
    &__buttons {
      a,
      p {
        color: #fff;
        @include tablet {
          font-size: 10px;
        }
      }
    }
    a.random-chosen__button {
      color: #333;
      @include tablet {
        height: 30px;
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
}
</style>
