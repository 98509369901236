<script setup>
import { computed, defineProps, defineEmits } from "vue";

// Declarations
const imageTypes = ["image/svg+xml", "image/jpeg", "image/png", "image/gif"];

// Emits
defineEmits(["remove"]);

// Props
const props = defineProps({
  file: {
    required: true,
    type: Object,
    default: () => ({}),
  },
  uploaded: {
    required: false,
    type: Boolean,
    default: false,
  },
});

// Computed
const getImage = computed(() =>
  !props.uploaded ? URL.createObjectURL(props.file) : props.file
);
const isImage = computed(() =>
  imageTypes.includes(props.file.type || props.file.mime)
);
</script>
<template>
  <li class="kyc-file d-flex justify-content-center position-relative">
    <a
      :href="uploaded && file.url ? file.url : null"
      :download="uploaded && file.url ? true : null"
    >
      <a
        v-if="!uploaded"
        class="kyc-file__remove position-absolute"
        role="button"
        @click="$emit('remove')"
      >
        <icon variant="close" fill="#fff" />
      </a>
      <div class="d-flex flex-column align-items-center">
        <img
          class="kyc-file__image"
          v-if="isImage && !uploaded"
          :src="getImage"
          alt="verification image"
        />
        <img
          class="kyc-file__image"
          v-if="uploaded && file.url && isImage"
          :src="file.url"
          alt="verification image"
        />
        <icon v-if="!isImage" variant="document" />
        <h3 class="kyc-file__name text-center">{{ file.name }}</h3>
      </div></a
    >
  </li>
</template>
<style scoped lang="scss">
$block: ".kyc-file";

#{$block} {
  list-style: none;
  padding: 10px 5px;
  a {
    text-decoration: none;
  }
  &__image,
  .svg-icon {
    width: 80px;
    height: 90px;
  }
  &__image {
    object-fit: cover;
    margin-bottom: 5px;
  }
  &__name {
    font-size: 10px;
    width: 100px;
    color: #fff;
  }
  &__remove {
    right: -3px;
    top: -5px;
    .svg-icon {
      width: 13px;
      height: 13px;
    }
  }
}
</style>
