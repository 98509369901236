<script setup>
import { ref, inject, computed, watch } from "vue";
import { useRoute } from "vue-router";
import qs from "qs";
import { marked } from "marked";

const route = useRoute();

let isLoading = ref(false);
let page = ref({});

let markdownText = computed(() => {
  let text = "";
  if (p.value.text) {
    text = marked(p.value.text);
  }
  return text;
});

let p = computed(() => {
  return page.value?.attributes || {};
});

const api = inject("api");
let fetchPage = async () => {
  isLoading.value = true;
  let slug = route.params.slug;
  let query = qs.stringify(
    {
      filters: {
        slug: {
          $eq: slug,
        },
      },
      populate: "*",
    },
    {
      encodeValuesOnly: true,
    }
  );
  let result = await api
    .get(`/pages/?${query}`)
    .then((response) => {
      let page = {};
      page = response.data.data[0];
      return page;
    })
    .catch((error) => {
      return error;
    });
  page.value = result;
  isLoading.value = false;
};

watch(
  () => route.params.slug,
  () => {
    fetchPage();
  },
  { immediate: true }
);
</script>
<template>
  <div class="page">
    <div class="bg"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <div class="content">
            <h1>
              {{ p.title }}
            </h1>
            <h4>
              {{ p.excerpt }}
            </h4>
            <div v-html="markdownText"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.page {
  position: relative;
  .content {
    background: #fff;
    padding-bottom: 80px;
    margin-bottom: 60px;
    padding: 40px 100px 300px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    margin-top: 100px;
    @media (max-width: 991px) {
      padding: 40px 60px 300px;
    }
    @media (max-width: 475px) {
      padding: 40px 20px 160px;
    }

    .cta {
      a {
        font-size: 24px;
        height: 48px;
        &:hover {
          background: darken(#f1ca4b, 10%) !important;
        }
      }
    }
    h1 {
      font-weight: bold;
      font-size: 48px;
      margin-bottom: 30px;
      line-height: 1.2;
      @media (max-width: 475px) {
        font-size: 42px;
      }
    }
    h4 {
      font-weight: bold;
      font-size: 28px;
      @media (max-width: 475px) {
        font-size: 22px;
      }
    }
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(17, 43, 60, 0.85);
      backdrop-filter: blur(20px);
    }
  }
}
</style>
