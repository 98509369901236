<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Pagination, Autoplay } from "swiper";
import { handleButtonClick } from "../../helpers/button";

import "swiper/swiper.scss";
let modules = [Pagination, Autoplay];

const store = useStore();

const bannersData = computed(
  () =>
    store.getters.gamePage?.data?.attributes?.banners?.data
      .map((carousel) => carousel.attributes.Banner)
      .flat() || []
);

let banners = computed(() => {
  let banners = [];
  if (store.state.user.user.id) {
    banners = bannersData.value.filter((banner) => {
      return banner.show_for == "all" || banner.show_for == "authenticated";
    });
  } else {
    banners = bannersData.value.filter((banner) => {
      return banner.show_for == "all" || banner.show_for == "not_authenticated";
    });
  }

  return banners;
});

const getImageUrl = (banner) => {
  let url = "";
  if (banner.banner_image) {
    if (banner.banner_image.data) {
      url = banner.banner_image.data.attributes.url;
    } else {
      url = banner.banner_image.url;
    }
  }

  return url;
};
const getImageUrlMobile = (banner) => {
  let url = "";
  if (banner.banner_mobile_image) {
    if (banner.banner_mobile_image.data) {
      url = banner.banner_mobile_image.data.attributes.url;
    } else {
      url = banner.banner_mobile_image.url;
    }
  }
  return url;
};
const getAlignment = (banner) => {
  let alignment = "";
  if (banner.text_alignment) {
    alignment = banner.text_alignment;
  }
  return alignment;
};
</script>

<template>
  <div class="games__banners">
    <Swiper
      :modules="modules"
      :pagination="{ clickable: true }"
      :speed="800"
      :loop="true"
      :autoplay="{ delay: 5000 }"
      :slides-per-view="1"
    >
      <SwiperSlide v-for="(b, i) in banners" :key="i">
        <div class="games__banner" :style="{ textAlign: getAlignment(b) }">
          <div
            class="bg d-none d-sm-block"
            :style="{
              backgroundImage: 'url(' + getImageUrl(b) + ')',
            }"
          ></div>
          <div
            class="bg d-sm-none"
            :style="{
              backgroundImage: 'url(' + getImageUrlMobile(b) + ')',
            }"
          ></div>
          <div class="container">
            <div
              class="row align-items-center"
              :class="{
                'justify-content-center': getAlignment(b) == 'center',
                'justify-content-end': getAlignment(b) == 'right',
              }"
            >
              <div class="col-12 col-lg-6">
                <h1>
                  {{ b.heading_1 }}
                  <strong class="d-block"> {{ b.heading_2 }} </strong>
                </h1>
                <p>
                  {{ b?.banner_text }}
                </p>
                <div class="cta" v-if="b.banner_button">
                  <a
                    role="button"
                    class="btn btn-primary"
                    @click="handleButtonClick(b.banner_button)"
                  >
                    <span>{{ b.banner_button.button_text }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
<style lang="scss">
.games {
  &__banners {
    position: relative;
    height: 548px;
    @media (max-width: 768px) {
      height: 300px;
    }
    .swiper,
    .swiper-wrapper,
    .swiper-slide,
    .games__banner,
    .container,
    .row {
      height: 100%;
    }
    .swiper-pagination {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 10;
      transform: translateY(-100%);
      height: 36px;
      align-items: center;

      .swiper-pagination-bullet {
        border: 1px solid var(--app-yellow);
        &.swiper-pagination-bullet-active {
          background: var(--app-yellow);
        }
        width: 16px;
        height: 16px;
        display: block;
        margin: 8px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  &__banner {
    color: #fff;
    position: relative;
    padding-bottom: 36px;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: cover;
      background-position: center top;
    }
    * {
      z-index: 2;
    }
    h1 {
      font-style: normal;
      font-weight: 300;
      font-size: 48px;
      margin-bottom: 35px;
      @media (max-width: 768px) {
        font-size: 28px;
        margin-bottom: 12px;
      }
      strong {
        font-weight: 800;
        color: var(--app-yellow);
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 50px;
    }
  }
}
</style>
