<script setup>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { createPopper } from "@popperjs/core";
import { marked } from "marked";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import CountryCultures from "../../assets/data/CountryCultures.json";
import AppLink from "../AppLink.vue";

let language = ref(null);

let router = useRouter();
let store = useStore();

let langs = computed(() => store.getters.langs);

const data = computed(() => store.getters.footerData);

const getFooterLinks = computed(() => data.value?.footer_links);

const currentLang = computed(() => store.getters.currentLang);

const languageObj = computed(
  () =>
    langs.value.find((lang) => lang.code === language.value) || {
      name: "English (en)",
      code: "en",
    }
);

let markdownText = computed(() =>
  data.value.footer_text ? marked(data.value.footer_text) : ""
);

const getImageUrl = (data) => data?.image?.url || "";

let setLang = async (newLang) => {
  language.value = newLang.code;

  store.dispatch("setLanguage", language.value);

  window.localStorage.setItem("luckroyal-lang", language.value);

  await router.replace({
    params: {
      lang: language.value,
    },
  });

  window.location.reload();
};

let flagCode = (code) => {
  if (code.includes("-")) {
    return code.split("-")[1].toLowerCase();
  }
  for (let countryKey in CountryCultures) {
    if (countryKey === code) {
      return CountryCultures[countryKey];
    }
  }
};

let withPopper = (dropdownList, component, { width }) => {
  dropdownList.style.width = width;

  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  return () => popper.destroy();
};

onMounted(() => {
  language.value = currentLang.value;
});
</script>
<template>
  <footer class="footer">
    <div class="container">
      <div class="row" v-if="data && data.payment_methods">
        <div class="col-12">
          <div
            class="footer__payment-methods d-flex flex-wrap justify-content-center"
          >
            <a
              :href="p.link"
              target="_blank"
              :title="p.title"
              v-for="p in data.payment_methods"
              :key="p.id"
            >
              <img :src="getImageUrl(p)" alt="" />
            </a>
          </div>
          <div
            class="footer__game-providers d-flex flex-wrap justify-content-center"
            v-if="data && data.game_providers"
          >
            <a
              :href="g.link"
              target="_blank"
              :title="g.title"
              v-for="g in data.game_providers"
              :key="g.id"
            >
              <img :src="getImageUrl(g)" alt="" />
            </a>
          </div>
          <div
            class="footer__generic-icons d-flex flex-wrap justify-content-center"
            v-if="data && data.generic_icons"
          >
            <a
              :href="i.link"
              target="_blank"
              :title="i.title"
              v-for="i in data.generic_icons"
              :key="i.id"
            >
              <img :src="getImageUrl(i)" :alt="i.title" />
            </a>
          </div>
        </div>
      </div>
      <div
        class="row align-items-center justify-content-center"
        v-if="data && data.footer_text"
      >
        <div class="col-12 col-md-8">
          <div class="text-center" v-if="data.footer_text">
            <div class="footer__copyright py-2" v-html="markdownText"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__links">
      <div class="container">
        <div class="col-12">
          <div
            class="d-flex flex-column flex-md-row align-items-center justify-content-center w-100"
          >
            <AppLink
              v-for="link in getFooterLinks"
              :key="link.id"
              :link="link"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="text-center pb-3">
      <div class="py-3 mb-4 mx-auto footer__lang-selector">
        <v-select
          @option:selected="setLang"
          v-model="languageObj"
          :options="langs"
          :title="'name'"
          append-to-body
          :clearable="false"
          :searchable="false"
          :calculate-position="withPopper"
        >
          <template #selected-option="{ code, name }">
            <div
              class="lang-option-selected d-flex justify-content-between w-100"
            >
              <flag class="flag" :iso="flagCode(code)" />
              <span class="ms-3">{{ name }}</span>
            </div>
          </template>
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <icon class="dropdown" :variant="'dropdown'" />
            </span>
          </template>
          <template #option="{ name, code }">
            <div class="lang-option">
              <flag class="flag" :iso="flagCode(code)" />
              <span class="ms-3">{{ name }}</span>
            </div>
          </template>
        </v-select>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
.vs__dropdown-menu {
  border-radius: 12px;
  border: none;
  background: lighten(#0c1d29, 10%);
  * {
    color: rgba(#fff, 0.8);
  }
  .vs__dropdown-option {
    padding: 8px 12px 8px 18px;
    .flag {
      transform: scaleX(1.5);
      border-radius: 2px;
    }
  }
  .vs__dropdown-option--highlight {
    background: lighten(#0c1d29, 20%);
  }
}
.footer {
  background: rgba(#000, 0.3);
  padding-top: 40px;
  &__lang-selector {
    color: #fff;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    .lang-option-selected {
      text-transform: uppercase;
      .flag {
        transform: scaleX(1.5);
        border-radius: 2px;
      }
    }
    .dropdown {
      fill: #fff !important;
      margin-bottom: 4px;
      display: inline-block;
    }

    .vs__selected {
      color: #fff;
    }
    .vs__dropdown-toggle {
      border: 1px solid rgba(#fff, 0.4);
      border-radius: 22px;
      padding: 6px 16px;
    }
  }
  &__links {
    .container {
      margin-top: 40px;
      padding-bottom: 30px;
      &::before {
        width: 800px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        content: "";
        display: block;
        border-top: 1px solid rgba(#fff, 0.2);
        padding-top: 40px;
        text-align: center;
      }
    }
    a {
      display: inline-flex;
      margin: 10px 0px;
      color: rgba(#fff, 0.8);
      font-size: 16px;
      text-decoration: none;
      &:hover {
        color: var(--app-yellow);
      }
      &:not(:last-child) {
        &::after {
          @media (min-width: 768px) {
            content: "|";
            margin: 0 18px;
            color: rgba(#fff, 0.8) !important;
          }
        }
      }
    }
  }
  &__payment-methods,
  &__game-providers,
  &__generic-icons {
    padding: 16px 0px;
    a {
      display: inline-flex;
      margin: 20px;
      filter: grayscale(1);
      opacity: 1;
      transition: 0.3s;
      &:hover {
        filter: grayscale(0);
        opacity: 1;
        transition: 0.3s;
      }
      img {
        height: 36px;
      }
    }
  }
  &__copyright {
    font-weight: 400;
    font-size: 14px;
    color: rgba(#fff, 0.4);
    padding: 28px 0px;
  }
}
</style>
