<script setup>
import { onMounted, ref, computed, watch } from "vue";
import appHeader from "./components/layout/app-header.vue";
import appFooter from "./components/layout/app-footer.vue";
import iconsCollection from "./components/icons/icons-collection.vue";
import authModal from "./components/modals/auth-modal.vue";
import gameSingle from "./components/modals/game-single.vue";
import cashierModal from "./components/modals/cashier-modal.vue";
import bonusModal from "./components/modals/bonus-modal.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import homePreloader from "./views/home/home-preloader.vue";
import { setZohochatDetails } from "./helpers/chat";
import AppUnderMaintenance from "./components/AppUnderMaintenance.vue";

import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

let store = useStore();
let isLoading = ref(false);
let route = useRoute();
const underMaintenanceInterval = 60000;

const isReady = ref(false);
const showPreloader = ref(false);

let isProviderAuth = computed(() => {
  let state = false;
  if (
    route.query.provider &&
    store.state.auth.providerData.jwt &&
    !store.state.auth.jwt
  ) {
    state = true;
  }

  return state;
});
const isBonusDetailsModalVisible = computed(
  () => store.getters.bonusDetailsModal.isVisible
);
const getUser = computed(() => store.getters.user);
const getIsUnderMaintenance = computed(() => store.getters.isUnderMaintenance);

const fetchInitialData = async () => {
  isLoading.value = true;
  await store.dispatch("fetchInitialData");
  isLoading.value = false;

  let path = window.location.pathname;

  if (path.includes("register") && !isLoading.value) {
    showAuthModal("registration");
  }
  if (path.includes("login") && !isLoading.value) {
    showAuthModal("login");
  }
};

const showAuthModal = (useCase) => {
  let event = new CustomEvent("toggleAuthModal", {
    detail: {
      show: true,
      useCase,
    },
  });
  window.dispatchEvent(event);
};

const setSmarticoDetails = (userId, language) => {
  window._smartico_user_id = userId;
  window._smartico_language = language;
};

// Watchers
watch(
  getUser,
  (user) => {
    if (user.crm_client_id) {
      setZohochatDetails();
      setSmarticoDetails(user.crm_client_id, store.getters.currentLang);
      return;
    }
    setSmarticoDetails(null, null);
  },
  { immediate: true, deep: true }
);

onMounted(async () => {
  await fetchInitialData();

  setTimeout(() => {
    if (isProviderAuth.value) {
      showAuthModal("registration");
    }

    // Btag cookie
    if (route.query.btag) {
      let tag = route.query.btag;
      document.cookie = `btag=${tag};max-age=259200`;
    }
  }, 1200);
});

// Watchers
watch(
  () => store.getters.isAppLoading,
  (isLoading) => {
    if (isLoading) {
      showPreloader.value = true;
      isReady.value = false;
    }

    if (!isLoading) {
      setTimeout(() => {
        showPreloader.value = false;
      }, 500);
      isReady.value = true;
    }
  }
);

// Created
store.dispatch("fetchUnderMaintenance");
setInterval(() => {
  store.dispatch("fetchUnderMaintenance");
}, underMaintenanceInterval);
</script>

<template>
  <AppUnderMaintenance v-if="getIsUnderMaintenance" />
  <template v-else>
    <app-header />
    <div class="wrapper">
      <router-view />
      <app-footer />
      <homePreloader v-if="showPreloader" :is-ready="isReady" />
    </div>
    <iconsCollection />
    <authModal />
    <gameSingle />
    <cashierModal />
    <bonusModal v-if="isBonusDetailsModalVisible" />
  </template>
</template>

<style lang="scss">
.wrapper {
  padding-top: 100px;
  @media (max-width: 1024px) {
    padding-top: 60px;
  }
}
</style>
