<script setup>
import { computed } from "vue";
import store from "../../store";
import { useRouter } from "vue-router";
import { isLogged } from "../../composables/auth";
import { toggleAuthModal } from "../../helpers/modals";

// Declarations
const router = useRouter();

// Computed
const dealersData = computed(
  () => store.getters.homepageData?.liveDealers || []
);

// Methods
const thumbnail = (obj) => obj?.dealer_photo?.url;
const handleClickAction = (dealer) => {
  if (dealer.action === "open-game" && dealer.game) {
    if (isLogged.value) {
      window.dispatchEvent(
        new CustomEvent("openGame", {
          detail: {
            gameID: dealer.game.game_id,
          },
        })
      );
    } else {
      if (store.getters.hasBeenLoggedBefore) {
        toggleAuthModal({ show: true, useCase: "login" });
        return;
      }
      toggleAuthModal({ show: true, useCase: "registration" });
    }
  } else if (
    dealer.action === "redirect" &&
    dealer.redirect_url &&
    router.resolve(dealer.redirect_url).name
  ) {
    router.push(dealer.redirect_url);
  }
};
</script>
<template>
  <!-- <pre style="color: #f00;">{{dealersData}}</pre> -->
  <div class="dealers">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="dealers__title mb-4">
            {{ $t("pages.homepage.title_live_dealers") }}
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="d in dealersData" :key="d.id" class="col-6 col-lg-2">
          <div class="dealers__item">
            <div
              class="dealers__img mb-4"
              :style="{
                backgroundImage: 'url(' + thumbnail(d) + ')',
              }"
              @click="handleClickAction(d)"
            ></div>
            <div class="dealers__name">{{ d.dealer_title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dealers {
  padding: 30px 0px;
  width: 100%;
  height: 100%;
  background: #000000;
  position: relative;
  z-index: 10;

  &__title {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 800;
    line-height: 48px;
    letter-spacing: 0.13em;
    text-align: center;
    color: #ffffff;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__img {
    position: relative;
    z-index: 100;
    left: 0.5%;
    width: 132px;
    height: 132px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: 6px solid;
    box-shadow: 0px 0px 0px 4px var(--app-yellow);
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
    @media (max-width: 640px) {
      width: 100px;
      height: 100px;
    }
  }
  &__fone {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 336px !important;
    min-height: 190px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media (max-width: 640px) {
      min-height: 108px;
      min-width: 180px !important;
    }
  }

  &__name {
    position: relative;
    top: -12px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;

    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
  }
  &__footer-line {
    margin-right: 4px;
    @media (max-width: 768px) {
      display: none;
    }
  }
}
</style>
