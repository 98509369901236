<script setup>
import { ref, onMounted } from "vue";
// import { useStore } from "vuex";
// import { useRouter } from "vue-router";

// const store = useStore();
// const router = useRouter();

let allJackpots = ref(0);
let firstLoadJackPots = ref(false);

// const jackpots = computed(() => store.getters.homepageData?.jackpots || []);

// const thumbnail = (obj) => obj?.jackpot_image?.url;

function animateValue(start, end, duration) {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    allJackpots.value = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

const getRandomJackPots = () => {
  setInterval(() => {
    if (firstLoadJackPots.value) {
      let randomAmount = Math.floor(Math.random() * 100) + 20;
      let nextValue = allJackpots.value + randomAmount;
      animateValue(allJackpots.value, nextValue, 2000);
    } else {
      let nextValue = Math.floor(Math.random() * 10000000) + 700000;
      animateValue(allJackpots.value, nextValue, 2000);
      firstLoadJackPots.value = true;
    }
  }, 3500);
};
// const openGame = (game) => {
//   let gameID = null;
//   if (game && game) {
//     gameID = game.game_id;
//   }
//   if (store.state.user.user.id) {
//     if (window.innerWidth > 768) {
//       // open game for desktop
//       let event = new CustomEvent("openGame", {
//         detail: {
//           gameID: gameID,
//         },
//       });
//       window.dispatchEvent(event);
//     } else {
//       let routeData = router.resolve({
//         name: "game-single",
//         params: { id: gameID },
//       });
//       window.open(routeData.href, "_blank");
//     }
//   } else {
// if (store.getters.hasBeenLoggedBefore) {
//   toggleAuthModal({ show: true, useCase: "login" });
//   return;
// }
// toggleAuthModal({ show: true, useCase: "registration" });
//   }
// };

onMounted(() => {
  getRandomJackPots();
});
</script>
<template>
  <section class="jackpot">
    <div class="p-0">
      <div class="row m-0">
        <!-- <div class="col-12">
          <div class="jackpot__title">
            {{ $t("jackpots_title") }}
          </div>
        </div> -->
        <div class="col-12">
          <div class="jackpot__item text-center">
            <div class="jackpot__text total_jackpot_title mb-4">
              <span>{{ $t("pages.homepage.title_royal_jackpot") }}</span>
            </div>
            <div
              class="jackpot__img-fone d-flex justify-content-center align-items-center"
            >
              <div
                v-if="allJackpots > 0"
                class="jackpot__img-wrapper d-flex justify-content-center align-items-center"
              >
                ${{ allJackpots.toLocaleString("en-US") }}
              </div>
            </div>
          </div>
        </div>

        <!-- <div>
          <div class="jackpot__text text-center total_jackpot_title mt-5 mb-4">
            <span
              ><span>{{ $t("pages.homepage.title_jackpots") }}</span></span
            >
          </div>
        </div>
        <div
          class="col-6 col-lg-3"
          @click="openGame(j?.game)"
          v-for="j in jackpots"
          :key="j.id"
        >
          <div class="jackpot__item text-center">
            <div
              class="jackpot__img"
              :style="{
                backgroundImage: 'url(' + thumbnail(j) + ')',
              }"
            ></div>
            <div class="jackpot__text mt-3">
              ${{ j?.jackpot_amount.toLocaleString("en-US") }}
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.total_jackpot_title {
  position: relative;
  span {
    font-size: 30px;
    background-color: #102b3c;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 100;
    span {
      background: -webkit-linear-gradient(
        rgba(250, 241, 168, 1),
        rgba(144, 108, 57, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    z-index: -1;
    background: -webkit-linear-gradient(
      rgba(250, 241, 168, 1),
      rgba(144, 108, 57, 1)
    );
    top: 50%;
    transform: translateY(-50%);
  }
}
.jackpot {
  margin: 40px 0px;

  &__title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    color: #ffffff;
  }

  // .jackpot__item

  &__item {
    margin: 10px 0px;
    cursor: pointer;
  }

  // .jackpot__img

  &__img {
    border: 2px solid #e2c777;
    box-shadow: 0px 15px 16px -5px rgba(0, 0, 0, 0.37);
    border-radius: 10px;
    width: 100%;

    height: 158px;
    @media (max-width: 991px) {
      aspect-ratio: 2/1;
    }
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &-wrapper {
      /* background: linear-gradient(
        0deg,
        rgba(26, 22, 22, 0.63),
        rgba(26, 22, 22, 0.63)
      ); */
      background: -webkit-linear-gradient(
        rgba(250, 241, 168, 1),
        rgba(144, 108, 57, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      min-width: 100%;
      min-height: 100%;
      border-radius: 10px;
    }
  }
  &__img-fone {
    border: 2px solid #e2c777;
    background-color: #2a0303;
    box-shadow: 0px 15px 16px -5px rgba(0, 0, 0, 0.37);
    border-radius: 10px;
    width: 100%;
    height: 158px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-family: Montserrat;
    font-size: 58px;
    font-weight: 800;
    line-height: 78px;
    color: white;
    @media screen and (max-width: 1250px) {
      font-size: 48px;
    }
    @media screen and (max-width: 1110px) {
      font-size: 42px;
    }
  }

  // .jackpot__text

  &__text {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    color: #e2c777;
  }
}
</style>
