<script setup>
import { ref, inject, computed, defineProps } from "vue";
import qs from "qs";
import gameTile from "@/components/game-tile.vue";
import _ from "lodash";
import homeSlidingSectionItem from "./home-sliding-section-item.vue";
import homeTopGamesSection from "./home-topgames-section.vue";
import { useStore } from "vuex";

const store = useStore();
const api = inject("api");

const props = defineProps({
  categories: {
    type: Array,
    default: () => {
      return [];
    },
  },
});

let isLoading = ref(false);

let orderedCategories = computed(() =>
  _.orderBy(props.categories, (item) => item.order, ["asc"])
);

const hasTopGames = computed(
  () => store.getters.homepageData?.homepageSettings?.top_games?.length
);

//search
let q = ref("");
let searcheResults = ref([]);
let loadingFinished = ref(false);
let games = ref({});

let clearSearch = async () => {
  q.value = "";
  searcheResults.value = [];
};

let delayTimer;
let doSearch = async () => {
  clearTimeout(delayTimer);
  delayTimer = setTimeout(() => {
    fetchSearchResults();
  }, 1000);
};

let fetchSearchResults = async () => {
  loadingFinished.value = false;
  games.value = [];
  let query = q.value;
  let gamesQuery = {
    pagination: {
      pageSize: 21,
    },
    filters: {
      Name: {
        $containsi: query,
      },
    },
    populate: {
      thumbnail: "*",
    },
  };
  gamesQuery = qs.stringify(gamesQuery, {
    encodeValuesOnly: true, // prettify URL
  });

  isLoading.value = true;
  await api
    .get("/games?" + gamesQuery)
    .then((response) => {
      searcheResults.value = response.data.data;
      isLoading.value = false;
      loadingFinished.value = true;
    })
    .catch((error) => {
      console.log(error);
      isLoading.value = false;
      loadingFinished.value = true;
    });
};
</script>

<template>
  <div class="home">
    <div class="bg"></div>
    <div class="container">
      <div class="row" style="display: none">
        <div class="col-12 search-column d-flex justify-content-end">
          <div class="home__tabs-search col-12 col-md-3 text-end py-3">
            <icon variant="search" class="search" />
            <input
              @keyup="doSearch"
              class="w-100"
              type="text"
              :placeholder="$t('label_search')"
              v-model="q"
            />
            <icon
              variant="close"
              class="clear"
              @click="clearSearch"
              v-if="q.length"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mb-4">
        <div class="col-12">
          <div class="home__tabs-content">
            <div class="home__tabs-pane">
              <template v-if="q.length">
                <h1 class="search__tabs-title mt-1">
                  {{ $t("label_search_results_for") }} <strong>{{ q }}</strong>
                </h1>
                <div class="page search" v-if="q.length">
                  <gameTile
                    class="item"
                    :game="r"
                    v-for="(r, y) in searcheResults"
                    :key="y"
                  />
                </div>
              </template>
              <div
                class="no-results"
                v-if="
                  loadingFinished &&
                  !games[1]?.length &&
                  !searcheResults.length &&
                  q.length
                "
              >
                No games found
              </div>
              <div class="page search" v-if="isLoading && q.length">
                <div class="item" v-for="n in 12" :key="n"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-show="!q.length">
      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-column sliding-sections-holder">
            <homeTopGamesSection v-if="hasTopGames" />
            <home-sliding-section-item
              v-for="c in orderedCategories"
              :key="c.id"
              :category="c"
              :style="{ order: c.order }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container-fluid {
  max-width: 1900px;
  position: relative;
  z-index: 3;
}
.no-results {
  color: rgba(#fff, 1);
  font-size: 24px;
  text-align: center;
  padding: 80px 0px 100px;
  font-weight: 300;
}
.search {
  &__tabs-title {
    color: var(--app-yellow);
  }
}
.home {
  position: relative;
  padding-bottom: 25px;
  .page {
    padding: 30px 0px;
    display: grid;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
    @media (max-width: 1024px) {
      padding: 16px 0px;
      grid-template-columns: repeat(3, minmax(100px, 1fr));
      gap: 12px;
    }
    &.search {
      .item {
        opacity: 1;
        background: #183a50;
        aspect-ratio: 1/1.21;
        border-radius: 10px;

        animation: animationGradient 6s linear infinite;
        background: linear-gradient(-90deg, #183a50, #164462, #183a50);
        background-size: 400% 400%;
      }
    }
    gap: 25px;
  }
  &__tabs-search {
    position: relative;
    &:focus-within {
      svg.search {
        fill: var(--app-yellow);
      }
      input {
        border: 2px solid var(--app-yellow);
        color: var(--app-yellow);
      }
    }
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.search {
        fill: #445f70;
        width: 24px !important;
        height: 24px !important;
        left: 16px;
      }
      &.clear {
        fill: var(--app-yellow);
        width: 24px !important;
        height: 24px !important;
        right: 16px;
        cursor: pointer;
      }
    }

    input {
      width: 353px;
      height: 59px;
      background: #183a50;
      box-shadow: 0px 20px 30px -8px rgba(0, 0, 0, 0.21);
      border-radius: 10px;
      border: none;
      padding-left: 48px;
      outline: none !important;
      color: #f2f2f2;
    }
    ::placeholder {
      color: #445f70;
    }
  }
  & > .container {
    position: relative;
    z-index: 3;
  }
  .bg {
    background-size: cover;
    background-position: center top;
    opacity: 0.9;
    background-color: #2a0303;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}
</style>
