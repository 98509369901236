<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useStore } from "vuex";

// Declarations
const store = useStore();

// Props
const props = defineProps({
  category: {
    required: true,
    type: Object,
    default: () => ({}),
  },
  selectedCategoryId: {
    required: true,
    type: [Number, String],
    default: 0,
  },
});

// Emits
defineEmits({
  setCategory: (categoryId) => {
    return categoryId;
  },
});

// Computed
const isSelected = computed(
  () => props.selectedCategoryId === props.category.id
);
const isFavourite = computed(() => props.category.id === "favourites");
const getCategoryTitle = computed(
  () =>
    props.category.attributes.localized_name?.find(
      (name) =>
        name.key?.split("_")[1]?.toLowerCase() ===
        store.getters.currentLang?.toLowerCase()
    )?.value || props.category.attributes.name
);
</script>
<template>
  <li
    role="button"
    class="game-category-tab d-flex align-items-center"
    :class="{ 'game-category-tab--active': isSelected }"
    @click="$emit('setCategory', category.id)"
  >
    <span>{{ getCategoryTitle }}</span>
    <icon
      v-if="isFavourite"
      class="ms-2"
      :variant="'star-filled'"
      fill="#fff"
    />
  </li>
</template>
<style scoped lang="scss">
.game-category-tab,
.svg-icon {
  transition: all 0.1s ease 0s;
}
.game-category-tab {
  display: block;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  padding: 21px 18px;
  text-decoration: none;
  border-bottom: 2px solid rgb(84, 103, 115);
  &:hover {
    color: var(--app-yellow-dark);
    transition: 0.3s;
    .svg-icon {
      fill: var(--app-yellow-dark);
    }
  }
  &--active,
  &--active:hover {
    color: var(--app-yellow);
    border-color: var(--app-yellow);
    transition: all 0.1s ease 0s;
    .svg-icon {
      fill: var(--app-yellow);
    }
  }
}
</style>
