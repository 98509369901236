<script setup>
import { onMounted, ref, inject, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Datepicker from "@vuepic/vue-datepicker";
import loadingSpinenrBig from "@/components/loading-spinner-big.vue";
import { toggleAuthModal } from "../../helpers/modals";

let isLoading = ref(false);
let dataLoaded = ref(false);
let history = ref([]);
let historyTotalCount = ref(0);
let games = ref([]);
let quickDates = ref(null);
let disableShowMore = ref(false);
let filters = ref({
  dateFrom: null,
  dateTo: null,
  pageNo: 1,
  perPage: 20,
});

const store = useStore();
const router = useRouter();
let user = computed(() => store.state.user.user);
let items = computed(() => {
  return history.value;
});

watch(
  () => quickDates.value,
  (val) => {
    if (val == "today") {
      let todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      let todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);
      filters.value.dateFrom = todayStart;
      filters.value.dateTo = todayEnd;
    }
    if (val == "yesterday") {
      let yesterdayStart = new Date();
      yesterdayStart.setDate(yesterdayStart.getDate() - 1);
      yesterdayStart.setHours(0, 0, 0, 0);
      let yesterdayEnd = new Date();
      yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);
      yesterdayEnd.setHours(23, 59, 59, 999);
      filters.value.dateFrom = yesterdayStart;
      filters.value.dateTo = yesterdayEnd;
    }
    if (val == "7") {
      let todayStart = new Date();
      todayStart.setDate(todayStart.getDate() - 7);
      todayStart.setHours(0, 0, 0, 0);
      let todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);
      filters.value.dateFrom = todayStart;
      filters.value.dateTo = todayEnd;
    }
    if (val == "30") {
      let todayStart = new Date();
      todayStart.setDate(todayStart.getDate() - 30);
      todayStart.setHours(0, 0, 0, 0);
      let todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);
      filters.value.dateFrom = todayStart;
      filters.value.dateTo = todayEnd;
    }
  },
  { deep: true }
);

const getGameName = (gameId) => {
  let name = gameId;
  let game = games.value.find((g) => g.game_id === gameId);
  if (game) {
    name = game.Name;
  }
  return name;
};

const getBalance = (real, bonus) => {
  let balance = 0;
  if (real) {
    balance = Number(real);
  }
  if (bonus) {
    balance += Number(bonus);
  }
  return parseFloat(balance).toFixed(2);
};

const openGame = (gameID) => {
  if (store.state.user.user.id) {
    if (window.innerWidth > 768) {
      // open game for desktop
      let event = new CustomEvent("openGame", {
        detail: {
          gameID: gameID,
        },
      });
      window.dispatchEvent(event);
    } else {
      let routeData = router.resolve({
        name: "game-single",
        params: { id: gameID },
      });
      window.open(routeData.href, "_blank");
    }
  } else {
    if (store.getters.hasBeenLoggedBefore) {
      toggleAuthModal({ show: true, useCase: "login" });
      return;
    }
    toggleAuthModal({ show: true, useCase: "registration" });
  }
};
const formatDate = (timestamp) => {
  let date = new Date(timestamp);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = "0" + date.getMinutes();
  let seconds = "0" + date.getSeconds();
  let formattedTime =
    day +
    "/" +
    month +
    "/" +
    year +
    " " +
    hours +
    ":" +
    minutes.substr(-2) +
    ":" +
    seconds.substr(-2);
  return formattedTime;
};
const getGameThumb = (gameId) => {
  let thumb = "";
  let game = games.value.find((g) => g.game_id === gameId);
  if (game) {
    thumb = game?.thumbnail?.url ?? null;
  }
  return thumb;
};

const api = inject("api");
const getUserGameHistory = async (loadmore) => {
  if (loadmore == false) {
    history.value = [];
    historyTotalCount.value = 0;
    filters.value.pageNo = 1;
    disableShowMore.value = false;
  }
  isLoading.value = true;
  await api
    .post("/crm/gamesHistory", {
      sessionKey: user.value.crm_session_key,
      ...filters.value,
    })
    .then((r) => {
      if (r.data.games) {
        r.data.games.forEach((g) => {
          games.value.push(g);
        });
      }
      if (r.data.history.items) {
        for (let i = 0; i < r.data.history.items.length; i++) {
          history.value.push(r.data.history.items[i]);
        }
      }

      historyTotalCount.value = Number(r.data.history.totalCount);
      if (isNaN(historyTotalCount.value)) {
        disableShowMore.value = true;
      }
      dataLoaded.value = true;
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const loadMore = () => {
  filters.value.pageNo++;
  getUserGameHistory(true);
};

onMounted(() => {
  quickDates.value = "today";
  setTimeout(() => {
    getUserGameHistory(false);
  }, 200);
});
</script>

<template>
  <div class="game-history">
    <div class="vue_table-container">
      <div class="vue_table-section">
        <div class="filter__header row">
          <div class="col-12 col-md-3 mb-2">
            <div class="filter__time-select">
              <select class="form-select" v-model="quickDates">
                <option value="today" selected>Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="7">7 days</option>
                <option value="30">30 days</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-3 mb-2">
            <Datepicker
              v-model="filters.dateFrom"
              :autoApply="true"
              class="w-100"
              placeholder="From Date"
            />
          </div>
          <div class="col-12 col-md-3 mb-2">
            <Datepicker
              v-model="filters.dateTo"
              :autoApply="true"
              class="w-100"
              placeholder="To Date"
            />
          </div>
          <div class="col-12 col-md-3 mb-2">
            <button
              @click="getUserGameHistory(false)"
              class="filter_show-btn btn btn-primary w-100"
            >
              {{ $t("pages.user.button_show") }}
            </button>
          </div>
        </div>
      </div>
      <div class="pt-2 color-white" v-if="history.length">
        Shown <strong> {{ history.length }}</strong> results
      </div>
      <div
        class="py-3 position-relative"
        :class="{ 'd-flex  justify-content-center': isLoading }"
      >
        <loadingSpinenrBig class="my-5 position-absolute" v-if="isLoading" />
        <div class="d-flex flex-wrap">
          <div
            class="game-history__tab col-12"
            v-for="item in items"
            v-bind:key="item.TrailID"
          >
            <div class="top d-flex align-items-top">
              <div class="details w-100">
                <div class="game pb-2 mb-2 border-bottom">
                  <div
                    class="d-flex flex-column flex-sm-row align-items-top align-items-sm-center"
                  >
                    <a
                      href="#"
                      @click.prevent="openGame(item.GameID)"
                      class="d-flex align-items-center"
                    >
                      <img
                        class="me-2 game-thumb"
                        :src="getGameThumb(item.GameID)"
                        alt=""
                      />
                      <span style="white-space: nowrap">{{
                        getGameName(item.GameID)
                      }}</span>
                    </a>
                    <span class="description ps-1">
                      {{ item.Description }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom d-flex flex-wrap row justify-content-between">
              <div class="type pe-3 col-6 col-sm-3 mb-3">
                <div class="label">Type</div>
                <icon
                  :class="item.TransactionType.toLowerCase()"
                  variant="circled-arrow"
                  class="ms-2 icon"
                />
                {{ item.TransactionType }}
              </div>
              <div class="round pe-3 col-6 col-sm-3 mb-3">
                <div class="label">Round</div>
                {{ item.GameRoundID }}
              </div>
              <div class="date pe-3 col-6 col-sm-3 mb-3">
                <div class="label">Date</div>
                {{ formatDate(item.Timestamp) }}
              </div>
              <div class="balance pe-3 col-6 col-sm-3 mb-3">
                <div class="label">Balance</div>
                {{ getBalance(item.BalanceReal, item.BalanceBonus) }}
                {{ user.currency }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="no-data text-center py-5"
          v-if="dataLoaded && !isLoading && items.length === 0"
        >
          {{ $t("pages.user.text_nothing_found") }}
        </div>
      </div>
    </div>
    <div class="pb-5 text-center" v-if="!disableShowMore">
      <button
        @click="loadMore"
        :disabled="isLoading"
        class="btn btn-primary btn-xl"
      >
        Load more
      </button>
    </div>
  </div>
</template>

<style lang="scss">
div.game-history {
  &__tab {
    color: #fff;
    border: 1px solid rgba(#fff, 0.1);
    background: rgba(#fff, 0.1);
    margin-bottom: 10px;
    border-radius: 12px;
    padding: 8px 20px !important;
    padding-bottom: 4px !important;
    font-size: 0.8rem; //12px!important;
    .label {
      font-size: 10px;
      font-weight: 600;
      opacity: 0.6;
    }
    .round {
      font-size: 0.8rem; //12px;
      margin-bottom: 0.5rem !important;
      span {
        opacity: 0.7;
      }
    }
    .type,
    .date,
    .balance {
      margin-bottom: 0.5rem !important;
    }
    .description {
      opacity: 0.8;
    }
    .icon {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin: 0.2rem 0.5rem 0 0.2rem !important;
      &.loss {
        fill: rgb(223, 70, 70);
      }
      &.bet {
        transform: rotate(-45deg);
        fill: rgb(223, 70, 70);
      }
      &.win {
        transform: rotate(225deg);
        fill: rgb(0, 255, 0);
      }
    }
    .game {
      font-size: 0.9rem !important; //12px;
      border-color: rgba(#fff, 0.1) !important;
    }
    a {
      color: #fff;
      text-decoration: none;
      font-weight: 600;
      &:hover {
        color: var(--app-yellow);
      }
    }
  }
  .color-white {
    color: white;
  }
  .vue_table-container {
    min-height: 50vh;
    display: block;
    //background: #5d5c5c;
    //padding: 45px 50px 25px;
    border-radius: 20px;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  //table filter header css
  .filter_header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .filter_time-select {
    display: flex;
    flex: 1;
  }

  .date_select-div {
    display: flex;
    flex: 2;
    justify-content: space-evenly;

    @media (max-width: 768px) {
      flex-direction: column;
      margin: 15px 0 0;
    }
  }

  .dp__main.dp__theme_light.date_picker-input {
    display: flex;
    width: 80%;

    @media (max-width: 768px) {
      margin-bottom: 15px;
      width: 100%;
    }
  }

  .dp__main.dp__theme_light.date_picker-input > div {
    width: inherit;
    margin: 0 auto;
  }

  .pagination_section {
    ul.pagination {
      justify-content: center;
      margin: 20px auto;
    }
  }

  .game-thumb {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
  .no-data {
    font-size: 24px;
    color: rgba(#fff, 0.3);
    font-weight: 400;
    margin-top: 20px;
  }
}
</style>
