<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { toggleAuthModal } from "../helpers/modals";
import { useRouter } from "vue-router";
import cashier from "../helpers/cashier";
import { useStore } from "vuex";

// Declarations
const router = useRouter();
const store = useStore();

// Props
const props = defineProps({
  link: { required: true, type: Object },
});

// Emits
const emits = defineEmits(["navigated"]);

// Computed
let user = computed(() => store.getters.user);

// Methods
const openCashier = (type) => {
  cashier.open(type);
};
const openAuthModal = (useCase) => {
  toggleAuthModal({ useCase, show: true });
};
const handleLink = () => {
  const action = props.link.action;
  const supportedButtonActions = {
    redirect: "REDIRECT",
    deposit: "DEPOSIT",
    withdraw: "WITHDRAW",
    smartico: "SMARTICO",
  };
  const protectedActions = [
    supportedButtonActions.deposit,
    supportedButtonActions.withdraw,
    supportedButtonActions.smartico,
  ];
  emits("navigated");
  // Check if banner button has any assigned path. Also check if that banner button path exists in our routes
  if (action === supportedButtonActions.redirect && props.link.path) {
    if (router.resolve(props.link.path).name) {
      router.push(props.link.path);
      return;
    }
    if (props.link.is_new_tab) {
      window.open(props.link.path, "_blank");
      return;
    }
  }
  // Check if the action is valid and if user is logged in
  // If not, open registration modal
  if (protectedActions.includes(action) && !user.value.id) {
    openAuthModal("login");
    return;
  }
  // If yes, open the cashier (deposit or withdraw)
  if (protectedActions.includes(action)) {
    if (action === supportedButtonActions.smartico) {
      window._smartico.dp("dp:gf");
      return;
    }
    openCashier(action);
    return;
  }
};
</script>
<template>
  <a href="javascript:void(0)" @click.prevent="handleLink" class="app-link">
    <slot name="icon"></slot>
    <span>
      {{ link.label }}
    </span>
  </a>
</template>
