<script setup>
import GameSelectedFilter from "./GameSelectedFilter.vue";
import { useStore } from "vuex";
import { computed } from "vue";

// Declarations
const store = useStore();

// Computed
const getSelectedFilters = computed(() => store.getters.selectedFilters);
</script>
<template>
  <div class="game-selected-filters" v-if="getSelectedFilters.length">
    <h1 class="game-selected-filters__title">
      {{ $t("pages.games.title_filter_search_result") }}
    </h1>
    <ul class="p-0 d-flex">
      <GameSelectedFilter
        v-for="filter in getSelectedFilters"
        :key="filter.id"
        :filter="filter"
      />
    </ul>
  </div>
</template>
<style scoped lang="scss">
.game-selected-filters {
  margin-bottom: toRem(20);
  &__title {
    color: var(--app-yellow);
    font-size: toRem(40);
    margin-bottom: toRem(10);
  }
}
</style>
