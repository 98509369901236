<script setup>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import cashier from "@/helpers/cashier";

// Declarations
const store = useStore();

// Data
const praxisCashier = ref(null);

// Computed
const getCashierUrl = computed(() => store.getters.cashierUrl);

const getCashierToken = computed(() => {
  const urlParts = getCashierUrl.value.split("/");
  return urlParts[urlParts.length - 1];
});

onMounted(() => {
  cashier.init(praxisCashier.value, getCashierToken.value);
});
</script>
<template>
  <div class="w-100" ref="praxisCashier"></div>
</template>
