// global translations plugin vue 3
import store from "@/store";
export default {
  install(app) {
    app.config.globalProperties.$t = (string) => {
      let data = store.state.global.strings;

      const getProp = (obj, prop) => {
        if (typeof obj === "undefined") {
          return prop;
        }

        const index = prop.indexOf(".");
        if (index !== -1) {
          return (
            getProp(obj[prop.substring(0, index)], prop.substr(index + 1)) ||
            prop
          );
        }

        return obj[prop];
      };

      return getProp(data, string);
    };
  },
};
