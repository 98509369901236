import authProvider from "@/providers/auth";
import api from "@/providers/api";

export default {
  state: {
    user: {},
    geodata: (() => {
      const cookies = document.cookie.split(";");
      const geoData = cookies.reduce((acc, cookie) => {
        const [cookieKey, cookieValue] = cookie.split("=");
        const key = cookieKey.trim("");
        if (key === "country" || key === "user_ip") {
          return { ...acc, [key]: cookieValue };
        }
        return acc;
      }, {});
      return {
        country: geoData?.country || "US",
        user_ip: geoData?.user_ip || "0.0.0.0",
      };
    })(),
    balance: {},
  },
  getters: {
    user: (state) => state.user,
    geodata: (state) => state.geodata,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      setTimeout(() => {
        if (user.first_name == null) {
          let event = new CustomEvent("continueRegistration");
          window.dispatchEvent(event);
        }
      }, 400);
    },
    setBalance(state, balance) {
      state.balance = balance;
    },
    setPlayerAttributes(state, data) {
      if (!state.user.player_attributes) {
        state.user.player_attributes = [];
      }
      const attributeIndex = state.user?.player_attributes?.findIndex(
        ({ attribute_name }) => attribute_name === data.attribute_name
      );
      if (attributeIndex !== -1) {
        state.user.player_attributes[attributeIndex] = data;
      } else {
        state.user.player_attributes.push(data);
      }
    },
  },
  actions: {
    async userMeUpdate(context, payload) {
      let result = await authProvider.userMeUpdate({
        ...payload,
        deviceAccountInfo: {
          ...context.getters.deviceAccountInfo,
          userAgent: window.navigator.userAgent,
        },
      });
      if (result) {
        context.commit("setUser", result);
      }
      return result;
    },
    async fetchUser(context, payload) {
      let result = await authProvider.fetchUser(payload);
      if (result) {
        context.commit("setUser", result);
      }
      return result;
    },
    async refreshBalance(context, sessionKey) {
      let result = await api
        .post("/crm/updateBalance", {
          sessionKey: sessionKey,
        })
        .then((r) => {
          context.commit("setBalance", r);
          return r.data;
        })
        .catch((e) => {
          context.dispatch("logOut");
          console.log(e);
        });
      context.commit("setBalance", result);
    },
    async favouriteGame(context, payload) {
      payload.sessionKey = context.state.user.crm_session_key;
      await api
        .post("/crm/favouriteGame", payload)
        .then((r) => {
          if (r.data.success) {
            context.dispatch("fetchUser");
          }
        })
        .catch((e) => {
          console.log(e);
          return Promise.reject(e);
        });
    },
  },
};
