<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import AppLink from "../AppLink.vue";
import { toggleAuthModal } from "../../helpers/modals";

import userMenu from "@/components/header/header-user.vue";

const menu_opened = ref(false);
const toggleMenu = () => {
  menu_opened.value = !menu_opened.value;
};

const store = useStore();
//store getter with parameter

const route = useRoute();
watch(
  () => route.path,
  (newValue) => {
    if (newValue) {
      menu_opened.value = false;
    }
  },
  { immediate: true }
);

const showAuthModal = (useCase) => {
  toggleAuthModal({ useCase, show: true });
};
const mobileAuthToggler = () => {
  if (store.getters.hasBeenLoggedBefore) {
    toggleAuthModal({ show: true, useCase: "login" });
    return;
  }
  toggleAuthModal({ show: true, useCase: "registration" });
};
const state = computed(() => {
  return store.state;
});
const menuItems = computed(
  () => store.getters.navigationMenus?.header_menu?.links || []
);
const leftMenuItems = computed(
  () => store.getters.navigationMenus?.left_menu?.links || []
);

let balance = computed(() => {
  let balance = {
    amount: 0,
    currency: "USD",
  };
  if (store.state.user.balance && store.state.user.balance.CurrentBalance) {
    balance.amount = store.state.user.balance.CurrentBalance;
    balance.currency = store.state.user.balance.CurrencyISO;
  }
  //check if balance is nubmer
  if (balance.amount && !isNaN(balance.amount)) {
    //balance.amount = (parseFloat(balance.amount) / 100).toFixed(2);
    balance.amount = parseFloat(balance.amount).toFixed(2);
  }

  return balance;
});

let refreshBalance = () => {
  store.dispatch("refreshBalance", user.value.crm_session_key);
};

let user = computed(() => {
  return state.value.user.user;
});

watch(store.state.auth.jwt, async (newData) => {
  if (newData) {
    refreshBalance();
  }
});

onMounted(() => {
  window.addEventListener("toggleAuthModal", () => {
    menu_opened.value = false;
  });
});
</script>

<template>
  <div>
    <header class="header d-flex align-items-center">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-4 col-md-4 col-lg-5 d-flex align-items-center">
            <a
              href="#"
              @click.prevent="toggleMenu"
              class="header__menu-toggle me-3"
            >
              <icon :variant="'menu'" color="#fff" />
            </a>
            <div class="header__mobile-logo d-block d-lg-none">
              <router-link to="/">
                <img
                  src="@/assets/img/logomob.svg"
                  alt="logo"
                  class="pe-none"
                />
              </router-link>
            </div>
            <ul class="header__nav d-none d-lg-flex m-0 p-0 ps-3 flex-nowrap">
              <li v-for="item in menuItems" :key="item.id">
                <AppLink :link="item" />
              </li>
            </ul>
          </div>
          <div class="col-4 col-lg-2 d-none d-lg-block">
            <div
              class="header__logo"
              :class="{
                'header__logo--shrunk': store.getters.isGamesScrolling,
              }"
            >
              <div class="bg d-none"></div>
              <router-link to="/">
                <img src="@/assets/img/logo_bg.png" alt="logo" />
              </router-link>
            </div>
          </div>
          <div class="col-8 col-lg-5 d-flex justify-content-end">
            <template v-if="!user.id">
              <div class="header__user d-none d-md-flex justify-content-end">
                <a
                  class="btn btn-primary"
                  @click="showAuthModal('registration')"
                >
                  {{ $t("header.button_sign_up") }}
                </a>
                <a
                  class="btn btn-outline-primary ms-3"
                  @click="showAuthModal('login')"
                >
                  {{ $t("header.button_sign_in") }}</a
                >
              </div>

              <a
                href="#"
                @click="mobileAuthToggler()"
                class="d-flex d-md-none header__user-toggle"
              >
                <img src="@/assets/img/login.svg" alt="" />
              </a>
            </template>
            <template v-if="user.id">
              <user-menu />
            </template>
          </div>
        </div>
      </div>
    </header>
    <div class="mobile__side-nav" :class="{ active: menu_opened }">
      <div class="top text-end py-3 pb-3">
        <a href="#" @click.prevent="menu_opened = false">
          <icon variant="close" color="#fff" />
        </a>
      </div>
      <template v-if="user.id">
        <h5 class="text-white">
          My Balance:
          <span class="text-warning">{{
            balance.amount + balance.currency
          }}</span>
        </h5>
      </template>
      <div
        class="user text-center py-2 d-flex justify-content-center"
        v-if="!user.id"
      >
        <a class="btn btn-primary" @click="showAuthModal('registration')">{{
          $t("header.button_sign_up")
        }}</a>
        <a
          class="btn btn-outline-primary ms-3"
          @click="showAuthModal('login')"
          >{{ $t("header.button_sign_in") }}</a
        >
      </div>
      <ul class="nav-list list-unstyled">
        <li v-for="item in leftMenuItems" :key="item.id">
          <AppLink :link="item">
            <template #icon>
              <div class="icon">
                <icon :variant="item.icon_name || 'casino'" color="#fff" />
              </div>
            </template>
          </AppLink>
        </li>
      </ul>
    </div>
    <div
      class="mobile__side-nav-fader"
      :class="{ active: menu_opened }"
      @click="toggleMenu"
    ></div>
  </div>
</template>
<style lang="scss" scoped>
.mobile {
  &__side-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 320px;
    height: 100vh;
    max-width: 90%;
    background: var(--app-body);
    z-index: 99999;
    transition: all 0.3s ease-in-out;
    transform: translateX(-100%);
    padding: 20px;
    .nav-list {
      padding: 30px 0px;
      a {
        color: #fff;
        height: 48px;
        text-decoration: none;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding: 0px 8px;
        &:hover {
          background: rgba(#fff, 0.1);
        }
        .icon {
          width: 42px;
          margin-right: 12px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
    &.active {
      transform: translateX(0);
    }
  }
  &__side-nav-fader {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--app-body);
    opacity: 0.6;
    z-index: 999;
    &.active {
      visibility: visible;
    }
  }
}
.header {
  background: #0f0004;
  height: 100px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  .container {
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    height: 60px;
  }
  &__mobile-logo {
    img {
      height: 76px;
    }
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
  &__user-toggle {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
    }
  }
  &__logo {
    position: relative;
    height: 100px;
    display: flex;
    justify-content: center;
    a {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 11;
      text-align: center;
      @media (max-width: 991px) {
        top: 20px;
        width: 108px;
      }
      img {
        width: 158px;
        @media (max-width: 1024px) {
          width: 108px;
        }
      }
    }
    &--shrunk {
      @media only screen and (min-width: 992px) {
        a {
          img {
            width: 108px;
          }
        }
      }
    }
    .bg {
      position: absolute;
      top: -20px;
      width: 130px;
      height: 130px;
      left: 40%;
      z-index: 10;

      background: var(--app-brown);
      border-radius: 10px;
      transform: rotate(-45deg) translateX(-50%);
      transform-origin: center;
      @media (max-width: 1024px) {
        left: 45%;
        top: 20px;
        left: 50%;
        width: 70px;
        height: 70px;
      }
    }
  }
  &__nav {
    li {
      padding-right: 30px;
      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #ffffff;
        text-decoration: none;
        white-space: nowrap;
        position: relative;
        display: block;
        padding: 9px 0px;
        transition: 0.3s;
        &::after {
          transition: 0.3s;
          content: "";
          display: block;
          width: 0px;
          height: 3px;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          background: var(--app-yellow);
          border-radius: 12px;
          opacity: 0;
        }
        &:hover {
          color: var(--app-yellow);
          transition: 0.3s;
          &::after {
            transition: 0.3s;
            opacity: 1;
            width: 20px;
          }
        }
      }
    }
  }
  &__menu-toggle {
    &:hover {
      svg {
        fill: var(--app-yellow) !important;
        path {
          fill: var(--app-yellow);
        }
      }
    }
    svg {
      width: 24px;
      height: 24px;
      fill: #fff;
    }
  }
}
</style>
