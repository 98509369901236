<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import homeRandomGame from "./home-random-game.vue";
import homeRandomChosen from "./home-random-chosen.vue";

// Declarations
const store = useStore();
let previousActiveGameIds = [];

// Data
const activeGames = ref([]);
const chosenGame = ref({});
const isAnimationRunning = ref(false);

// Computed
const getRandomGames = computed(
  () => store.getters.homepageData?.homepageSettings?.random_games
);
const getGameColumns = computed(() => {
  const gameColumns = [];
  let gameColumnIndex = 1;

  for (const g of getRandomGames.value) {
    const isColumnEven = gameColumnIndex % 2 === 0;
    if (!gameColumns[gameColumnIndex - 1]) {
      gameColumns[gameColumnIndex - 1] = [];
    }
    if (!isColumnEven && gameColumns[gameColumnIndex - 1].length < 5) {
      gameColumns[gameColumnIndex - 1].push(g);
      continue;
    }
    if (isColumnEven && gameColumns[gameColumnIndex - 1].length < 4) {
      gameColumns[gameColumnIndex - 1].push(g);
      continue;
    }
    ++gameColumnIndex;
  }
  return gameColumns;
});

const generateRandomGame = () => {
  const filteredGames = getRandomGames.value.filter(
    (game) => !previousActiveGameIds.includes(game.id)
  );
  const randomIndex = Math.floor(Math.random() * filteredGames.length);
  const randomGame = getRandomGames.value[randomIndex];

  return randomGame;
};
const isGameChosen = computed(
  () => chosenGame.value && !!Object.keys(chosenGame.value).length
);

// Methods
const reset = () => {
  chosenGame.value = {};
  isAnimationRunning.value = false;
};
const startAnimation = () => {
  if (isAnimationRunning.value) {
    return;
  }

  isAnimationRunning.value = true;

  if (isGameChosen.value) {
    chosenGame.value = {};
  }

  const newRandomGamePeriod = 400;
  const randomCounterRepetitions = 15;
  let repetitionCounter = 0;
  const resetPeriod = 1000;

  const repetitionInterval = setInterval(() => {
    const randomGame = generateRandomGame();
    activeGames.value.push(randomGame);
    previousActiveGameIds.push(randomGame.id);

    if (repetitionCounter === randomCounterRepetitions) {
      previousActiveGameIds = [];

      chosenGame.value = randomGame;
      activeGames.value = [];
      isAnimationRunning.value = false;

      clearInterval(repetitionInterval);
      return;
    }

    setTimeout(() => {
      const gameId = activeGames.value.findIndex(
        (game) => game.id === randomGame.id
      );
      activeGames.value.splice(gameId, 1);
    }, resetPeriod);

    ++repetitionCounter;
  }, newRandomGamePeriod);
};
</script>
<template>
  <div class="random-games position-relative overflow-hidden">
    <div class="d-flex align-items-center justify-content-center">
      <ul
        class="random-games__column d-flex flex-column"
        v-for="(gameColumn, index) in getGameColumns"
        :key="index"
      >
        <homeRandomGame
          v-for="game in gameColumn"
          :key="game.id"
          :game="game"
          :active-games="activeGames"
        />
      </ul>
    </div>
    <div class="random-games__blur position-absolute"></div>
    <homeRandomChosen
      :chosen-game="chosenGame"
      @shuffle="startAnimation"
      @open-game="reset"
      @close="reset"
    />
    <div
      class="random-games__action position-absolute w-100 d-flex flex-column align-items-center"
      :class="{
        'random-games__action--hide': isAnimationRunning || isGameChosen,
      }"
    >
      <h1 class="random-games__title text-center">
        {{ $t("pages.homepage.random_game.title") }}
      </h1>
      <a
        role="button"
        class="random-games__button"
        @click="!isAnimationRunning ? startAnimation() : null"
        >{{ $t("pages.homepage.random_game.button_suprise_me") }}</a
      >
    </div>
  </div>
</template>
<style scoped lang="scss">
.random-games {
  padding: 30px 0 0;
  @include mobile {
    padding: 40px 0 10px;
  }
  &__column {
    gap: 10px;
    padding-left: 10px;
    @include tablet {
      gap: 10px;
      padding-left: 10px;
    }
    @include mobile {
      gap: 5px;
      padding-left: 5px;
    }
  }
  &__blur {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(16, 43, 60, 0.3);
    backdrop-filter: blur(6px);
  }
  &__action {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-out;
    &--hide {
      opacity: 0;
      pointer-events: none;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 90px;
    margin-bottom: 30px;
    color: #fff;
    padding: 0 20px;
    @include desktop-xl {
      max-width: 70%;
    }
    @include desktop {
    }
    @include tablet {
      font-size: 50px;
      margin-bottom: 20px;
    }
    @include mobile {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
  &__button {
    font-weight: 500;
    font-size: 22px;
    background: #fff;
    border-radius: 40px;
    padding: 15px 40px;
    text-decoration: none;
    color: #000;
    @include desktop {
      font-size: 18px;
    }
    @include tablet {
      font-size: 16px;
      padding: 13px 25px;
    }
    @include mobile {
      font-size: 12px;
      padding: 7px 18px;
    }
  }
}
</style>
