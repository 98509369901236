<script setup>
import { ref, inject, computed, defineEmits } from "vue";
import { useStore } from "vuex";

const store = useStore();

let code = ref("");
let isLoading = ref(false);
let result = ref({});

const emit = defineEmits(["redeem"]);

let user = computed(() => store.state.user.user);

const api = inject("api");
const redeem = async () => {
  isLoading.value = true;
  await api
    .post("/crm/VoucherRedeemCode", {
      sessionKey: user.value.crm_session_key,
      voucherCode: code.value,
    })
    .then((response) => {
      if (response.data.VoucherRedeemCodeResult) {
        result.value.redeemed =
          response.data.VoucherRedeemCodeResult.IsVoucherRedeemed === "true";
        if (result.value.redeemed) {
          emit("redeem");
        }
        result.value.error = response.data.VoucherRedeemCodeResult.Error;
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      isLoading.value = false;
    });
  isLoading.value = false;
};
</script>

<template>
  <div class="redeem pb-3 mb-3">
    <h4 class="color-white">
      {{ $t("pages.user.bonuses.title_have_bonus_code") }}
    </h4>
    <p>
      {{ $t("pages.user.bonuses.title_redeem_info") }}
    </p>
    <div class="alert alert-danger" v-if="result.error">
      <strong
        >{{ $t("pages.user.bonuses.message_redeem_error") }}: #{{
          result.error.ErrorNo
        }}</strong
      >
      :
      {{ result.error.Description }}
    </div>
    <div class="alert alert-success" v-if="result.redeemed">
      <strong>{{ $t("pages.user.bonuses.message_redeem_success") }}!</strong>
      {{ $t("pages.user.bonuses.message_redeem_success_message") }}
    </div>
    <form
      class="redeem__form d-block d-sm-flex align-item-center"
      @submit.prevent="redeem"
    >
      <div class="form-input me-0 me-sm-3 mb-2">
        <input
          :disabled="isLoading"
          :placeholder="$t('pages.user.bonuses.input_placeholder_voucher_code')"
          type="text"
          v-model="code"
        />
      </div>
      <button type="submit" :disabled="isLoading" class="btn btn-primary mb-2">
        <span v-if="!isLoading">
          {{ $t("pages.user.bonuses.button_redeem_code") }}
        </span>
        <span v-else> loading... </span>
      </button>
    </form>
    <hr class="mt-4" />
  </div>
</template>

<style lang="scss" scoped>
.redeem {
  color: #fff;
  &__form {
    input {
      background: #d9d9d9;
      border-radius: 15px;
      height: 48px;
      border: none;
      outline: none;
      padding: 0px 18px;
      color: #424242;
      font-weight: 600;
      width: 300px;
      @media (max-width: 574px) {
        width: 100%;
      }
    }
    .btn {
      height: 48px;
      border-radius: 15px;
      @media (max-width: 574px) {
        width: 100%;
      }
    }
  }
}
</style>
