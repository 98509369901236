<script setup>
import { defineProps, computed } from "vue";

// Props
const props = defineProps({
  game: {
    required: true,
    type: Object,
    default: () => ({}),
  },
  activeGames: {
    required: true,
    type: Array,
    default: () => [],
  },
});

// Computed
const isGameActive = computed(() =>
  props.activeGames.some((g) => g.id === props.game.id)
);
</script>

<template>
  <li
    class="random-game position-relative"
    :class="{ 'random-game--active': isGameActive }"
  >
    <img
      v-if="game?.thumbnail?.url"
      :src="game.thumbnail.url"
      :alt="game.thumbnail.url.alternativeText || `image for game ${game.Name}`"
      class="w-100 h-100"
    />
  </li>
</template>
<style scoped lang="scss">
.random-game {
  list-style: none;
  border-radius: 15px;
  width: 110px;
  height: 110px;
  transition: transform 0.6s ease-in-out, z-index 0.6s ease-in-out 4s;
  @include desktop {
    width: 90px;
    height: 90px;
  }
  @include tablet {
    width: 50px;
    height: 50px;
    border-radius: 6px;
  }
  @include mobile {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(16, 43, 60, 0.3);
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  img {
    object-fit: cover;
    border-radius: inherit;
  }
  &--active {
    z-index: 1;
    transform: scale(1.3);
    &::after {
      opacity: 0;
    }
  }
}
</style>
