<script setup>
import { onMounted, ref, inject, computed, watch } from "vue";
import { useStore } from "vuex";

import Datepicker from "@vuepic/vue-datepicker";
import loadingSpinenrBig from "@/components/loading-spinner-big.vue";

let isLoading = ref(false);
let dataLoaded = ref(false);
let transactions = ref([]);
let quickDates = ref(null);

let filters = ref({
  dateFrom: null,
  dateTo: null,
  pageNo: 1,
  perPage: 20,
  orderCriteria: "Timestamp",
  orderType: "DESC",
});

const store = useStore();
let user = computed(() => store.state.user.user);
let items = computed(() => {
  return transactions.value !== null ? transactions.value : [];
});

watch(
  () => quickDates.value,
  (val) => {
    if (val == "today") {
      let todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      todayStart = todayStart.toISOString().split("T")[0];
      let todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);
      todayEnd = todayEnd.toISOString().split("T")[0];
      filters.value.dateFrom = todayStart;
      filters.value.dateTo = todayEnd;
    }
    if (val == "yesterday") {
      let yesterdayStart = new Date();
      yesterdayStart.setDate(yesterdayStart.getDate() - 1);
      yesterdayStart.setHours(0, 0, 0, 0);
      yesterdayStart = yesterdayStart.toISOString().split("T")[0];
      let yesterdayEnd = new Date();
      yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);
      yesterdayEnd.setHours(23, 59, 59, 999);
      yesterdayEnd = yesterdayEnd.toISOString().split("T")[0];

      filters.value.dateFrom = yesterdayStart;
      filters.value.dateTo = yesterdayEnd;
    }
    if (val == "7") {
      let todayStart = new Date();
      todayStart.setDate(todayStart.getDate() - 7);
      todayStart.setHours(0, 0, 0, 0);
      todayStart = todayStart.toISOString().split("T")[0];
      let todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);
      todayEnd = todayEnd.toISOString().split("T")[0];
      filters.value.dateFrom = todayStart;
      filters.value.dateTo = todayEnd;
    }
    if (val == "30") {
      let todayStart = new Date();
      todayStart.setDate(todayStart.getDate() - 30);
      todayStart.setHours(0, 0, 0, 0);
      todayStart = todayStart.toISOString().split("T")[0];
      let todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);
      todayEnd = todayEnd.toISOString().split("T")[0];
      filters.value.dateFrom = todayStart;
      filters.value.dateTo = todayEnd;
    }
  },
  { deep: true }
);

const getBalance = (real, bonus) => {
  let balance = 0;
  if (real) {
    balance = Number(real);
  }
  if (bonus) {
    balance += Number(bonus);
  }
  //return parseFloat(balance / 1000).toFixed(2);
  return parseFloat(balance).toFixed(2);
};

const formatDate = (timestamp) => {
  let date = new Date(timestamp);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = "0" + date.getMinutes();
  let seconds = "0" + date.getSeconds();
  let formattedTime =
    day +
    "/" +
    month +
    "/" +
    year +
    " " +
    hours +
    ":" +
    minutes.substr(-2) +
    ":" +
    seconds.substr(-2);
  return formattedTime;
};

const getIconVariant = (transactionType) =>
  transactionType.toLowerCase() === "accepted"
    ? "circled-arrow"
    : "circled-declined";

const api = inject("api");
const getUserTransactions = async () => {
  isLoading.value = true;
  await api
    .post("/crm/transactionsHistory", {
      sessionKey: user.value.crm_session_key,
      ...filters.value,
    })
    .then((r) => {
      if (r.data.TransactionGetAccountTransactionsResult.Transactions) {
        transactions.value =
          r.data.TransactionGetAccountTransactionsResult.Transactions.PaymentTransaction;

        if (!Array.isArray(transactions.value)) {
          transactions.value = [transactions.value];
        }
      }

      dataLoaded.value = true;
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

onMounted(() => {
  quickDates.value = "today";
  setTimeout(() => {
    getUserTransactions(false);
  }, 200);
});
</script>

<template>
  <div class="game-history">
    <div class="vue_table-container">
      <div class="vue_table-section">
        <div class="filter__header row">
          <div class="col-12 col-md-3 mb-2">
            <div class="filter__time-select">
              <select class="form-select" v-model="quickDates">
                <option value="today" selected>Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="7">7 days</option>
                <option value="30">30 days</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-3 mb-2">
            <Datepicker
              v-model="filters.dateFrom"
              :autoApply="true"
              class="w-100"
              placeholder="From Date"
            />
          </div>
          <div class="col-12 col-md-3 mb-2">
            <Datepicker
              v-model="filters.dateTo"
              :autoApply="true"
              class="w-100"
              placeholder="To Date"
            />
          </div>
          <div class="col-12 col-md-3 mb-2">
            <button
              @click="getUserTransactions(false)"
              class="filter_show-btn btn btn-primary w-100"
            >
              {{ $t("pages.user.button_show") }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="py-3 position-relative"
        :class="{ 'd-flex justify-content-center': isLoading }"
      >
        <loadingSpinenrBig class="my-5 position-absolute" v-if="isLoading" />
        <div class="d-flex flex-wrap">
          <div
            class="game-history__tab col-12"
            v-for="item in items"
            :key="item.UniqueTransactionID"
          >
            <div class="bottom d-flex flex-wrap row justify-content-between">
              <div class="type pe-3 col-6 col-sm-3 col-lg-2 mb-3">
                <div class="label">
                  {{ $t("pages.user.label_table_header_type") }}
                </div>
                {{ item.TransactionTypeName }}
                <icon
                  :class="item.TransactionStatusName.toLowerCase()"
                  :variant="getIconVariant(item.TransactionStatusName)"
                  class="ms-2 icon"
                />
              </div>
              <div class="round pe-3 col-6 col-sm-3 col-lg-2 mb-3">
                <div class="label">
                  {{ $t("pages.user.label_table_header_details") }}
                </div>
                {{ item.PaymentMethodName }}
              </div>
              <div class="date pe-3 col-6 col-sm-3 col-lg-2 mb-3">
                <div class="label">
                  {{ $t("pages.user.label_table_header_date") }}
                </div>
                {{ formatDate(item.Timestamp) }}
              </div>
              <div class="date pe-3 col-6 col-sm-3 col-lg-2 mb-3">
                <div class="label">
                  {{ $t("pages.user.label_table_header_amount") }}
                </div>
                {{ parseFloat(item.Amount).toFixed(2) }}
                {{ item.CurrencyCode }}
              </div>
              <div class="balance pe-3 col-6 col-sm-3 col-lg-2 mb-3">
                <div class="label">
                  {{ $t("pages.user.label_table_header_balance") }}
                </div>
                {{ getBalance(item.BalanceRealAfter, item.BalanceBonusAfter) }}
                {{ user.currency }}
              </div>
              <div class="status pe-3 col-6 col-sm-3 col-lg-2 mb-3">
                <div class="label">
                  {{ $t("pages.user.label_table_header_status") }}
                </div>
                {{ item.TransactionStatusName }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="no-data text-center py-5"
          v-if="dataLoaded && !isLoading && items.length === 0"
        >
          {{ $t("pages.user.text_nothing_found") }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.game-history {
  &__tab {
    color: #fff;
    border: 1px solid rgba(#fff, 0.1);
    background: rgba(#fff, 0.1);
    margin-bottom: 12px;
    border-radius: 12px;
    padding: 20px !important;
    padding-bottom: 0px !important;
    .label {
      font-size: 11px;
      font-weight: 600;
      opacity: 0.7;
    }
    .round {
      font-size: 15px;
      span {
        opacity: 0.7;
      }
    }
    .description {
      opacity: 0.7;
    }
    .icon {
      width: 20px;
      height: 20px;
      display: inline-block;
      &.failed {
        fill: rgb(223, 70, 70);
      }
      &.pending {
        transform: rotate(-45deg);
        fill: rgb(226, 129, 44);
      }
      &.accepted {
        transform: rotate(225deg);
        fill: rgb(0, 255, 0);
      }
    }
    .game {
      font-size: 22px;
      border-color: rgba(#fff, 0.1) !important;
    }
    a {
      color: #fff;
      text-decoration: none;
      font-weight: 600;
      &:hover {
        color: var(--app-yellow);
      }
    }
  }
  .color-white {
    color: white;
  }
  .vue_table-container {
    min-height: 50vh;
    display: block;
    //background: #5d5c5c;
    //padding: 45px 50px 25px;
    border-radius: 20px;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  //table filter header css
  .filter_header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .filter_time-select {
    display: flex;
    flex: 1;
  }

  .date_select-div {
    display: flex;
    flex: 2;
    justify-content: space-evenly;

    @media (max-width: 768px) {
      flex-direction: column;
      margin: 15px 0 0;
    }
  }

  .dp__main.dp__theme_light.date_picker-input {
    display: flex;
    width: 80%;

    @media (max-width: 768px) {
      margin-bottom: 15px;
      width: 100%;
    }
  }

  .dp__main.dp__theme_light.date_picker-input > div {
    width: inherit;
    margin: 0 auto;
  }

  .pagination_section {
    ul.pagination {
      justify-content: center;
      margin: 20px auto;
    }
  }

  .game-thumb {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
  .no-data {
    font-size: 24px;
    color: rgba(#fff, 0.3);
    font-weight: 400;
    margin-top: 20px;
  }
}
</style>
