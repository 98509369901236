<script setup>
import { ref, reactive, computed, inject, watch } from "vue";
import { useStore } from "vuex";
import passwordTab from "./my-account-change-password.vue";
import auth from "../../providers/auth";
import { useToast } from "vue-toastification";
import vSelect from "vue-select";
import { VueTelInput } from "vue-tel-input/dist/vue-tel-input.es.js";
import "vue-tel-input/dist/vue-tel-input.css";
import qs from "qs";

const toast = useToast();
const store = useStore();
const $t = inject("translations");
const api = inject("api");
const keys = [
  "email",
  "first_name",
  "last_name",
  "username",
  "date_of_birth",
  "gender",
  "country",
  "address_1",
  "phone",
];

// Data
let tab = ref("profile");
const currencies = ref([]);
const countries = ref([]);
const canEdit = ref(false);
const phoneValid = ref(false);
let telInput = ref("");
let genders = ref([
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
]);
let fields = reactive([
  {
    name: "Email",
    editable: false,
    type: "email",
    key: "email",
    value: "",
  },
  {
    name: "First name",
    editable: false,
    type: "text",
    key: "first_name",
    value: "",
  },
  {
    name: "Last name",
    editable: false,
    type: "text",
    key: "last_name",
    value: "",
  },
  {
    name: "Nickname",
    editable: false,
    type: "text",
    key: "username",
    value: "",
  },
  {
    name: "Date of birth",
    editable: false,
    type: "text",
    key: "date_of_birth",
    value: "",
  },
  {
    name: "Gender",
    editable: false,
    type: "text",
    key: "gender",
    value: "",
  },
  {
    name: "Country",
    editable: false,
    type: "text",
    key: "country",
    value: "",
  },
  {
    name: "Address line 1",
    editable: false,
    type: "text",
    key: "address_1",
    value: "",
  },
  {
    name: "Phone",
    editable: false,
    type: "text",
    key: "phone",
    value: "",
  },
]);

// Computed
let user = computed(() => store.getters.user);

// Watchers
watch(fields, () => {
  canEdit.value = keys.every(
    (key) =>
      fields.find((field) => field.key === key).value !==
      user.value[Object.keys(user.value).find((userKey) => userKey === key)]
  );
});

// Methods
const saveChanges = async () => {
  if (!canEdit.value && !phoneValid.value) {
    return;
  }
  try {
    const fieldsToUpdate = fields
      .filter((field) => !user.value[field.key])
      .map(({ key, value }) => ({ key, value }))
      .reduce((acc, val) => {
        return { ...acc, [val.key]: val.value };
      }, {});

    const userData = await auth.userMeUpdate(fieldsToUpdate);
    store.commit("setUser", userData);

    toast.success($t("toaster.success.message_profile_updated"), {
      timeout: 2000,
    });
    canEdit.value = false;
  } catch (err) {
    toast.error($t("toaster.error.message_general_error"), {
      timeout: 2000,
    });
    console.error(err);
  }
};
const validateTel = (data) => {
  phoneValid.value = data.valid;
  if (data.valid) {
    fields[fields.findIndex(({ key }) => key === "phone")].value = data.number;
  }
};
const getCurrencies = async () => {
  try {
    const response = await api.get("/currencies");
    currencies.value = response.data.data.map((currency) => {
      return {
        value: currency.attributes.code,
        label: currency.attributes.code + " - " + currency.attributes.name,
      };
    });
  } catch (err) {
    console.error(err);
  }
};
const getCountriesList = async () => {
  let query = qs.stringify({
    pagination: {
      limit: -1,
    },
    filters: {
      active: {
        $eq: true,
      },
    },
  });
  try {
    const response = await api.get("/countries?" + query);
    if (response.data.data) {
      countries.value = response.data.data.map((country) => {
        return {
          label: country.attributes.name,
          value: country.attributes.code,
        };
      });
    }
  } catch (err) {
    console.error(err);
  }
};
const init = async () => {
  await getCurrencies();
  await getCountriesList();
};

// Created
init();
</script>
<template>
  <div class="my-account mb-5 pb-5">
    <div class="my-account__tabs">
      <div class="tabs-controls mb-5">
        <a
          href="#"
          :class="{ active: tab == 'profile' }"
          @click.prevent="tab = 'profile'"
        >
          {{ $t("pages.user.my_account.title_tab_profile") }}
        </a>
        <a
          href="#"
          :class="{ active: tab == 'security' }"
          @click.prevent="tab = 'security'"
        >
          {{ $t("pages.user.my_account.title_tab_security") }}
        </a>
      </div>
    </div>
    <div class="tabs-content">
      <form
        v-if="tab == 'profile'"
        class="tabs-pane"
        @submit.prevent="saveChanges"
      >
        <div
          v-for="f in fields"
          :key="f.key"
          class="form-input pb-3 d-flex align-items-center"
        >
          <label>
            {{ $t(`pages.user.my_account.label_${f.key}`) || f.name }}
          </label>
          <input
            :type="f.type"
            :value="f.value || user[f.key]"
            @input="f.value = $event.target.value"
            :disabled="!f.editable && user[f.key]?.length"
            v-if="
              f.key !== 'gender' && f.key !== 'country' && f.key !== 'phone'
            "
          />
          <template v-else>
            <input
              v-if="user[f.key]"
              :type="f.type"
              :value="user[f.key]"
              :disabled="!f.editable && user[f.key]"
            />
            <template v-else>
              <v-select
                v-if="f.key === 'gender'"
                :clearable="false"
                :options="genders"
                :placeholder="
                  $t('pages.user.my_account.input_placeholder_gender')
                "
                :reduce="(gender) => gender.value"
                :value="user[f.key]"
                v-model="f.value"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <icon class="dropdown" :variant="'dropdown'" />
                  </span>
                </template>
              </v-select>
              <v-select
                v-if="f.key === 'country'"
                :clearable="false"
                :options="countries"
                :placeholder="
                  $t('pages.user.my_account.input_placeholder_country')
                "
                :reduce="(country) => country.value"
                :value="user[f.key]"
                v-model="f.value"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <icon class="dropdown" :variant="'dropdown'" />
                  </span>
                </template>
              </v-select>
              <vue-tel-input
                v-if="f.key === 'phone'"
                @validate="validateTel"
                :inputOptions="{
                  placeholder: $t(
                    'pages.user.my_account.input_placeholder_phone'
                  ),
                }"
                v-model="telInput"
              />
            </template>
          </template>
        </div>
        <div class="form-input pb-3 pt-3 d-flex align-items-center">
          <label> </label>
          <button
            type="submit"
            class="btn btn-primary btn-lg"
            :disabled="!canEdit && !phoneValid"
          >
            {{ $t("pages.user.my_account.button_save") }}
          </button>
        </div>
      </form>
      <div v-if="tab == 'security'" class="tabs-pane">
        <password-tab />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.my-account {
  color: #fff;
  &__tabs {
    .tabs-controls {
      display: flex;
      border-bottom: 1px solid #546773;
      a {
        display: block;
        padding: 16px 12px;
        color: #fff;
        text-decoration: none;
        border-bottom: 2px solid #546773;
        transition: 0.1s;
        white-space: nowrap;
        transform: translatey(1px);
        &:hover {
          color: var(--app-yellow);
        }
        &.active {
          border-color: var(--app-yellow);
          color: var(--app-yellow);
        }
      }
    }
  }
  .form-input {
    label {
      width: 120px;
      font-size: 0.9rem;
      font-weight: 500;
      margin-right: 20px;
      margin-bottom: 0;
      color: #969696;
    }
    input {
      background: #e6e5e5;
      border-radius: 10px;
      height: 35px;
      border: none;
      outline: none;
      padding: 0px 18px;
      color: #3d3d3d;
      font-weight: 600;
      width: 300px;
      font-size: 0.9em;
      &:disabled {
        font-weight: 500;
        background: #d9d9d9;
        color: #666;
      }
    }
  }
  .vs {
    &__dropdown-toggle {
      border: 0;
    }
    &__open-indicator,
    &__selected {
      position: absolute;
      z-index: 2;
    }
    &__open-indicator {
      right: 20px;
    }
    &__selected {
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .vue-tel-input {
    border: 0;
    background: #e6e5e5;
    width: 300px;
    border-radius: 10px;
    .vti {
      &__dropdown {
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        &-list {
          z-index: 3;
          background-color: #102b3c;
        }
        &-item {
          &.highlighted {
            background-color: lighten($color: #102b3c, $amount: 5);
          }
        }
      }
    }
  }
  .v-select,
  .vue-tel-input {
    input {
      &,
      &::placeholder {
        font-weight: 500;
        color: #666;
      }
    }
  }
}
</style>
