import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import auth from "./modules/auth";
import user from "./modules/user";
import global from "./modules/global";
import games from "./modules/games";
import cashier from "./modules/cashier";
import homepage from "./modules/homepage";
import bonus from "./modules/bonus";
import crm from "./modules/crm";
import promotion from "./modules/promotion";
import kyc from "./modules/kyc";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "luck-royal",
  reducer: (state) => ({
    global: {
      langs: state.global.langs,
      currentLang: state.global.currentLang,
      data: state.global.data,
      strings: state.global.strings,
      footerData: state.global.footerData,
    },
    auth: {
      jwt: state.auth.jwt,
      registrationData: state.auth.registrationData,
      providerData: state.auth.providerData,
    },
    user: {
      user: state.user.user,
      geodata: state.user.geodata,
      balance: state.user.balance,
    },
    games: {
      selectedFilters: state.games.selectedFilters,
    },
    homepage: {
      homepageData: state.homepage.homepageData,
    },
  }),
});

const store = createStore({
  modules: {
    global,
    auth,
    user,
    games,
    cashier,
    homepage,
    bonus,
    crm,
    promotion,
    kyc,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
