<script setup>
import { ref, defineProps, inject } from "vue";
import { useStore } from "vuex";
import kycUploaderFile from "./kyc-uploader-file.vue";
import loadingSpinnerBig from "../../../components/loading-spinner-big";

// Declarations
const store = useStore();
const $t = inject("translations");
const fileTypes = [
  "image/svg+xml",
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "text/rtf",
];

// Props
const props = defineProps({
  kycKey: {
    required: true,
    type: String,
    default: "",
  },
  uploadedFiles: {
    required: true,
    type: Array,
    default: () => [],
  },
  status: {
    required: true,
    type: String,
    default: "",
  },
});

// Data
const uploaderInputEl = ref(null);
const isDragging = ref(false);
const files = ref(new Map());
const isButtonDisabled = ref(false);

// Methods
const addFiles = (localFiles) => {
  if (files.value.size === 2) {
    return;
  }
  if (localFiles.length > 2) {
    localFiles = [localFiles[0], localFiles[1]];
  }
  if (files.value.size && localFiles.length > 1) {
    localFiles = [localFiles[0]];
  }
  if (props.uploadedFiles.length && localFiles.length > 1) {
    localFiles = [localFiles[0]];
  }

  localFiles.forEach((file) => {
    if (!fileTypes.includes(file.type)) {
      alert(`File type: ${file.type} is not supported.`);
      return;
    }
    // Update the file if it exists
    if (files.value.has(file.name)) {
      files.value.delete(file.name);
    }
    files.value.set(file.name, file);
  });
};

const openUploader = () => {
  if (props.uploadedFiles.length >= 2) {
    return;
  }
  uploaderInputEl.value.click();
};

const inputFileHandler = (event, isDragType = false) => {
  if (isDragType) {
    isDragging.value = false;
  }
  const files = isDragType
    ? [...event.dataTransfer.files]
    : [...event.target.files];

  addFiles(files);
  isButtonDisabled.value = false;

  if (!isDragType) {
    event.target.value = null;
  }
};

const removeFile = (key) => {
  files.value.delete(key);
};

const submitFiles = async () => {
  if (isButtonDisabled.value || store.getters.kycIsLoading) {
    return;
  }
  isButtonDisabled.value = true;
  store.commit("setKycIsLoading", true);

  const fileData = {
    files: files.value,
    key: props.kycKey,
    data: { users_permissions_user: store.getters.user.id },
    id: store.getters.kyc?.id,
  };

  if (!props.status) {
    fileData.data[`${props.kycKey}_status`] = "in-process";
  }

  await store.dispatch("submitFiles", fileData);
  await store.dispatch("findKyc");
  await store.dispatch("getUserKycDocuments");
  files.value.clear();
  store.commit("setKycIsLoading", false);
};
</script>
<template>
  <form
    @submit.prevent="
      files.size && uploadedFiles.length < 2 ? submitFiles() : null
    "
  >
    <div
      class="kyc-uploader position-relative d-flex flex-column"
      :class="{ 'kyc-uploader--dragging': isDragging }"
      @dragover.prevent="uploadedFiles.length < 2 ? (isDragging = true) : null"
      @dragleave="uploadedFiles.length < 2 ? (isDragging = false) : null"
      @drop.prevent="
        uploadedFiles.length < 2 ? inputFileHandler($event, true) : null
      "
    >
      <input
        type="file"
        ref="uploaderInputEl"
        class="kyc-uploader__input position-absolute"
        multiple
        @input="inputFileHandler"
      />
      <h2 class="kyc-uploader__title text-center">
        {{ $t("pages.user.kyc.uploader_phrase_first") }}
        <a role="button" @click="openUploader">
          {{ $t("pages.user.kyc.uploader_phrase_second") }}</a
        >
        {{ $t("pages.user.kyc.uploader_phrase_third") }}
      </h2>
      <div
        class="kyc-uploader__area position-absolute w-100 h-100"
        v-if="!files.size && !uploadedFiles.length"
        @click="openUploader"
      ></div>
      <ul
        class="kyc-uploader__files d-flex justify-content-center overflow-auto"
      >
        <kyc-uploader-file
          v-for="[key, file] in files"
          :key="key"
          :file="file"
          @remove="removeFile(key)"
        />
        <kyc-uploader-file
          v-for="(file, index) in uploadedFiles"
          :key="index"
          :file="file"
          uploaded
        />
      </ul>
      <div
        class="kyc-uploader__loader position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
        v-if="store.getters.kycIsLoading"
      >
        <loading-spinner-big />
      </div>
    </div>
    <button
      v-if="uploadedFiles.length < 2"
      :disabled="!files.size || isButtonDisabled || store.getters.kycIsLoading"
      type="submit"
      class="btn btn-primary d-block mt-3 mx-auto"
    >
      {{ $t("pages.user.kyc.uploader_submit") }}
    </button>
  </form>
</template>
<style scoped lang="scss">
$block: ".kyc-uploader";
#{$block} {
  height: 250px;
  border: 2px dashed #fff;
  border-radius: 20px;
  padding-bottom: 10px;
  &__title {
    font-size: 18px;
    margin-top: 15px;
    color: rgb(199, 195, 195);
    a {
      color: var(--app-yellow) !important;
    }
  }
  &__input {
    visibility: hidden;
    z-index: -1;
  }
  &__area {
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 0;
  }
  &__files {
    flex-wrap: wrap;
    padding: 0 20px;
  }
  &__loader {
    left: 0;
    top: 0;
    background: rgba($color: #000, $alpha: 0.5);
    border-radius: inherit;
  }
  &--dragging {
    opacity: 0.5;
    background: rgba($color: #fff, $alpha: 0.1);
  }
}
</style>
