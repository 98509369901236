<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
import AppFreeSpinBonus from "../AppFreeSpinBonus.vue";

// Declarations
const store = useStore();

// Computed
const getUserBalance = computed(() => store.state.user?.balance);
const getBonus = computed(
  () => getUserBalance.value?.ActiveBonuses?.BonusAwarded
);
</script>
<template>
  <div class="gift-dropdown position-absolute">
    <div class="gift-dropdown__content">
      <AppFreeSpinBonus :bonus="getBonus" />
    </div>
  </div>
</template>
<style scoped lang="scss">
.gift-dropdown {
  width: 400px;
  padding-top: 10px;
  right: 0;
  top: 100%;
  @media only screen and (max-width: 745px) {
    width: 300px;
    right: unset;
    left: -30px;
  }
  &__content {
    background: #0e2737;
    padding: 25px 15px;
    @media only screen and (max-width: 745px) {
      padding: 15px 10px;
    }
  }
}
</style>
