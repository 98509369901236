<script setup>
import { ref, computed, inject } from "vue";
import { useStore } from "vuex";
import gameTile from "@/components/game-tile.vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Pagination, Autoplay, Controller, FreeMode } from "swiper";
import "swiper/swiper.scss";
import countries from "../../assets/data/countries.json";

let modules = [Pagination, Autoplay, Controller, FreeMode];

const $t = inject("translations");

let breakpoints = ref({
  300: {
    slidesPerView: "auto",
    spaceBetween: 12,
    speed: 600,
    loop: false,
    loopAdditionalSlides: 0,
  },
  // when window width is >= 480px
  480: {
    slidesPerView: "auto",
    spaceBetween: 25,
    loop: false,
  },
  // when window width is >= 640px
  640: {
    slidesPerView: "auto",
    spaceBetween: 20,
    loop: false,
  },
  768: {
    slidesPerView: "auto",
    loop: false,
  },
  1024: {
    slidesPerView: "auto",
    loop: false,
  },
  // 1366: {
  //   slidesPerView: 7,
  // },
  // 1440: {
  //   slidesPerView: 8,
  // },
});

const store = useStore();

let country = computed(
  () =>
    countries.find(({ code }) => code === store.getters.geodata.country)
      ?.name || store.getters.geodata.country
);

let controlledSwiper = ref(null);

const getTopGames = computed(
  () => store.getters.homepageData.homepageSettings.top_games
);
const getTopGamesTitle = computed(
  () => `${$t("pages.homepage.title_top_10_games_in")} ${country.value}`
);

const slideMove = (direction) => {
  if (direction == "next") {
    controlledSwiper.value.slideNext();
  } else {
    controlledSwiper.value.slidePrev();
  }
};

const setControlledSwiper = (swiper) => {
  controlledSwiper.value = swiper;
};
</script>
<template>
  <div class="sliding-top-games__row order-3">
    <div>
      <div class="container px-0 mt-1">
        <div
          class="sliding-top-games__row_name mx-0 d-flex justify-content-between align-items-center"
        >
          <h2 class="mb-0">{{ getTopGamesTitle }}</h2>
        </div>
      </div>
      <div class="row" :key="getTopGames.length">
        <div class="col-12 p-0">
          <div class="sliding-top-games__slider">
            <div class="prev pb-5">
              <div class="circle" @click="slideMove('prev')">
                <icon variant="arrow-right" />
              </div>
            </div>
            <Swiper
              :modules="modules"
              :speed="400"
              :loop="true"
              :autoplay="{ delay: 5000 }"
              effect="slide"
              :space-between="20"
              :freeMode="true"
              :breakpoints="breakpoints"
              :controller="{ control: controlledSwiper }"
              @swiper="setControlledSwiper"
            >
              <SwiperSlide v-for="(g, i) in getTopGames" :key="g">
                <div class="pb-5 pt-2 top-games-item">
                  <div class="number">
                    <img
                      :src="
                        require('@/assets/img/num_' + Number(i + 1) + '.svg')
                      "
                      alt=""
                    />
                  </div>
                  <gameTile :game="{ attributes: g }" is-top-game />
                </div>
              </SwiperSlide>
            </Swiper>
            <div class="next pb-5">
              <div class="circle" @click="slideMove('next')">
                <icon variant="arrow-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.sliding-top-games {
  &__slider {
    position: relative;
    .swiper-slide {
      max-width: 75% !important;
      @media (min-width: 475px) {
        max-width: 55% !important;
      }
      @media (min-width: 768px) {
        max-width: 40% !important;
      }
      @media (min-width: 991px) {
        max-width: 40% !important;
      }
      @media (min-width: 1024px) {
        max-width: 30% !important;
      }
      @media (min-width: 1366px) {
        max-width: 22% !important;
      }
      @media (min-width: 1440px) {
        max-width: 20% !important;
      }
      .top-games-item {
        position: relative;
        padding-left: 72px;
        @media (min-width: 768px) {
          padding-left: 72px;
        }
        .number {
          position: absolute;
          height: 80%;
          left: 0;
          top: 8%;
          z-index: 15;
          left: 12px;
          img {
            height: 70%;
          }
        }
      }
      .game-tile {
        a {
          aspect-ratio: 1.4;
          .image {
            background-position: top center;
          }
          .picture {
            background-position: top center;
          }
          .category {
            display: none;
          }
          .name {
            font-size: 13px !important;
          }
          .info {
            margin-bottom: -1px;
          }
        }
      }
    }
    .prev,
    .next {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.next {
        left: initial;
      }
      &.prev {
        svg {
          transform: scaleX(-1);
        }
      }
      right: 0;
      z-index: 20;
      .circle {
        transition: 0.5s ease-in-out;
        opacity: 0.7;
        &:hover {
          transform: scale(1.1);
          transition: 0.5s ease-in-out;
          opacity: 1;
        }
        cursor: pointer;
        width: 40px;
        height: 40px;
        min-width: 40px;
        background: rgb(250, 241, 168);
        background: radial-gradient(
          circle,
          rgba(250, 241, 168, 1) 0%,
          rgba(144, 108, 57, 1) 100%
        );
        border-radius: 50%;
        box-shadow: 0px 0px 30px 0px rgba(#000, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #2a0303;
          stroke: #2a0303;
        }
      }
    }
  }
  &__row {
    &_name {
      font-weight: 800;
      font-size: 20px;
      line-height: 1.3;
      background: -webkit-linear-gradient(
        rgba(250, 241, 168, 1),
        rgba(144, 108, 57, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 12px;
      .viewAll {
        font-style: italic;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.3;
        color: var(--app-yellow);
        margin-right: 8px;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        svg {
          margin-left: 10px;
          fill: var(--app-yellow);
          height: 12px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.sliding-top-games__row .container {
  max-width: 100% !important;
}
.swiper-pagination-progressbar {
  top: auto !important;
  height: 4px !important;
  bottom: 0px;
}
.swiper-pagination-progressbar-fill {
  background: -webkit-linear-gradient(
    rgba(250, 241, 168, 1),
    rgba(144, 108, 57, 1)
  ) !important;
}
</style>
