<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { inject } from "vue";

const toast = useToast();
const store = useStore();

const translations = inject("translations");

let isLoading = ref(false);
let user = ref({
  email: "",
  password: "",
  ip_address: computed(() => store.getters.geodata).value.user_ip,
});

let buttonDisabled = computed(() => {
  return !user.value.email || !user.value.password;
});

const oauth = (provider) => {
  const env = process.env.VUE_APP_ENV.toLowerCase();
  const baseURL =
    env === "dev"
      ? process.env.VUE_APP_LOCAL_STRAPI_URL
      : process.env.VUE_APP_STRAPI_OAUTH2_CALLBACK_URL;

  let url = `${baseURL}/connect/${provider}`;
  window.location.href = url;
};

const closeAuthModal = () => {
  let event = new CustomEvent("toggleAuthModal", {
    detail: {
      show: false,
    },
  });
  window.dispatchEvent(event);
};

let signInWithEmail = async () => {
  isLoading.value = true;
  let response = await store.dispatch("loginWithEmailAndPassword", user.value);
  if (response.jwt) {
    closeAuthModal();
    toast.success(translations("toaster.success.message_login_success"), {
      timeout: 2000,
    });
  } else {
    toast.error(
      translations("toaster.error.message_wrong_username_or_password"),
      {
        timeout: 2000,
      }
    );
  }
  isLoading.value = false;
};
</script>
<template>
  <form class="login-form" @submit.prevent="signInWithEmail">
    <div class="form-input">
      <div class="icon">
        <icon :variant="'email'" />
      </div>
      <input
        type="text"
        id="email"
        v-model="user.email"
        :placeholder="$t('login_form.input_placeholder_email')"
      />
    </div>
    <div class="form-input">
      <div class="icon">
        <icon :variant="'password'" />
      </div>
      <input
        type="password"
        id="password"
        v-model="user.password"
        :placeholder="$t('login_form.input_placeholder_password')"
      />
    </div>
    <div class="cta text-center mt-2 mt-md-4">
      <button
        type="submit"
        class="btn btn-primary w-100 mb-2"
        :disabled="buttonDisabled"
      >
        <span class="btn-text" v-if="!isLoading">
          {{ $t("login_form.button_sign_in") }}
        </span>
        <div class="spinner-border text-dark" role="status" v-if="isLoading">
          <span class="visually-hidden"></span>
        </div>
      </button>
      <div class="w-100 mt-2">
        <button
          type="button"
          @click="oauth('facebook')"
          class="btn facebook-auth w-100 btn-secondary mb-2"
        >
          <icon variant="facebook" />
          <span>{{ $t("login_form.button_sign_in_with_facebook") }}</span>
        </button>
      </div>
      <div class="w-100 mt-2">
        <button
          type="button"
          @click="oauth('google')"
          class="btn google-auth w-100 btn-secondary mb-2"
        >
          <icon variant="google" />
          <span>{{ $t("login_form.button_sign_in_with_google") }}</span>
        </button>
      </div>
    </div>
  </form>
</template>
<style lang="scss" scoped>
.cta {
  .btn {
    box-shadow: 0px 10px 25px -8px rgba(0, 0, 0, 0.26);
  }
}
.form-input {
  position: relative;
  margin-bottom: 12px;
  box-shadow: 0px 19px 25px -8px rgba(0, 0, 0, 0.17) !important;
  .icon {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 58px;
    text-align: center;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 545px) {
      width: 42px;
    }
  }

  input {
    background: #d9d9d9;

    border-radius: 15px;
    height: 48px;
    border: none;
    outline: none;
    padding-left: 58px;
    min-width: 100%;
    @media (max-width: 545px) {
      height: 42px;
      padding-left: 42px;
    }
  }
}
</style>
