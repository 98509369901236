<script setup>
import { ref, defineProps, onMounted, computed, inject } from "vue";
import { useStore } from "vuex";
import { getBonusPositionAbbr } from "../../helpers/bonus";
import { isLogged } from "../../composables/auth";
import { handleButtonAction } from "../../helpers/bonus";

// Declarations
const props = defineProps({
  bonus: {
    required: true,
    type: Object,
  },
});
const store = useStore();
const $t = inject("translations");

// Data
const textPositionEl = ref(null);
const textPositionAbbrX = ref(0);

// Computed
const getActionButtonLabel = computed(() => {
  if (isLogged.value) {
    return $t("bonus.button_deposit");
  }
  if (store.getters.hasBeenLoggedBefore) {
    return $t("bonus.button_login");
  }
  return $t("bonus.button_register");
});

// Methods
const openBonusDetailsModal = () => {
  store.commit("setBonusDetailsModal", {
    isVisible: true,
    bonus: props.bonus,
  });
};

onMounted(() => {
  textPositionAbbrX.value = textPositionEl.value.getBoundingClientRect().width;
});
</script>
<template>
  <article class="col-12 col-md-6 col-lg-6 col-xl-4">
    <div
      class="bonus"
      :class="[
        { 'bonus--used': bonus.is_used },
        { 'bonus--locked': bonus.is_locked },
      ]"
    >
      <div
        class="bonus__content d-flex justify-content-between align-items-start position-relative"
      >
        <svg class="bonus__position position-absolute">
          <text
            x="0"
            y="110"
            font-size="130"
            font-weight="700"
            fill="none"
            stroke-width="2"
            stroke="#851BC3"
            font-family="sans-serif"
            ref="textPositionEl"
          >
            {{ bonus.deposit_count }}
          </text>
          <text
            :x="textPositionAbbrX"
            y="110"
            font-size="80"
            font-weight="700"
            fill="none"
            stroke-width="2"
            stroke="#851BC3"
            font-family="sans-serif"
          >
            {{ getBonusPositionAbbr(bonus.deposit_count) }}
          </text>
        </svg>
        <h3 class="bonus__type">{{ bonus.bonus_type }}</h3>
        <div class="bonus__details">
          <h2 class="bonus__percentage">{{ bonus.bonus_percent }}</h2>
          <div class="bonus__upto">{{ bonus.bonus_upto }}</div>
        </div>
      </div>
      <div
        class="bonus__footer d-flex justify-content-between align-items-center"
      >
        <a
          v-if="!bonus.is_locked && !bonus.is_used"
          role="button"
          class="btn-outline-primary-new"
          @click="handleButtonAction(bonus)"
          >{{ getActionButtonLabel }}</a
        >
        <div
          v-if="bonus.is_locked && !bonus.is_used"
          class="bonus__locked-icon d-flex align-items-center justify-content-center"
        >
          <icon variant="locked-bonus" />
        </div>
        <div
          v-if="!bonus.is_locked && bonus.is_used"
          class="bonus__used-icon d-flex align-items-center justify-content-center"
        >
          <icon variant="used-bonus" />
        </div>
        <a role="button" class="bonus__link" @click="openBonusDetailsModal">{{
          $t("bonus.link_more_info")
        }}</a>
      </div>
    </div>
  </article>
</template>
<style scoped lang="scss">
.bonus {
  padding: 20.87px 12.74px 10px 36.89px;
  margin: 0 40.13px 25.46px 40.13px;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(32.2418px);
  @include mobile {
    padding: 26.14px 10.31px 9.37px 31.76px;
    margin: 0 0 15.17px 35.96px;
  }
  &--used {
    .bonus__content {
      opacity: 0.3;
    }
  }
  &--locked,
  &--used {
    .bonus__footer {
      justify-content: center !important;
    }
    .bonus__locked-icon,
    .bonus__used-icon {
      margin-right: 36.86px;
    }
  }
  &__content {
    margin-bottom: 30px;
  }
  &__position {
    top: 50%;
    transform: translateY(-50%);
    left: -73.78px;
    padding-top: 30px;
  }
  &__type {
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 37.61px;
    font-weight: 700;
    margin-bottom: 0;
    @include mobile {
      font-size: 15.4953px;
      line-height: 18px;
    }
  }
  &__percentage {
    font-size: 60px;
    font-weight: 800;
    line-height: 68.7px;
    color: #e1af43;
    margin-bottom: 10px;
    transition: text-shadow 0.3s ease;
    @include mobile {
      font-size: 51.6511px;
      line-height: 59px;
      margin-bottom: 9.01px;
    }
  }
  &__upto {
    color: #fff;
    text-align: right;
    font-size: 21px;
    font-weight: 500;
    line-height: 24.04px;
    @include mobile {
      font-size: 18.0779px;
      line-height: 21px;
    }
  }
  &__footer {
    padding-right: 48px;
    @media screen and (max-width: 1320px) {
      padding-right: 0;
    }
    @include laptop {
      padding-right: 0;
    }
    @include tablet {
      padding-right: 48px;
    }
    @include mobile {
      padding-right: 36px;
    }
    @include mobile-sm {
      padding-right: 0;
    }
  }
  &__link {
    font-size: 16px;
    line-height: 18.32px;
    font-weight: 500;
    color: #fff;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    @include mobile {
      font-size: 13.7736px;
      line-height: 16px;
      @include mobile {
        opacity: 1;
      }
    }
  }
  &__locked-icon,
  &__used-icon {
    height: 47.71px;
    width: 47.71px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
  }
  &__locked-icon {
    .svg-icon {
      width: 21px;
      height: 27px;
    }
  }
  &__used-icon {
    .svg-icon {
      width: 33px;
      height: 30px;
    }
  }
  &:hover {
    .bonus__percentage {
      text-shadow: 0px 0px 18px rgba(225, 175, 67, 0.8);
    }
    .bonus__link {
      opacity: 1;
      pointer-events: all;
    }
  }
}
</style>
