<script setup>
import loadingSpinnerBig from "@/components/loading-spinner-big.vue";
import qs from "qs";
import { ref, inject, onMounted, computed } from "vue";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import cashier from "@/helpers/cashier";

const toast = useToast();
const store = useStore();
const route = useRoute();
let isLoading = ref(false);
let isFullscreen = ref(false);
let gameID = ref(null);
let gameData = ref({});
let show = ref(false);
let isLandscape = ref(false);

let time = ref("");
let url = ref("");

let isSinglePage = computed(() => {
  return route.name === "game-single";
});

let isMobile = computed(() => window.innerWidth <= 991);

let favouriteGames = computed(() => store.getters.user.favourited_games);

let favourited = computed(
  () => favouriteGames.value?.includes(gameID.value) || false
);

let sessionKey = computed(() => store.getters.user.crm_session_key);

let backgroundImage = computed(() => {
  let url = "";
  if (gameData.value.backgroundImage?.data?.[0]) {
    url = gameData.value.background_image.data[0].attributes.url;
  }
  // Get fallback background image from global-fields
  if (!url) {
    if (store.getters.data?.default_game_bg?.data?.[0]) {
      url = store.getters.data.default_game_bg.data[0].attributes.url;
    }
  }

  return url;
});
const getGameUrl = computed(() => {
  const proxyUrls =
    gameData.value.game_provider?.data?.attributes?.proxy_urls?.data || [];

  const proxyUrl = proxyUrls.find(
    (proxy) => proxy?.attributes?.country === store.getters.geodata.country
  )?.attributes?.url;

  return proxyUrl
    ? url.value.replace(/https:\/\/(.*?)(?=\/)/g, proxyUrl)
    : url.value;
});

const api = inject("api");
const fetchGame = async () => {
  isLoading.value = true;
  let query = qs.stringify({
    filters: {
      game_id: gameID.value,
    },
    populate: {
      background_image: {
        populate: "*",
      },
      game_categories: {
        populate: "*",
      },
      game_provider: {
        populate: "*",
      },
      game_ribbon: {
        populate: "*",
      },
      game_themes: {
        populate: "*",
      },
      thumbnail: {
        populate: "*",
      },
    },
  });
  await api
    .get(`/games?${query}`)
    .then((r) => {
      gameData.value = r.data.data[0].attributes;
      openGame();
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      if (!window.innerWidth >= 768) {
        isLoading.value = false;
      }
    });
};

const toggleFavourite = async () => {
  const payload = {
    gameId: gameID.value,
    assignFlag: !favourited.value,
  };
  store.dispatch("favouriteGame", payload);
};

//Resize iframe
let resizeIframe = () => {
  let fr = document.getElementById("gameFrame");
  let wh = window.innerHeight;
  if (isLandscape.value == false) {
    let frameHight = wh - 70;
    if (fr) {
      fr.style.height = frameHight + "px";
      console.log("frameHeight = " + frameHight);
    }
  } else if (fr) {
    fr.style.height = wh + "px";
  }
};

const openGame = async () => {
  isLoading.value = true;
  await api
    .post("/crm/startGame", {
      sessionKey: sessionKey.value,
      gameID: gameID.value,
      lang: store.getters.currentLang || "EN",
      isMobile: isMobile.value,
    })
    .then((r) => {
      if (r.data.GameStartGameResult && r.data.GameStartGameResult.IsSuccess) {
        var gameURL = r.data.GameStartGameResult.GameURL;
        // if (window.innerWidth >= 768) {
        url.value = gameURL; //r.data.GameStartGameResult.GameURL;
        // } else {
        // window.location.href = gameURL; //r.data.GameStartGameResult.GameURL;
        // }
      } else {
        alert("Error opening game");
      }
    })
    .catch((e) => {
      const errorCode = e.response.status;
      if (errorCode === 404) {
        toast.error("Game was not found");
      }
      closeGame();
    });
  isLoading.value = false;
};

const closeGame = () => {
  try {
    exitFullscreen();
  } catch (e) {
    console.log("wasn't in fullscreen");
  }
  document.body.classList.remove("game-opened");
  show.value = false;
  gameData.value = {};
};

const enterFullscreen = () => {
  let elem = document.getElementById("game-single");
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
  isFullscreen.value = true;
};

const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE/Edge */
    document.msExitFullscreen();
  }
  isFullscreen.value = false;
};

const toggleFullscreen = () => {
  if (isFullscreen.value == true) {
    exitFullscreen();
  } else {
    enterFullscreen();
  }
};

const clock = () => {
  let date = new Date();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours % 24;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes;
  time.value = strTime;
};

const openCashier = () => {
  cashier.open("DEPOSIT");
};

onMounted(() => {
  setInterval(clock, 1000);
  favouriteGames.value = store.state.user.user.favourited_games;

  window.addEventListener("openGame", (e) => {
    gameID.value = e.detail.gameID;
    show.value = true;
    //remove scroll from body
    document.body.classList.add("game-opened");
    fetchGame();
  });

  //Check orientation
  let checkOrientation = () => {
    if (window.innerWidth > window.innerHeight) {
      isLandscape.value = true;
    } else {
      isLandscape.value = false;
    }
  };
  //On load
  checkOrientation();

  //on orientation change
  window.addEventListener("resize", () => {
    checkOrientation();
    resizeIframe();
  });

  window.onresize = function () {
    resizeIframe();
  };
});
</script>
<template>
  <div
    class="game-single"
    id="game-single"
    :class="{ show: show, mobile: isSinglePage }"
  >
    <div class="loader" v-if="isLoading || isSinglePage">
      <loading-spinner-big />
    </div>
    <div
      class="scene"
      :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
      v-if="!isLoading && gameData.game_id && !isSinglePage"
    >
      <div class="scene__header d-flex align-items-center">
        <div class="col-3">
          <div class="logo">
            <img src="@/assets/img/logo-line.svg" alt="" />
          </div>
        </div>
        <div class="col-6">
          <div class="game-title">
            {{ gameData.Name }}
          </div>
        </div>
        <div class="col-3 text-end d-none d-md-block">
          <a role="button" class="btn btn-primary me-3" @click="openCashier">
            {{ $t("game_single.header.button_deposit") }}
          </a>
          <a href="#" @click.prevent="closeGame" class="game-close me-2 p-2">
            <icon :variant="'close'" />
          </a>
        </div>
      </div>
      <div class="scene__game" @resize="resizeIframe">
        <div class="container">
          <div class="scene__controls d-none d-md-block">
            <a href="#" @click.prevent="toggleFullscreen">
              <icon v-if="!isFullscreen" :variant="'enter-fullscreen'" />
              <icon v-if="isFullscreen" :variant="'exit-fullscreen'" />
            </a>
            <a href="#" @click.prevent="toggleFavourite">
              <icon v-if="!favourited" :variant="'star'" />
              <icon v-if="favourited" :variant="'star-filled'" />
            </a>
          </div>
          <iframe
            :class="{ landscape: isLandscape }"
            class="w-100"
            style="height: 100%"
            v-if="getGameUrl.length > 2"
            :src="getGameUrl"
            frameborder="0"
            id="gameFrame"
            @load="resizeIframe"
          ></iframe>
        </div>
      </div>
      <div class="clock">
        {{ time }}
      </div>
      <div
        v-if="isMobile"
        :class="{ landscape: isLandscape }"
        class="game-single__mobile-footer d-flex justify-content-between align-items-center px-2"
      >
        <div class="f-controls">
          <a href="#" @click.prevent="closeGame" class="game-close me-2 p-2">
            <icon :variant="'home'" />
          </a>
          <a href="#" @click.prevent="toggleFavourite">
            <icon v-if="!favourited" :variant="'star'" />
            <icon v-if="favourited" :variant="'star-filled'" />
          </a>
        </div>
        <div class="f-deposit">
          <a role="button" class="btn btn-primary me-3" @click="openCashier">
            {{ $t("game_single.header.button_deposit") }}
          </a>
        </div>
        <div class="f-clock">{{ time }}</div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.game-single {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(#102b3c, 0.8);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  color: #fff;
  overflow-y: scroll;
  &__mobile-footer {
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #5a0505;
    z-index: 20;
    &.landscape {
      display: none !important;
    }
    .f-deposit {
      .btn {
        height: 36px;
        width: 160px;
      }
    }
    .f-clock {
      display: inline-flex;
      align-items: center;
      height: 36px;
    }
    .f-controls {
      display: flex;
      align-items: center;
      a {
        background: rgba(#fff, 0.03);
        display: inline-flex;
        border-radius: 8px;
        height: 36px;
        width: 36px;
        align-items: center;
        justify-content: center;
      }
      svg {
        fill: #fff;
      }
    }
  }
  &.mobile {
    background: rgba(#102b3c, 1);
  }
  &.show {
    z-index: 1000;
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
  }
  .scene {
    min-height: 100%;
    background: #102b3c;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    .clock {
      position: absolute;
      padding: 4px 12px;
      border-radius: 12px;
      display: inline-block;
      bottom: 8px;
      left: 10px;
      background: #102b3c;
    }
    &__controls {
      position: absolute;
      right: 0;
      width: 72px;
      display: flex;
      flex-direction: column;
      padding-left: 4px;
      a {
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 4px;
        transition: 0.3s;
        &:hover {
          background: #e6e6e6;
          transition: 0.3s;
        }
        svg {
          width: 22px;
          height: 22px;
          fill: #102b3c;
        }
      }
    }
    &__game {
      .container {
        padding: 20px 100px;
        max-width: 85%;
        position: relative;
      }
      iframe {
        aspect-ratio: 16/9;
        background-color: #102b3c;
        //min-height: calc(100vh - 280px);
        @media (max-width: 991px) {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          height: 400px;
          //bottom: 70px;
          //min-height: calc(100vh - 70px);
          aspect-ratio: initial;
          &.landscape {
            min-height: 100vh;
            bottom: 0;
          }
        }
      }
    }
    &__header {
      height: 70px;
      background: rgba(#000, 0.4);
      padding: 6px 12px;
      .logo {
        img {
          max-height: 72px;
          max-width: 100%;
        }
      }
      .game-title {
        color: #fff;
        text-align: center;
        font-size: 24px;
      }
      .game-close {
        svg {
          width: 22px;
          height: 22px;
          fill: #fff;
        }
      }
    }
  }
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
