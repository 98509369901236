<script setup>
import { computed, inject, ref } from "vue";
import { useStore } from "vuex";
import { getBonusPositionAbbr } from "../../helpers/bonus";
import { marked } from "marked";

// Declarations
const bonusStore = useStore();
const $t = inject("translations");

// Data
const isTermsExpanded = ref(false);
const termsEl = ref(null);

// Computed
const getBonusDetails = computed(
  () => bonusStore.getters.bonusDetailsModal.bonus
);
const getComputedBonusPositionAbbr = computed(
  () =>
    `${getBonusDetails.value.deposit_count}${getBonusPositionAbbr(
      getBonusDetails.value.deposit_count,
      {
        first: $t("bonus.positions.first"),
        second: $t("bonus.positions.second"),
        third: $t("bonus.positions.third"),
        default: $t("bonus.positions.default"),
      }
    )}`
);
const getBonusRules = computed(() => bonusStore.getters.bonusRules);
const getBonusRule = computed(() =>
  getBonusRules.value.find(
    (rule) => +rule.BaseRule.BonusRuleID === getBonusDetails.value.bonus_id
  )
);
const getWager = computed(() =>
  getBonusRule.value?.BaseRule.WagerRequirementMultiplier.includes(".")
    ? getBonusRule.value?.BaseRule.WagerRequirementMultiplier.split(".")[0]
    : getBonusRule.value?.BaseRule.WagerRequirementMultiplier
);
const getMinDeposit = computed(() => getBonusRule.value?.BonusMinDepositAmount);
const getExpiryDate = computed(() =>
  getBonusRule.value?.BaseRule?.ExpiryDate?.split(" ")[0].replaceAll("-", "/")
);
const getTermsMaxHeightStyle = computed(() => ({
  maxHeight: isTermsExpanded.value ? `${termsEl.value.clientHeight}px` : 0,
}));
const getTermsAndConditions = computed(() =>
  marked(getBonusDetails.value.terms_and_conditions || "")
);

// Methods
const closeBonusDetailsModal = () => {
  bonusStore.commit("setBonusDetailsModal", {
    isVisible: false,
    bonus: {},
  });
};
</script>
<template>
  <div
    class="bonus-modal position-fixed vh-100 vw-100 top-0 left-0 d-flex align-items-center justify-content-center"
  >
    <div class="bonus-modal__content">
      <div class="bonus-modal__container position-relative">
        <a role="button" @click="closeBonusDetailsModal">
          <icon
            variant="bonus-modal-close"
            class="bonus-modal__close position-absolute top-0"
          />
        </a>
        <div class="bonus-modal__header d-flex align-items-center">
          <h1 class="bonus-modal__title">
            {{ getComputedBonusPositionAbbr }}
            {{ $t("bonus.details.label_deposit") }}
          </h1>
          <p class="bonus-modal__rate m-0 ms-3">
            {{ $t("bonus.details.label_welcome_bonus") }}
            {{ getBonusDetails.deposit_count }} /
            {{ bonusStore.getters.promotion.attributes.bonuses.data.length }}
          </p>
        </div>
        <h3 class="bonus-modal__code" v-if="getBonusDetails.bonus_code">
          {{ $t("bonus.details.label_coupon_code") }}:
          {{ getBonusDetails.bonus_code }}
        </h3>
        <h2 class="bonus-modal__upto">
          {{ `${getBonusDetails.bonus_percent} ${getBonusDetails.bonus_upto}` }}
        </h2>
        <div class="bonus-modal__details">
          <p class="m-0">
            {{ $t("bonus.details.label_minimum_deposit") }}: {{ getMinDeposit }}
          </p>
          <p class="m-0">
            {{ $t("bonus.details.label_wager") }}: x{{ getWager }}
            {{ $t("bonus.details.label_bonus") }}
          </p>
        </div>
        <template v-if="getBonusDetails.terms_and_conditions">
          <a
            class="bonus-modal__terms-toggler d-inline-block"
            role="button"
            @click="isTermsExpanded = !isTermsExpanded"
            >{{ $t("bonus.details.label_terms_and_conditions") }}</a
          >
          <div
            class="bonus-modal__terms overflow-hidden"
            :style="getTermsMaxHeightStyle"
          >
            <div
              class="bonus-modal__terms-content overflow-auto"
              ref="termsEl"
              v-html="getTermsAndConditions"
            ></div>
          </div>
        </template>
        <div
          class="bonus-modal__footer d-flex align-items-center justify-content-between"
        >
          <p class="bonus-modal__expiry m-0">
            {{ $t("bonus.details.label_expiry_date") }}: {{ getExpiryDate }}
          </p>
          <a
            role="button"
            class="bonus-modal__button btn-outline-primary-new"
            @click="closeBonusDetailsModal"
            >{{ $t("bonus.details.button_close") }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.bonus-modal {
  background: rgba(5, 4, 26, 0.92);
  z-index: 100;
  &__header {
    @include tablet {
      flex-direction: column;
      align-items: flex-start !important;
      margin-bottom: 20px;
    }
  }
  &__content {
    width: 567.04px;
    max-width: 100%;
    padding: 35.56px 48.62px 59.53px 56.27px;
    margin: 0 20px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.03) 100%
    );
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(32.2418px);
    border-radius: 20px;
    @include mobile {
      padding: 35.56px 28.62px 29.53px 26.27px;
    }
  }
  &__close {
    right: 0;
  }
  &__title {
    font-size: 35px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 9.54px;
    @include mobile-sm {
      font-size: 28px;
    }
  }
  &__rate {
    background: #851bc3;
    padding: 2px 7px;
    border-radius: 20px;
    @include tablet {
      margin: 0 !important;
      font-size: 14px;
    }
  }
  &__code {
    font-weight: 500;
    font-size: 22px;
    line-height: 25px;
    color: var(--app-yellow);
    margin-bottom: 30.55px;
    @include mobile-sm {
      font-size: 18px;
      margin-bottom: 20.55px;
    }
  }
  &__upto {
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 5.5px;
    @include mobile-sm {
      font-size: 18px;
      margin-bottom: 0;
      line-height: 20px;
    }
  }
  &__footer {
    margin-top: 50px;
    @include mobile {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
  &__details {
    font-size: 22px;
  }
  &__expiry {
    font-size: 16px;
  }
  &__details,
  &__expiry {
    font-weight: 400;
    line-height: 25.19px;
    margin-top: 5.56px;
    color: #fff;
    &-for {
      font-weight: 500;
      @include mobile-sm {
        font-size: 20px;
      }
    }
    @include mobile {
      margin-bottom: 15px !important;
    }
  }
  &__details {
    @include mobile-sm {
      font-size: 18px;
      line-height: 20px;
    }
  }
  &__title,
  &__rate,
  &__upto,
  &__terms-toggler,
  &__terms {
    color: #fff;
  }
  &__terms-toggler {
    margin: 35px 0 10px 0;
  }
  &__terms {
    transition: max-height 0.3s ease-in-out;
    &-content {
      max-height: 200px;
      font-size: 14px;
    }
  }
}
</style>
