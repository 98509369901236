<script setup>
import { defineEmits, defineProps } from "vue";

// Emits
defineEmits(["forfeitBonus"]);

// Props
defineProps({
  bonus: {
    required: true,
    type: Object,
    default: () => ({}),
  },
});

// Methods
const formatDate = (timestamp) => {
  let date = new Date(timestamp);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = "0" + date.getMinutes();
  let seconds = "0" + date.getSeconds();
  let formattedTime =
    day +
    "/" +
    month +
    "/" +
    year +
    " " +
    hours +
    ":" +
    minutes.substr(-2) +
    ":" +
    seconds.substr(-2);
  return formattedTime;
};
</script>
<template>
  <div class="active-bonus">
    <div class="name">
      <div class="label">
        <h4 class="mb-0">
          {{ bonus.BonusRule.BaseRule.Name }}
        </h4>
      </div>
    </div>
    <div class="amount d-flex mb-3">
      <div class="value">
        Amount<strong class="ms-2">
          {{ parseFloat(bonus.BonusAmountGiven).toFixed(2) }}
          {{ bonus.BonusRule.CurrencyCode }}
        </strong>
      </div>
    </div>
    <div class="wager pt-2 mb-4">
      <div class="range-block w-75 position-relative mx-auto">
        <div class="range my-2">
          <div
            class="range-fill"
            :style="{
              width:
                parseFloat(
                  bonus.BonusWagerRequirementAchievedPercentage
                ).toFixed(2) + '%',
            }"
          ></div>
        </div>
        <div class="range-amount ps-3">
          {{ parseFloat(bonus.BonusWagerRequirement).toFixed(0) }}
        </div>
      </div>

      <div class="summary text-center mb-3 p1-2">
        Wager:
        <strong
          >{{
            parseFloat(bonus.BonusWagerRequirementAchievedPercentage).toFixed(2)
          }}
          %</strong
        >
      </div>
    </div>
    <div class="d-flex pb-2">
      <div class="given">
        <div class="label">Given</div>
        <div class="value">{{ formatDate(bonus.GivenDate) }}</div>
      </div>
      <div class="expire ms-auto">
        <div class="label">Expire</div>
        <div class="value">{{ formatDate(bonus.ExpiryDate) }}</div>
      </div>
    </div>
    <div class="text-end pb-3 pt-2">
      <button
        @click.prevent="$emit('forfeitBonus', bonus)"
        class="btn btn-outline-primary btm-sm"
      >
        Forfeit bonus
      </button>
    </div>
  </div>
</template>
<style scoped lang="scss">
.active-bonus {
  color: #fff;
  border: 1px solid rgba(#fff, 0.1);
  background: rgba(#fff, 0.1);
  margin-bottom: 12px;
  border-radius: 12px;
  padding: 20px !important;
  padding-bottom: 0px !important;
  .btn {
    height: 32px;
    font-size: 13px;
    padding: 4px 8px;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .range-amount {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 13px;
  }
  .label {
    font-size: 11px;
    font-weight: 600;
    opacity: 0.7;
  }
  .value {
    font-weight: 400;
    font-size: 14px;
  }
  .range {
    height: 8px;
    background: rgba(#fff, 0.1);
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    &-fill {
      max-width: 100%;
      min-width: 1%;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 3px;
    }
  }
}
</style>
