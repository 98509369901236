import store from "../store/index";

export const setZohochatDetails = () => {
  window.$zoho.salesiq.ready = () => {
    const user = store.getters.user;
    const userFirstName = user.first_name || "";
    const userLastName = user.last_name || "";
    const name = `${userFirstName} ${userLastName}`;
    const email = user.email || "";
    const phone = user.phone || "";
    const username = user.username || "";
    const clientId = user.crm_client_id || "";

    window.$zoho.salesiq.visitor.name(name);
    window.$zoho.salesiq.visitor.email(email);
    window.$zoho.salesiq.visitor.contactnumber(phone);
    window.$zoho.salesiq.visitor.id(clientId);
    window.$zoho.salesiq.visitor.info({ username });
  };
};
