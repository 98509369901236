<script setup>
import {
  defineProps,
  ref,
  computed,
  onMounted,
  onBeforeMount,
  inject,
} from "vue";
import { useStore } from "vuex";
import qs from "qs";
import gameTile from "@/components/game-tile.vue";

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Pagination, Autoplay, Controller, FreeMode, Lazy } from "swiper";

import "swiper/swiper.scss";

let modules = [Pagination, Autoplay, Controller, FreeMode, Lazy];
let breakpoints = ref({
  300: {
    slidesPerView: 3,
    spaceBetween: 12,
  },
  // when window width is >= 480px
  480: {
    slidesPerView: 3,
    spaceBetween: 25,
  },
  // when window width is >= 640px
  640: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 5,
  },
  1024: {
    slidesPerView: 7,
  },
  // 1366: {
  //   slidesPerView: 7,
  // },
  // 1440: {
  //   slidesPerView: 8,
  // },
});

const store = useStore();
let games = ref([]);
let gamesLoaded = ref(false);
let isLoading = ref(false);
let controlledSwiper = ref(null);

const props = defineProps({
  category: {
    type: Object,
    required: true,
    default: () => ({
      id: null,
      attributes: {},
    }),
  },
});

let isMobile = computed(() => window.innerWidth < 768);

let tabName = (category) => {
  let name = category.name;
  let currentLang = store.state.global.currentLang || "en";
  let found = category.localized_name.find((el) => {
    return el.key == "name_" + currentLang;
  });
  if (currentLang != "en" && found) {
    // if current language is not english and found localized name
    name = found.value;
  }
  return name;
};

const api = inject("api");
const fetchGameByIdCategory = async (category_id) => {
  //let category_name = "Video Slots";
  let gamesQuery = {
    filters: {
      game_categories: {
        id: {
          $eq: category_id,
        },
      },
      game_provider: {
        is_active: {
          $eq: true,
        },
      },
    },
    populate: {
      thumbnail: "*",
      game_ribbon: "*",
    },
    pagination: {
      page: 0,
      pageSize: 10,
    },
  };
  if (isMobile.value) {
    gamesQuery.filters.is_mobile = true;
  } else {
    gamesQuery.filters.is_desktop = true;
  }
  gamesQuery = qs.stringify(gamesQuery, {
    encodeValuesOnly: true, // prettify URL
  });
  await api
    .get("/games/getGamesWithCategoryOrder?" + gamesQuery)
    .then((response) => {
      isLoading.value = true;
      games.value = response.data.data;
      gamesLoaded.value = true;
      isLoading.value = false;
    })
    .catch((error) => {
      console.log(error);
      isLoading.value = false;
    });
};

const slideMove = (direction) => {
  if (direction == "next") {
    controlledSwiper.value.slideNext();
  } else {
    controlledSwiper.value.slidePrev();
  }
};

let category = computed(() => {
  return props.category ? props.category : {};
});

const setControlledSwiper = (swiper) => {
  controlledSwiper.value = swiper;
};
onBeforeMount(() => {
  for (var i = 0; i < 9; i++) {
    games.value.push({
      attributes: {
        createdAt: 0,
        updatedAt: 0,
      },
    });
  }
});
onMounted(() => {
  if (category.value.id) {
    fetchGameByIdCategory(category.value.id);
  }
});
</script>
<template>
  <div class="sliding-games__row">
    <div>
      <div class="container px-0 mt-1">
        <div
          class="sliding-games__row_name mx-0 d-flex justify-content-between align-items-center"
        >
          {{ tabName(category) }}
          <router-link
            class="viewAll"
            :to="{
              name: 'games',
              params: { slug: category.game_pages[0].slug },
              query: { category: category.id },
            }"
          >
            {{ $t("pages.homepage.link_view_all") }}
            <icon variant="arrow-right" />
          </router-link>
        </div>
      </div>
      <div class="row" :key="games.length">
        <div class="col-12 p-0">
          <div class="sliding-games__slider">
            <div class="prev pb-5">
              <div class="circle" @click="slideMove('prev')">
                <icon variant="arrow-right" />
              </div>
            </div>
            <Swiper
              :modules="modules"
              :speed="1200"
              :loop="true"
              :autoplay="{ delay: 5000 }"
              effect="slide"
              :space-between="20"
              :freeMode="true"
              :breakpoints="breakpoints"
              :controller="{ control: controlledSwiper }"
              @swiper="setControlledSwiper"
            >
              <SwiperSlide v-for="g in games" :key="g.id">
                <div class="pb-5 pt-2">
                  <gameTile :game="g" />
                </div>
              </SwiperSlide>
            </Swiper>
            <div class="next pb-5">
              <div class="circle" @click="slideMove('next')">
                <icon variant="arrow-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.sliding-games {
  &__slider {
    position: relative;
    .prev,
    .next {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.next {
        left: initial;
      }
      &.prev {
        svg {
          transform: scaleX(-1);
        }
      }
      right: 0;
      z-index: 20;
      .circle {
        transition: 0.5s ease-in-out;
        opacity: 0.7;
        &:hover {
          transform: scale(1.1);
          transition: 0.5s ease-in-out;
          opacity: 1;
        }
        cursor: pointer;
        width: 40px;
        height: 40px;
        min-width: 40px;
        background: rgb(250, 241, 168);
        background: radial-gradient(
          circle,
          rgba(250, 241, 168, 1) 0%,
          rgba(144, 108, 57, 1) 100%
        );
        border-radius: 50%;
        box-shadow: 0px 0px 30px 0px rgba(#000, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #2a0303;
          stroke: #2a0303;
        }
      }
    }
  }
  &__row {
    &_name {
      font-weight: 800;
      font-size: 20px;
      line-height: 1.3;
      background: -webkit-linear-gradient(
        rgba(250, 241, 168, 1),
        rgba(144, 108, 57, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin-bottom: 12px;
      .viewAll {
        font-style: italic;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.3;
        color: var(--app-yellow);
        margin-right: 8px;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        svg {
          margin-left: 10px;
          fill: var(--app-yellow);
          height: 12px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.sliding-games__row .container {
  max-width: 100% !important;
}
.swiper-pagination-progressbar {
  top: auto !important;
  height: 4px !important;
  bottom: 0px;
}
.swiper-pagination-progressbar-fill {
  background: -webkit-linear-gradient(
    rgba(250, 241, 168, 1),
    rgba(144, 108, 57, 1)
  ) !important;
}
</style>
