import api from "../../providers/api";
import qs from "qs";

export default {
  state: {
    promotions: [],
    promotion: {},
  },
  getters: {
    promotions: (state) => state.promotions,
    promotion: (state) => state.promotion,
  },
  mutations: {
    setPromotions: (state, data) => {
      state.promotions = data;
    },
    setPromotion: (state, data) => {
      state.promotion = data;
    },
  },
  actions: {
    async fetchPromotions({ commit }) {
      try {
        let query = {
          populate: "*",
          sort: {
            order: "asc",
          },
        };
        query = qs.stringify(query);
        const { data } = await api.get(`/promotions?${query}`);
        commit("setPromotions", data.data);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchPromotion({ commit }, query) {
      try {
        const { data } = await api.get(`/promotions?${query}`);
        commit("setPromotion", data.data[0]);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
