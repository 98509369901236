<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";

import { defineProps } from "vue";
import { toggleAuthModal } from "../helpers/modals";
//eslint-disable-next-line

const store = useStore();
// const router = useRouter();

const gameData = computed(() => {
  if (props.game) {
    if (Object.keys(props.game).length > 2) {
      return props.game;
    }
    if (props.game.attributes) {
      return props.game.attributes;
    }
  }
  return { Name: "", description: "" };
});
const getGames = computed(() => store.getters.games);

let isUser = computed(() => {
  return store.state.user.user.id;
});

let favourited = computed(() => {
  if (!isUser.value) {
    return false;
  } else {
    return store.state.user.user.favourited_games
      ? store.state.user.user.favourited_games.includes(gameData.value.game_id)
      : false;
  }
});
const getDefaultGameBg = computed(
  () => store.getters.data?.default_game_tile_bg
);

const toggleFavourite = async () => {
  if (!store.state.auth.jwt) {
    if (store.getters.hasBeenLoggedBefore) {
      toggleAuthModal({ show: true, useCase: "login" });
      return;
    }
    toggleAuthModal({ show: true, useCase: "registration" });
    return;
  }
  const payload = {
    gameId: gameData.value.game_id,
    assignFlag: !favourited.value,
  };
  try {
    await store.dispatch("favouriteGame", payload);
    store.commit("setGames", {
      data: getGames.value.data.filter(
        (game) => game.game_id !== gameData.value.game_id
      ),
    });
  } catch (err) {
    console.error(err);
  }
};

const ribbon = computed(() => {
  let ribbon = {};
  if (
    props.game?.attributes?.game_ribbon &&
    props.game?.attributes?.game_ribbon.data
  ) {
    ribbon = {
      id: props.game?.attributes?.game_ribbon.data.id,
      ...props.game?.attributes?.game_ribbon.data.attributes,
    };
  } else if (gameData.value.game_ribbon && gameData.value.game_ribbon.id) {
    ribbon = gameData.value.game_ribbon;
  }
  return ribbon;
});

const thumbnail = computed(() => {
  if (props.game?.thumbnail?.url) {
    return props.game.thumbnail.url;
  }
  if (props.game?.attributes?.thumbnail?.data?.attributes?.url) {
    return props.game.attributes.thumbnail.data.attributes.url;
  }
  if (props.game?.attributes?.thumbnail?.url) {
    return props.game.attributes.thumbnail.url;
  }

  return getDefaultGameBg.value?.url || "";
});

const gameID = computed(() => {
  let id = 0;
  if (props.game?.attributes) {
    id = props.game.attributes.game_id;
  }
  return id;
});

const props = defineProps({
  game: {
    type: Object,
    required: true,
    default: () => ({
      id: null,
      attributes: {},
    }),
  },
  index: {
    type: Number,
  },
  isTopGame: {
    required: false,
    type: Boolean,
    default: false,
  },
});

const openGame = () => {
  if (store.state.user.user.id) {
    if (window.innerWidth < 768) {
      window.scrollTo(0, window.scrollY + 1);
    }
    // if (window.innerWidth > 768) {
    // open game for desktop
    let event = new CustomEvent("openGame", {
      detail: {
        gameID: gameID.value,
      },
    });
    window.dispatchEvent(event);
    // } else {
    //   let routeData = router.resolve({
    //     name: "game-single",
    //     params: { id: gameID.value },
    //   });
    //   window.open(routeData.href, "_blank");
    // }
  } else {
    if (store.getters.hasBeenLoggedBefore) {
      toggleAuthModal({ show: true, useCase: "login" });
      return;
    }
    toggleAuthModal({ show: true, useCase: "registration" });
  }
};

const getThumbnail = () => {
  let size = 200;

  if (window.innerWidth < 574) {
    size = 250;
  }
  if (window.innerWidth >= 574 && window.innerWidth < 1025) {
    if (props.index % 7 === 0) {
      size = 500;
    }
  }
  if (window.innerWidth >= 1025 && window.innerWidth < 1519) {
    if (props.index % 7 === 0) {
      size = 700;
    }
  }
  if (window.innerWidth >= 1519) {
    if (props.index % 9 === 0) {
      size = 700;
    }
  }
  if (props.isTopGame) {
    size = 400;
  }

  return thumbnail.value.replace("public", `w=${size}`);
};
</script>
<template>
  <div class="game-tile" :class="{ disabled: !gameData.is_active }">
    <a
      @click.prevent="openGame"
      href="#"
      :class="{ 'has-image': thumbnail.length > 1 }"
    >
      <div
        class="ribbon"
        :style="{ backgroundColor: '#' + ribbon.ColorRGB }"
        v-if="ribbon.id"
      >
        {{ ribbon.Title }}
      </div>
      <div class="image">
        <img :src="getThumbnail()" :alt="gameData.Name" class="w-100 h-100" />
      </div>
      <div class="info" v-if="gameData.Name">
        <div class="name">{{ gameData?.Name }}</div>
        <div class="category" v-if="gameData.description">
          {{ gameData.description }}
        </div>
      </div>
    </a>
    <icon
      class="star"
      @click="toggleFavourite"
      :variant="'star-filled'"
      v-if="favourited && isUser"
    />
    <icon
      class="star"
      @click="toggleFavourite"
      :variant="'star'"
      v-if="!favourited && isUser"
    />
  </div>
</template>

<style lang="scss" scoped>
.game-tile {
  position: relative;
  aspect-ratio: 1/1.15;
  &.disabled {
    filter: grayscale(1);
  }
  .star {
    position: absolute;
    bottom: 14px;
    right: 10px;
    cursor: pointer;
    fill: white;
    z-index: 14;
    width: 20px;
    height: 20px;
    @media (max-width: 768px) {
      bottom: initial;
      right: initial;
      top: 12px;
      left: 12px;
      background: rgba(#000, 0.2);
    }
  }
  a {
    background: rgba(0, 0, 0, 0.8);
    .ribbon {
      position: absolute;
      top: -6px;
      right: -6px;
      z-index: 10;
      padding: 4px 8px;
      font-size: 14px;
      font-weight: 600;
      color: white;
      border-radius: 0px 0px 0px 0px;
      box-shadow: 0px 4px 0px 0px rgba(#000, 0.2);
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    &:not(.has-image) {
      animation: animationGradient 6s linear infinite;
      background: linear-gradient(-90deg, #183a50, #164462, #183a50);
      background-size: 400% 400%;
    }
    position: relative;
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 15px 16px -5px rgba(0, 0, 0, 0.37);
    flex-direction: column;
    justify-content: flex-end;
    text-decoration: none;
    transition: 0.3s;
    height: 100%;
    max-width: 100%;
    min-width: 100%;
    &:hover {
      transition: 0.3s;
      box-shadow: 0px 15px 32px -5px rgba(0, 0, 0, 0.67);
      .info {
        .category {
          transition: 0.3s;
          opacity: 1;
        }
      }
    }
    .info {
      background: rgba(17, 43, 60, 0.76);
      backdrop-filter: blur(18px);
      border-radius: 0px 0px 10px 10px;
      position: relative;
      z-index: 5;
      padding: 9px 14px;
      padding-right: 40px;
      @media (max-width: 768px) {
        padding: 6px 8px;
      }

      .name {
        color: var(--app-yellow);
        font-weight: 700;
        font-size: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        @media (max-width: 768px) {
          font-size: 10px;
        }
      }
      .category {
        transition: 0.3s;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #f2f2f2;
        opacity: 0.6;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
    .image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      border-radius: 10px;
      overflow: hidden;

      .picture {
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        bottom: 35px;
        background: rgba(0, 0, 0, 0.5);
        @media (max-width: 768px) {
          bottom: 25px;
        }
      }
    }
  }
}
@keyframes animationGradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
</style>
