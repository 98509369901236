<script setup>
import { ref, computed, inject, watch, watchEffect, reactive } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

const toast = useToast();
const $t = inject("translations");
const store = useStore();
const minPasswordConstraint = 6;

const isButtonDisabled = ref(true);
const isLoading = ref(false);
let password = reactive({
  old_password: "",
  new_password: "",
  confirm_password: "",
});
let fields = ref([
  {
    name: "Old password",
    editable: true,
    type: "password",
    key: "old_password",
    error: "",
  },
  {
    name: "New password",
    editable: true,
    type: "password",
    key: "new_password",
    error: "",
  },
  {
    name: "Confirm new password",
    editable: true,
    type: "password",
    key: "confirm_password",
    error: "",
  },
]);

watch(password, ({ old_password, new_password, confirm_password }) => {
  if (old_password.length && old_password.length < minPasswordConstraint) {
    fields.value[
      fields.value.findIndex((field) => field.key === "old_password")
    ].error = $t("pages.user.my_account.message_old_password_min_length");
  } else {
    fields.value[
      fields.value.findIndex((field) => field.key === "old_password")
    ].error = "";
  }
  if (new_password.length && new_password.length < minPasswordConstraint) {
    fields.value[
      fields.value.findIndex((field) => field.key === "new_password")
    ].error = $t("pages.user.my_account.message_new_password_min_length");
  } else {
    fields.value[
      fields.value.findIndex((field) => field.key === "new_password")
    ].error = "";
  }
  if (confirm_password.length && new_password !== confirm_password) {
    fields.value[
      fields.value.findIndex((field) => field.key === "confirm_password")
    ].error = $t("pages.user.my_account.message_passwords_do_not_match");
  } else {
    fields.value[
      fields.value.findIndex((field) => field.key === "confirm_password")
    ].error = "";
  }
});

watchEffect(() => {
  if (
    password.old_password.length >= minPasswordConstraint &&
    password.new_password.length >= minPasswordConstraint &&
    password.confirm_password === password.new_password
  ) {
    isButtonDisabled.value = false;
  } else {
    isButtonDisabled.value = true;
  }
});

let passwordsMatch = computed(() => {
  return password.new_password === password.confirm_password;
});

const api = inject("api");
const changePassword = async () => {
  if (isButtonDisabled.value && isLoading.value) {
    return;
  }

  if (passwordsMatch.value) {
    let payload = {
      newPassword: password.new_password,
      oldPassword: password.old_password,
      sessionKey: store.state.user.user.crm_session_key,
    };
    try {
      isLoading.value = true;
      await api.post("/crm/updatePlayerAccountPassword", payload);
      toast.success($t("toaster.success.message_password_saved"), {
        timeout: 2000,
      });
      password.old_password = "";
      password.new_password = "";
      password.confirm_password = "";
    } catch (err) {
      const { crmStatus } = err.response?.data?.error?.details;
      const invalidOldPasswordStatusCode = 480;
      if (crmStatus === invalidOldPasswordStatusCode) {
        toast.error($t("toaster.error.message_wrong_old_password"), {
          timeout: 2000,
        });
      }
    }
  } else {
    toast.error($t("toaster.error.message_passwords_do_not_match"), {
      timeout: 2000,
    });
  }
  isLoading.value = false;
};
</script>
<template>
  <form class="change-password" @submit.prevent="changePassword">
    <div class="row justify-content-end">
      <div class="col-12" v-for="f in fields" :key="f.key">
        <div
          class="form-input d-flex align-items-center"
          :class="f.error ? 'pb-1' : 'pb-3'"
        >
          <label>
            {{ $t(`pages.user.my_account.label_${f.key}`) }}
          </label>
          <div class="position-relative">
            <input
              :type="f.type"
              :value="password[f.key]"
              :disabled="!f.editable"
              v-model="password[f.key]"
            />
            <p class="error-msg mb-0" v-if="f.error">
              {{ f.error }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-input pb-3 pt-3 d-flex align-items-center">
        <label> </label>
        <button
          type="submit"
          class="btn btn-primary btn-lg"
          :disabled="isButtonDisabled || isLoading"
        >
          {{ $t("pages.user.my_account.button_change_password") }}
        </button>
      </div>
    </div>
  </form>
</template>
<style scoped lang="scss">
.form-input {
  p {
    margin-top: 2px;
  }
}
</style>
