<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

const router = useRouter();

const toast = useToast();

let isLoading = ref(false);
let showPassword = ref(false);
let password = ref("");
let password_confirmation = ref("");
let code = ref("");
let username = ref("");

const valid = computed(() => {
  let valid = {
    password: true,
    password_confirmation: true,
  };
  if (password.value.length < 6) {
    valid.password = false;
  }
  if (password.value !== password_confirmation.value) {
    valid.password_confirmation = false;
  }
  return valid;
});

const api = inject("api");
async function resetPassword() {
  if (!valid.value.password || !valid.value.password_confirmation) {
    toast.error("Please check your password", {
      timeout: 2000,
    });
    return;
  }
  if (
    valid.value.password.length < 6 ||
    valid.value.password_confirmation.length < 6
  ) {
    toast.error("Password must be at least 6 characters", {
      timeout: 2000,
    });
    return;
  }
  if (valid.value.password !== valid.value.password_confirmation) {
    toast.error("Passwords do not match", {
      timeout: 2000,
    });
    return;
  }

  isLoading.value = true;
  await api
    .post("/crm/resetPassword", {
      newPassword: password.value,
      resetCode: code.value,
      username: username.value,
    })
    .then((response) => {
      if (response.data.success) {
        toast.success("New password saved", {
          timeout: 2000,
        });
        router.push({ name: "home" });
        let event = new CustomEvent("toggleAuthModal", {
          detail: {
            show: true,
            useCase: "login",
          },
        });
        window.dispatchEvent(event);
      }
    })
    .catch((error) => {
      return error.response;
    });

  isLoading.value = false;
}

onMounted(() => {
  code.value = router.currentRoute.value.query.code;
  username.value = router.currentRoute.value.query.username;
});
</script>

<template>
  <div class="reset-password">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-md-6 col-lg-4">
          <h2 class="mb-3">{{ $t("login_forgot_password_choose") }}</h2>
          <div class="form-input mb-2">
            <div class="icon">
              <icon :variant="'password'" />
            </div>
            <input
              class="w-100"
              v-model="password"
              placeholder="Enter your new password"
              :type="showPassword ? 'text' : 'password'"
            />
          </div>
          <div class="form-input mb-2">
            <div class="icon">
              <icon :variant="'password'" />
            </div>
            <input
              class="w-100"
              placeholder="Confirm your new password"
              v-model="password_confirmation"
              :type="showPassword ? 'text' : 'password'"
            />
          </div>
          <button
            :disabled="isLoading"
            class="btn btn-primary w-100 mt-3"
            @click="resetPassword"
          >
            {{ $t("login_forgot_password_save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.reset-password {
  padding-top: 120px;
  color: #fff;
  padding-bottom: 120px;
}
@import "@/scss/_mixins.scss";
@include fancy-form-input;
.cta {
  .btn {
    box-shadow: 0px 10px 25px -8px rgba(0, 0, 0, 0.26);
  }
}
</style>
