import store from "@/store";

const backToSiteEvent = (e) => {
  const key = e.message ? "message" : "data";
  const data = e[key];

  if (data === "back-to-site") {
    store.dispatch(
      "refreshBalance",
      store.getters.user.crm_session_key || 15000
    );
    cashier.close();
  }
};

const cashier = {
  init(element, token) {
    let cashier = window.PraxisCashier({
      auth_token: token,
      container: element,
      mode: "iframe",
      locale:
        store.getters.data.praxis_locale_mapping[store.getters.currentLang] ||
        "en-GB",
    });

    cashier.render();

    const cashierIframe = cashier.getCashierIframe();
    cashierIframe.style.width = "100%";
    cashierIframe.style.height = "100%";

    window.addEventListener("message", backToSiteEvent, false);
  },
  open(type, bonusRuleId) {
    if (type === "DEPOSIT") {
      window.$zoho.salesiq.visitor.customaction("Track-deposit-button");
    }
    store.commit(
      "setCashierModalTitle",
      type === "DEPOSIT" ? "Deposit" : "Withdraw"
    );
    store.commit("setShowCashierModal", true);
    store.dispatch("openCashier", {
      action: type === "DEPOSIT" ? "payment" : "withdrawal",
      bonusRuleId,
    });
  },
  async close() {
    window.removeEventListener("message", backToSiteEvent);
    store.commit("setCashierModalTitle", "");
    store.commit("setShowCashierModal", false);
    store.commit("setCashierUrl", "");
    if (Object.keys(store.getters.cashierBonus).length) {
      store.commit("setIsBonusesLoading", true);
      await store.dispatch("fetchActiveBonuses");
      await store.dispatch("getApplicableDepositBonuses");

      let isBonusRewarded,
        activeBonuses = store.getters.activeBonuses,
        activeCashierBonus = store.getters.cashierBonus;

      if (Array.isArray(activeBonuses)) {
        isBonusRewarded = store.getters.activeBonuses.some(
          (bonus) => store.getters.cashierBonus.bonus_id === +bonus.BonusRuleID
        );
      } else {
        isBonusRewarded =
          +activeBonuses.BonusRuleID === activeCashierBonus.bonus_id;
      }
      if (isBonusRewarded) {
        await store.dispatch("savePlayerAttributes", {
          attributeName: `bonus_rule_id_${activeCashierBonus.bonus_id}`,
          attributeValue: `rewarded`,
        });
      }

      store.commit("setCashierBonus", {});
      store.commit("setIsBonusesLoading", false);
    }
  },
};

export default cashier;
