<script setup>
import { ref, onMounted, computed } from "vue";
import homeBannerSlider from "./home-banner-slider.vue";
import homeSectionsSliders from "./home-sliding-sections.vue";
import homeProvider from "./home-provider.vue";
// import homeWinners from "./home-winners.vue";
import homeJackPots from "./home-jackpots.vue";
import homeDealers from "./home-dealers.vue";
import homeRandomGames from "./home-random-games.vue";
import { useStore } from "vuex";

const store = useStore();

let homeSettings = ref({});

const categoriesToShow = computed(
  () => homeSettings.value?.game_categories_to_show || []
);

const showDealers = computed(() => !!homeSettings.value?.show_dealers);

const showProviders = computed(() => !!homeSettings.value?.show_game_providers);

const showRandomGames = computed(
  () => !!homeSettings.value?.random_games?.length
);

let fetchHomeSettings = async () => {
  const settings = await store.dispatch("fetchHomepageData");
  homeSettings.value = settings;
};

onMounted(async () => {
  store.commit("setIsAppLoading", true);
  await fetchHomeSettings();
  store.commit("setIsAppLoading", false);
});
</script>

<template>
  <div>
    <homeBannerSlider />

    <homeSectionsSliders
      :key="categoriesToShow.length"
      :categories="categoriesToShow"
    />
    <homeRandomGames v-if="showRandomGames" />
    <div class="container p-0">
      <homeJackPots />
    </div>
    <homeProvider v-if="showProviders" />

    <homeDealers v-if="showDealers" />
  </div>
</template>
<style lang="scss"></style>
