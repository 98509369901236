<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  promotion: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

let p = computed(() => {
  let promotion = props.promotion.attributes ? props.promotion.attributes : {};
  promotion["id"] = props.promotion.id;
  return promotion;
});
let image = computed(() => {
  return p.value.image?.data ? p.value.image?.data.attributes.url : "";
});
const getPromotionLink = computed(() =>
  p.value.slug && p.value.bonuses?.data.length > 1
    ? { name: "bonus", params: { slug: p.value.slug } }
    : {
        name: "promotion-single",
        params: {
          id: p.value.id,
        },
      }
);
</script>

<template>
  <router-link
    :to="getPromotionLink"
    class="promotions__card"
    :style="{ backgroundImage: 'url(' + image + ')' }"
  >
    <div class="bg" style=""></div>
    <div class="text">
      <h3>
        {{ p.title }}
      </h3>
      <div class="excerpt">
        {{ p.excerpt }}
      </div>
    </div>
    <div class="image" :style="{ backgroundImage: 'url(' + image + ')' }"></div>
    <div class="arrow">
      <icon :variant="'dropdown'" color="#fff" />
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.promotions {
  &__card {
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 15px 24px -5px rgb(0 0 0 / 37%);
    background-color: rgb(17, 43, 60);
    color: #fff;
    text-decoration: none;
    display: flex;
    padding-bottom: 40px;
    background-size: cover;
    background-position: center top;
    position: relative;
    overflow: hidden;
    @media (max-width: 475px) {
      padding: 12px 20px;
    }
    .arrow {
      position: absolute;
      bottom: 15px;
      transform: rotate(-90deg);
      left: 3px;
      opacity: 0;
      transition: 0.3s;
      @media (max-width: 475px) {
        display: none;
      }
    }
    .bg {
      position: absolute;
      top: -1px;
      left: -1px;
      bottom: -1px;
      right: -1px;
      background-color: rgba(17, 43, 60, 0.9);
      backdrop-filter: blur(24px);
      z-index: 0;
      border-radius: 12px;
    }
    & > * {
      position: relative;
      z-index: 2;
    }
    h3 {
      font-weight: bold;
      font-size: 32px;
      color: var(--app-yellow) !important;
      margin-top: 40px;
      @media (max-width: 1024px) {
        font-size: 24px;
        margin-top: 20px;
      }
      @media (max-width: 475px) {
        font-size: 20px;
      }
    }
    &:hover {
      .excerpt {
        opacity: 1 !important;
        transition: 0.3s;
      }
      .arrow {
        opacity: 1 !important;
        transition: 0.3s;
        left: 18px;
      }
    }
    .text {
      width: 60%;
      padding-right: 20px;

      .excerpt {
        opacity: 0.6;
        transition: 0.3s;
        margin-bottom: 20px;
        @media (max-width: 1024px) {
          font-size: 18px;
        }
        @media (max-width: 475px) {
          font-size: 14px;
        }
      }
    }
    .image {
      width: 40%;
      background-size: cover;
      background-position: center top;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border-bottom-right-radius: 12px;
      border-top-right-radius: 12px;
    }
  }
}
</style>
