import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "@/store";

const app = createApp(App);

//Strapi API
import api from "@/providers/api";

app.config.globalProperties.$api = api;
app.provide("api", app.config.globalProperties.$api);

//Auth services
import auth from "@/providers/auth";
app.config.globalProperties.$auth = { ...auth };
app.provide("auth", app.config.globalProperties.$auth);

//Translations
import translations from "@/providers/translations";
app.use(translations);
app.provide("translations", app.config.globalProperties.$t);

//Icon component
import icon from "@/components/icons/icon-component.vue";
app.component("icon", icon);

//loading spinner
import spinner from "@/components/loading-spinner.vue";
app.component("spinner", spinner);

//Bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "@/scss/style.scss";

//Toast notifications
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
  // You can set your default options here
};
app.use(Toast, options);

import FlagIcon from "vue-flag-icon";
app.use(FlagIcon);

app.use(router);

app.use(store);

router.isReady().then(() => {
  app.mount("#app");
});
