<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import gamesFilterSelectionsItem from "./games-filter-selections-item.vue";

// Declarations
const store = useStore();

// Computed
const getSelectedProviders = computed(() => store.getters.selectedProviders);

const getSelectedThemes = computed(() => store.getters.selectedThemes);
</script>
<template>
  <div class="filter-selected">
    <h2 class="filter-selected__title">Active Filters</h2>
    <div
      class="filter-selected__list d-flex align-items-center flex-wrap"
      v-if="getSelectedProviders.length"
    >
      <div class="filter-selected__list-title">Game Provider:</div>
      <games-filter-selections-item
        v-for="filter in getSelectedProviders"
        :key="filter.id"
        :filter="filter"
      />
    </div>
    <div
      class="filter-selected__list d-flex align-items-center flex-wrap"
      v-if="getSelectedThemes.length"
    >
      <div class="filter-selected__list-title">Game Theme:</div>
      <games-filter-selections-item
        v-for="filter in getSelectedThemes"
        :key="filter.id"
        :filter="filter"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.filter-selected {
  padding: 8px;
  @media (min-width: 1024px) {
    padding: 8px 22px;
  }
  &__title {
    font-size: 12px;
    font-weight: 700;
  }
  &__list-title {
    font-size: 12px;
  }
}
</style>
