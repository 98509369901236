<script setup>
import { useStore } from "vuex";
import kycStep from "./kyc/kyc-step.vue";
import { computed, inject } from "vue";

// Declarations
const store = useStore();
const $t = inject("translations");

// Computed
const getKyc = computed(() => store.getters.kyc?.attributes);
const getKycDocuments = computed(() => store.getters.kycDocuments);
const getIsKycVerified = computed(
  () =>
    store.getters.playerDetails?.PlayerGetDetailsResult?.IsKYCChecked === "true"
);
const getKycVerifiedText = computed(() =>
  getIsKycVerified.value
    ? $t("pages.user.kyc.status_verified")
    : $t("pages.user.kyc.status_not_verified")
);

// Methods
const fetchKyc = async () => {
  await store.dispatch("getUserKycDocuments");
  await store.dispatch("findKyc");
  await store.dispatch("fetchPlayerDetails");
};

// Created
fetchKyc();
</script>
<template>
  <div class="my-kyc">
    <div
      class="my-kyc__status d-flex align-items-center position-absolute"
      :class="{ 'my-kyc__status--verified': getIsKycVerified }"
    >
      <h3 class="m-0">{{ $t("pages.user.kyc.label_account_status") }}</h3>
      <h3 class="my-kyc__status-value">{{ getKycVerifiedText }}</h3>
      <icon :variant="getIsKycVerified ? 'verified' : 'not-verified'" />
    </div>
    <div class="my-kyc__text">
      <p class="mb-0">
        {{ $t("pages.user.kyc.text_first") }}
      </p>
      <p>
        {{ $t("pages.user.kyc.text_second") }}
      </p>
      <p>
        {{ $t("pages.user.kyc.text_third") }}
      </p>
    </div>

    <ul class="my-kyc__steps">
      <kyc-step
        kyc-key="proof_of_id"
        :files="getKycDocuments[0]?.proof_of_id || []"
        :status="getKyc?.proof_of_id_status || ''"
      >
        <template #position>1</template>
        <template #title>{{ $t("pages.user.kyc.steps.first.title") }}</template>
        <template #status>{{ getKyc?.proof_of_id_status }}</template>
        <template #header-text>{{
          $t("pages.user.kyc.steps.first.title_sub")
        }}</template>
        <template #list>
          <li>{{ $t("pages.user.kyc.steps.first.item_first") }}</li>
          <li>{{ $t("pages.user.kyc.steps.first.item_second") }}</li>
          <li>{{ $t("pages.user.kyc.steps.first.item_third") }}</li>
        </template>
        <template #footer-text>{{
          $t("pages.user.kyc.steps.first.title_footer")
        }}</template>
      </kyc-step>
      <kyc-step
        kyc-key="proof_of_address"
        :files="getKycDocuments[1]?.proof_of_address || []"
        :status="getKyc?.proof_of_address_status || ''"
      >
        <template #position>2</template>
        <template #title>{{
          $t("pages.user.kyc.steps.second.title")
        }}</template>
        <template #status>{{ getKyc?.proof_of_address_status }}</template>
        <template #header-text>{{
          $t("pages.user.kyc.steps.second.title_sub")
        }}</template>
        <template #list>
          <li>{{ $t("pages.user.kyc.steps.second.item_first") }}</li>
          <li>{{ $t("pages.user.kyc.steps.second.item_second") }}</li>
          <li>{{ $t("pages.user.kyc.steps.second.item_third") }}</li>
        </template>
        <template #footer-text>{{
          $t("pages.user.kyc.steps.second.title_footer")
        }}</template>
      </kyc-step>
      <kyc-step
        kyc-key="proof_of_payment"
        :files="getKycDocuments[2]?.proof_of_payment || []"
        :status="getKyc?.proof_of_payment_status || ''"
      >
        <template #position>3</template>
        <template #title>{{ $t("pages.user.kyc.steps.third.title") }}</template>
        <template #status>{{ getKyc?.proof_of_payment_status }}</template>
        <template #header-text>{{
          $t("pages.user.kyc.steps.third.title_sub")
        }}</template>
        <template #list>
          <li>{{ $t("pages.user.kyc.steps.third.item_first") }}</li>
          <li>{{ $t("pages.user.kyc.steps.third.item_second") }}</li>
          <li>{{ $t("pages.user.kyc.steps.third.item_third") }}</li>
        </template>
      </kyc-step>
    </ul>
  </div>
</template>
<style scoped lang="scss">
$block: ".my-kyc";

#{$block} {
  &__status {
    top: 0;
    right: 20px;
    border: 2px solid #fff;
    padding: 10px 20px;
    color: #fff;
    h3 {
      font-size: 14px;
    }
    &-value {
      color: #f00;
      margin: 0 10px;
    }
    .svg-icon {
      width: 25px;
      height: 25px;
      fill: #fff;
    }
    &--verified {
      #{$block}__status-value {
        color: #00a912;
      }
    }
    @media only screen and (max-width: 590px) {
      position: static !important;
      display: inline-flex !important;
    }
  }
  &__text {
    margin-top: 60px;
    color: #fff;
    font-size: 14px;
    @media only screen and (max-width: 590px) {
      margin-top: 30px;
    }
  }
  &__steps {
    margin-top: 50px;
    padding: 0;
  }
}
</style>
