import api from "@/providers/api";
import DeviceDetector from "device-detector-js";

export default {
  state: {
    langs: [],
    currentLang: "en",
    data: {},
    strings: {},
    showGamesAdvancedFilters: false,
    footerData: {},
    deviceAccountInfo: new DeviceDetector().parse(window.navigator.userAgent),
    isAppLoading: false,
    navigationMenus: {},
    isGamesScrolling: false,
    hasBeenLoggedBefore: localStorage.getItem("has-logged-before") === "true",
    isUnderMaintenance: false,
    underMaintenanceImage: {},
  },
  getters: {
    showGamesAdvancedFilters: (state) => state.showGamesAdvancedFilters,
    footerData: (state) => state.footerData,
    data: (state) => state.data,
    currentLang: (state) => state.currentLang,
    langs: (state) => state.langs,
    deviceAccountInfo: (state) => state.deviceAccountInfo,
    isAppLoading: (state) => state.isAppLoading,
    navigationMenus: (state) => state.navigationMenus,
    isGamesScrolling: (state) => state.isGamesScrolling,
    hasBeenLoggedBefore: (state) => state.hasBeenLoggedBefore,
    isUnderMaintenance: (state) => state.isUnderMaintenance,
    underMaintenanceImage: (state) => state.underMaintenanceImage,
  },
  mutations: {
    setLangs(state, data) {
      state.langs = data;
    },
    setLanguage(state, langCode) {
      state.currentLang = langCode;
    },
    setGlobalData(state, data) {
      state.data = data;
    },
    setLocaleStrings(state, strings) {
      state.strings = strings;
    },
    setShowGamesAdvancedFilters(state, data) {
      state.showGamesAdvancedFilters = data;
    },
    setFooterData(state, data) {
      state.footerData = data;
    },
    setIsAppLoading: (state, data) => {
      state.isAppLoading = data;
    },
    setNavigationMenus: (state, data) => {
      state.navigationMenus = data;
    },
    setIsGameScrolling: (state, data) => {
      state.isGamesScrolling = data;
    },
    setIsUnderMaintenance: (state, data) => {
      state.isUnderMaintenance = data;
    },
    setUnderMaintenanceImage: (state, data) => {
      state.underMaintenanceImage = data;
    },
  },
  actions: {
    setGlobalData(context, payload) {
      context.commit("setGlobalData", payload);
    },
    setGlobalDataEnFallback(context, payload) {
      context.commit("setGlobalDataEnFallback", payload);
    },
    setLocaleStrings(context, strings) {
      context.commit("setLocaleStrings", strings);
    },
    setLangs(context, payload) {
      context.commit("setLangs", payload);
    },
    setLanguage(context, payload) {
      context.commit("setLanguage", payload);
    },
    setFooterData(context, payload) {
      context.commit("setFooterData", payload);
    },
    async fetchInitialData({ dispatch, commit }) {
      try {
        const { data } = await api.get(`/init/getInitialData`);
        dispatch("setLangs", data.locales);
        dispatch("setLocaleStrings", data.localeKeys.locale_keys);
        dispatch("setGlobalData", data.globalField);
        dispatch("setFooterData", data.footerData);
        commit("setNavigationMenus", data.navigationMenus);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchUnderMaintenance({ commit }) {
      try {
        const { data } = await api.get(
          "/global-field?fields[0]=is_under_maintenance&populate=under_maintenance_image"
        );
        commit(
          "setIsUnderMaintenance",
          !!data.data.attributes.is_under_maintenance
        );
        commit(
          "setUnderMaintenanceImage",
          data.data.attributes.under_maintenance_image
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
};
