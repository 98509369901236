<script setup>
import { ref, defineExpose, watch } from "vue";

let showModal = ref(false);
let showCard = ref(false);

watch(
  () => showModal.value,
  (val) => {
    if (val) {
      setTimeout(() => {
        showCard.value = true;
      }, 200);
    } else {
      showCard.value = false;
    }
  }
);

const closeModal = () => {
  showCard.value = false;
  setTimeout(() => {
    showModal.value = false;
  }, 200);
};
defineExpose({
  showModal,
});
</script>
<template>
  <div class="modal-base" v-if="showModal">
    <div class="modal-base__fader" @click="closeModal"></div>
    <div class="modal-base__card">
      <div class="card p-4" :class="{ show: showCard }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-base {
  &__card {
    position: fixed;
    z-index: 2000;
    top: 100px;
    width: 450px;
    max-width: 90vw;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 768px) {
      top: 40px;
    }
    .card {
      opacity: 0;
      transform: translateY(-100px);
      transition: 0.1s;
      background: #fff;
      border-radius: 16px;
      color: #102b3c;
      &.show {
        opacity: 1;
        transform: translateY(0);
        transition: 0.3s;
      }
    }
  }
  &__fader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#112b3c, 0.6);
    z-index: 1000;
    backdrop-filter: blur(8px);
    transition: 0.3s;
  }
}
</style>
