<script setup>
import LoadingSpinnerBig from "@/components/loading-spinner-big.vue";
import PraxisCashier from "@/components/praxis-cashier.vue";
import cashier from "../../helpers/cashier";
import { computed } from "vue";
import { useStore } from "vuex";

// Declarations
const store = useStore();

// Computed
const getShowCashierModal = computed(() => store.getters.showCashierModal);
const getCashierModalTitle = computed(() => store.getters.cashierModalTitle);
const getIsCashierLoading = computed(() => store.getters.isCashierLoading);
const getCashierBonus = computed(() => store.getters.cashierBonus);

// Methods
const closeCashier = () => {
  cashier.close();
};
</script>
<template>
  <div
    class="cashier position-fixed top-0 h-100 w-100"
    v-if="getShowCashierModal"
  >
    <div class="cashier__content d-flex flex-column">
      <div
        class="cashier__header d-flex align-items-center justify-content-between"
      >
        <h2 class="cashier__title m-0">{{ getCashierModalTitle }}</h2>
        <h2
          class="cashier__bonus-code m-0"
          v-if="Object.keys(getCashierBonus).length"
        >
          Bonus {{ getCashierBonus.bonus_code }} is active
        </h2>
        <div role="button" class="cashier__close d-flex" @click="closeCashier">
          <icon variant="close" />
        </div>
      </div>
      <div class="cashier__body d-flex">
        <div
          v-if="getIsCashierLoading"
          class="cashier__loader w-100 d-flex align-items-center justify-content-center"
        >
          <loading-spinner-big />
        </div>
        <praxis-cashier v-else />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.cashier {
  background: rgba(#112b3c, 0.6);
  z-index: 1001;
  overflow: auto;
  &__content {
    background: #fff;
    margin: 10px auto auto;
    max-width: 800px;
    min-height: 800px;
    border-radius: 10px;
  }
  &__header {
    padding: 16px;
  }
  &__title,
  &__bonus-code {
    font-size: 18px;
  }
  &__bonus-code {
    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    color: var(--app-yellow-dark);
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: 3;
  }
  &__close {
    padding: 10px;
    .svg-icon {
      height: 15px;
      width: 15px;
    }
  }
  &__body {
    flex: 1;
  }
  &__iframe-wrapper {
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
