export default {
  state: {
    bonusDetailsModal: {
      isVisible: false,
      bonus: {},
    },
    isBonusesLoading: false,
  },
  getters: {
    bonusDetailsModal: (state) => state.bonusDetailsModal,
    isBonusesLoading: (state) => state.isBonusesLoading,
  },
  mutations: {
    setBonusDetailsModal: (state, data) => {
      state.bonusDetailsModal = data;
    },
    setIsBonusesLoading: (state, data) => {
      state.isBonusesLoading = data;
    },
  },
};
