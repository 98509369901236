<script setup>
import { computed, onBeforeUnmount, watch } from "vue";
import { useStore } from "vuex";
import SingleBonus from "./single-bonus.vue";
import { isLogged } from "../../composables/auth";
import loadingSpinenrBig from "@/components/loading-spinner-big.vue";
import qs from "qs";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

// Declarations
const store = useStore();
const route = useRoute();
const router = useRouter();

// Computed
const getApplicableDepositBonuses = computed(
  () => store.getters.applicableDepositBonuses
);
const getActiveBonuses = computed(() => store.getters.activeBonuses);
const getPromotion = computed(() => store.getters.promotion);
const getPackageTitle = computed(() => getPromotion.value?.attributes?.title);
const getPackageValue = computed(
  () => getPromotion.value?.attributes?.bonus_package_value
);
const isLoading = computed(() => store.getters.isBonusesLoading);
const getPlayerAttributes = computed(
  () => store.getters.user.player_attributes || []
);
const getPromotionBonuses = computed(() => {
  const orderedBonuses = _.orderBy(
    getPromotion.value?.attributes?.bonuses?.data || [],
    (bonus) => parseInt(bonus.attributes.deposit_count),
    "asc"
  );

  if (isLogged.value) {
    orderedBonuses.forEach((bonus) => {
      bonus.attributes.is_locked = false;
      bonus.attributes.is_used = false;

      const crmApplicableBonus = Array.isArray(
        getApplicableDepositBonuses.value?.BonusRules?.BonusRuleDeposit
      )
        ? getApplicableDepositBonuses.value.BonusRules.BonusRuleDeposit.find(
            (bonus) => bonus.BaseRule.Name.includes("welcome_pkg")
          )
        : getApplicableDepositBonuses.value?.BonusRules?.BonusRuleDeposit;

      const crmApplicableBonusRuleId =
        +crmApplicableBonus?.BaseRule?.BonusRuleID;

      if (!crmApplicableBonusRuleId) {
        bonus.attributes.is_used = true;
        bonus.attributes.is_locked = false;
        return;
      }

      let bonusIsUsed;
      if (Array.isArray(getActiveBonuses.value)) {
        bonusIsUsed = getActiveBonuses.value.some(
          (b) => +b.BonusRuleID === bonus.attributes.bonus_id
        );
      } else {
        bonusIsUsed =
          +getActiveBonuses.value.BonusRuleID === bonus.attributes.bonus_id;
      }

      if (bonusIsUsed) {
        bonus.attributes.is_used = true;
      } else {
        bonus.attributes.is_locked = true;
      }

      if (bonus.attributes.bonus_id === crmApplicableBonusRuleId) {
        bonus.attributes.is_locked = false;
      }
    });
  }

  return orderedBonuses;
});

// Methods
const checkPlayerAttributes = async () => {
  const updatePlayerAttributes = getPlayerAttributes.value.map(
    async (attribute) => {
      let isAttributePresent;
      const attributeNameParts = attribute.attribute_name.split("_");
      const attributeId = attributeNameParts[attributeNameParts.length - 1];
      if (Array.isArray(getActiveBonuses.value)) {
        isAttributePresent = getActiveBonuses.value.some((activeBonus) => {
          if (+activeBonus.BonusRuleID === +attributeId) {
            return true;
          }
          return false;
        });
      } else {
        isAttributePresent =
          +getActiveBonuses.value.BonusRuleID === +attributeId;
      }
      if (isAttributePresent && attribute.attribute_value === "attempted") {
        return await store.dispatch("savePlayerAttributes", {
          attributeName: attribute.attribute_name,
          attributeValue: `rewarded`,
        });
      }
      return;
    }
  );

  if (updatePlayerAttributes.length) {
    await Promise.all(updatePlayerAttributes);
  }
};
const fetchInitialData = async () => {
  store.commit("setIsBonusesLoading", true);
  // New Request
  const promotionQuery = qs.stringify({
    filters: { slug: { $eq: route.params.slug } },
    populate: "bonuses",
  });
  await store.dispatch("fetchBonusRules");
  await store.dispatch("fetchPromotion", promotionQuery);

  if (getPromotionBonuses.value?.length <= 1) {
    router.push({
      name: "promotion-single",
      params: {
        id: getPromotion.value?.id,
      },
    });
  }

  if (isLogged.value) {
    await store.dispatch("getApplicableDepositBonuses");
    await store.dispatch("fetchActiveBonuses");
    if (getPlayerAttributes.value.length) {
      await checkPlayerAttributes();
    }
  } else {
    watch(isLogged, async () => {
      store.commit("setIsBonusesLoading", true);
      if (isLogged.value) {
        await store.dispatch("fetchActiveBonuses");
        await store.dispatch("getApplicableDepositBonuses");
        if (getPlayerAttributes.value.length) {
          await checkPlayerAttributes();
        }
      }
      store.commit("setIsBonusesLoading", false);
    });
  }
  store.commit("setIsBonusesLoading", false);
};

// Init
fetchInitialData();

// On destroyed
onBeforeUnmount(() => {
  store.commit("setPromotion", {});
});
</script>
<template>
  <div class="bonuses-page">
    <div class="container">
      <div class="bonuses-page__heading d-flex flex-column align-items-center">
        <h1 class="bonuses-page__title m-0" v-if="getPackageValue">
          {{ getPackageValue }}
        </h1>
        <h3 class="bonuses-page__post-title" v-if="getPackageTitle">
          {{ getPackageTitle }}
        </h3>
      </div>
      <div
        v-if="isLoading"
        class="d-flex justify-content-center align-items-center"
      >
        <loadingSpinenrBig />
      </div>
      <div v-else class="bonuses-page__bonuses row">
        <SingleBonus
          v-for="bonus in getPromotionBonuses"
          :key="bonus.id"
          :bonus="bonus.attributes"
        />
        <h2
          class="bonuses-page__no-data text-center"
          v-if="!getPromotionBonuses.length"
        >
          {{ $t("pages.bonuses.title_no_data") }}
        </h2>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.bonuses-page {
  padding: 47.67px 0;
  &__title {
    font-size: 160px;
    font-weight: 800;
    line-height: 183.2px;
    font-family: "GT Walsheim Pro Bold";
    color: transparent;
    background: linear-gradient(180deg, #f2c94c 35.88%, #e1af43 78.57%);
    background-clip: text;
    text-shadow: 0px 0px 74px rgba(255, 255, 255, 0.25);
    @include tablet {
      font-size: 130px;
      line-height: 120px;
    }
    @include mobile {
      font-size: 65px;
      line-height: 74px;
    }
  }
  &__post-title {
    font-size: 63px;
    font-weight: 400;
    font-family: "GT Walsheim Pro Regular";
    color: #fff;
    margin-bottom: 40.87px;
    line-height: 72.13px;
    @include tablet {
      font-size: 46px;
      line-height: 62px;
      margin-bottom: 15.87px;
    }
    @include mobile {
      font-size: 25px;
      line-height: 29px;
    }
  }
  &__bonuses {
    margin-top: 63px;
    @include tablet {
      margin-top: 43.68px;
    }
  }
  &__no-data {
    color: #fff;
  }
}
</style>
